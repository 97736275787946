/* eslint-disable jsx-a11y/anchor-is-valid */
import { default as classNames, default as cx } from "classnames";
import * as _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Dropdown,
  Row,
  Tab,
  Tabs,
  useAccordionToggle,
} from "react-bootstrap";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import emptyKnowledge from "../../../assets/New-images/empty-knowledge.svg";
import folderImg from "../../../assets/New-images/folder-imgblue.svg";
import AddQuestionsImg from "../../../assets/images/add-learner-questions.svg";
import PinkQuestions from "../../../assets/images/pink-questions.svg";
import loadingimg from "../../../assets/images/portal-loading-anim.svg";

import archivedKnowledgeImg from "../../../assets/New-images/archived-knowledge.svg";
import uploadImg from "../../../assets/New-images/expand-knowledge-img.svg";
import plusIcon from "../../../assets/New-images/plus-blue.svg";
import sortIcon from "../../../assets/New-images/sort-blue-icon.svg";
import learningIcon from "../../../assets/images/learning-outcome-icon.svg";
import {
  KNOWLEDGE,
  KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH,
  KNOWLEDGE_MANAGEUNANSWERED,
  LEARNING_OUTCOMES_UPDATE,
  MANAGEKNOWLEDGEGROUP,
  MANAGE_LEARNER_QUESTIONS,
  REVIEW_QUIZ_QUESTIONS,
  TOOLKIT_GENERATE_QNA_UPLOAD_DOC,
} from "../../../config";
import { TagsComponent } from "../../../routes/Knowledge/AllKnowledge";
import "../../../routes/Knowledge/AllKnowledge/AllKnowledge.scss";
import KnowledgeCreateFromScratch from "../../../routes/Knowledge/KnowledgeCreateFromScratch/KnowledgeCreateFromScratch";
import UploadedDocuments from "../../../routes/UploadedDocuments/UploadedDocuments";
import { getCommonState } from "../../../store/common/selector";
import {
  bulkAssignModules,
  bulkSuggestKnowledgeByIds,
  deleteKnowledgeById,
  deleteKnowledgeGroup,
  getAllDocumentsNewFlow,
  getAllKnowledge,
  getAllLevels,
  getAllModulesFromYear,
  getReportsUnmatchedQuestions,
  getUnansweredQuestionCount,
  suggestKnowledgeById,
} from "../../../store/knowledge/actions";
import {
  getAllTopicsSelector,
  isAllKnowledgeError,
  isAllKnowledgeLoading,
  isAllKnowledgeSuccess,
  isTopicsLoading,
} from "../../../store/knowledge/selector";
import { getAllUserGroupOptions } from "../../../store/notifications/actions";
import { getAllIntents as getAllIntentsSelector } from "../../../store/questions/selector";
import { convertDataToMarkdownFormat } from "../../../utils/appUtils";
import { TwoButtonModal as KnowledgeConfirmationModal } from "../Common";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Loading from "../Loading/Loading";
import Pagination from "../Pagination/Pagination";
import PermissionWrapper from "../PermissionWrapper/PermissionWrapper";
import MoveSelectedModal from "./MoveSelectedModal";
import UpdateModuleModal from "./UpdateModuleModal";

import { subDays } from "date-fns";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import DashboardService from "../../../services/DashboardService";
import KnowledgeService from "../../../services/KnowledgeService";
import { HelperService } from "../../../services/helper.service";
import ProgressSteps from "../ProgressSteps/ProgressSteps";
import {
  setSelectedGroupId,
  setSelectedYear,
} from "../../../store/common/actions";
import KnowledgeCrousel from "./KnowledgeCrousel";


const knowledgeService = new KnowledgeService();
const helperService = new HelperService();
const service = new DashboardService();

function KnowledgeDatatableComponentGridView(props: any) {
  // Check if mgs
  const isMgs = process.env.REACT_APP_CISCO === "mgs";
  const { t } = useTranslation("translation");
  const commonState = useSelector(getCommonState);
  const history: any = useHistory();
  const useLocatio = useLocation();
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [topicIds, setTopicIds] = useState("");
  const allKnowledgeSuccess = useSelector(isAllKnowledgeSuccess);
  const allKnowledgeLoading = useSelector(isAllKnowledgeLoading);
  const topicsLoading = useSelector(isTopicsLoading);
  const allKnowledgeError = useSelector(isAllKnowledgeError);
  const allTopics = useSelector(getAllTopicsSelector);
  const allIntents = useSelector(getAllIntentsSelector);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
      useState(false);
  const [showDeletePermanentlyModal, setShowDeletePermanentlyModal] =
      useState(false);

  const [suggestionMode, setSuggestionMode] = useState(false);
  const [isLoadingItems, setIsLoadingItems] = useState(false);

  const tableWrapperRef = useRef(null);
  const [scrollRight, setScrollRight] = useState(0);
  const [unansweredCount, setUnansweredCount] = useState(0);
  const [activeKey, setActiveKey] = useState(null);
  const [recentUploadedDocuemnt, setRecentUploadedDocument] = useState(null);
  let defaultTab = "viewKnowledge";
  if (history.location.state?.activeTab) {
    defaultTab = history.location.state?.activeTab;
  }
  const [activeTab, setActiveTab] = useState(
      isMgs || props.createFromUploadedDocument ? "viewDocument" : defaultTab
  );
  const [years, setYears] = useState([]);
  const [modules, setModules] = useState([]);
  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [selectedParentId, setSelectedParentId] = React.useState(null);
  const [selectedModuleName, setSelectedModuleName] = React.useState("");
  const [moduleEditMode, setModuleEditMode] = React.useState(false);

  const [moduleDepth, setModuleDepth] = React.useState(0);
  const [selectedParentModule, setSelectedParentModule] = useState<any>();
  const [deletedRowId, setDeletedRowId] = useState([]);
  const [learningOutcomes, setLearningOutcomes] = useState([]);
  const [isEditLearningOutcomes, setIsEditLearningOutcomes] = useState(false);
  const [isTabLoading, setIsTabloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState([]);

  /**
   * Edit mode activate or de activate
   */
  const [editMode, setEditMode] = useState(false);
  const [selectedIntent, setSelectedIntent] = useState(null);

  useEffect(() => {
    if (!topicsLoading) {
      // dispatch(getAllTopics());
      // dispatch(getAllIntents());
      dispatch(getAllUserGroupOptions()); // user groups
      !isMgs && getUnansweredCount();
    }
    setSelectedNodes([]);
    setHasTopLevelApplied(false);
    getAllYearsFun();
    getRecentUploadedDocument();
  }, []);

  useEffect(() => {
    const autoSelectParentModule = (groupId: string) => {
      const findParent = (module: any) => {
        const parent = modules.find((m: any) => m._id === module.parent_id);
        return parent;
      };

      let me = modules.find((m: any) => m._id === groupId);

      if (!me) return;
      let parent = findParent(me);

      let highestParent;
      while (parent) {
        highestParent = parent;
        parent = findParent(parent);
      }

      setSelectedParentModule(highestParent);
    };

    if (modules.length > 0 && commonState.selectedGroupId) {
      autoSelectParentModule(commonState.selectedGroupId);
    }
  }, [modules]);

  useEffect(() => {
    if (isMgs) {
      if (props.onTabChanges) {
        props.onTabChanges("viewDocument");
      }
      setActiveTab("viewDocument");
    }
  }, []);

  const editKnowledgehandler = (intent: any) => {
    const editModeCheck = !editMode;
    setSelectedIntent(intent);
    setEditMode(editModeCheck);
  };

  const resetEditKnowledgehandler = (action: any) => {
    const editModeCheck = !editMode;
    setSelectedIntent(null);
    setEditMode(editModeCheck);
    // From edit mode if any intent gets deleted then we have to reload data
    if (action === "reload") {
      getAllYearsFun();
      // console.log('in')
      dispatch(
          getAllKnowledge(
              3,
              currentPage,
              perPage,
              query,
              topicIds,
              {},
              selectedNodes.length > 0,
              props.documentId,
              commonState.selectedGroupId,
              hasTopLevelApplied
          )
      );
    }
  };

  const isBranchUnderSelectedModule = (level: any) => {
    if (!selectedParentModule) {
      return false;
    }

    const findParent = (module: any) => {
      const parent = modules.find((m) => m._id === module.parent_id);
      return parent;
    };

    let parent = findParent(level);

    while (parent) {
      if (parent._id === selectedParentModule._id) {
        return true;
      }
      parent = findParent(parent);
    }
    return false;
  };

  const getAllYearsFun = async (loadSubModules: boolean = true) => {
    try {
      const response: any = await dispatch(getAllLevels());
      setYears([...response]);

      const query = new URLSearchParams(useLocatio.search);
      const parent = query.get("parent");
      if (parent) {
        const parentVal = response.find((par: any) => par._id === parent);
        if (parentVal) {
          getAllModulesFromYearFun(parentVal);
        } else {
          response &&
          response.length > 0 &&
          getAllModulesFromYearFun(
              commonState.selectedYear ? commonState.selectedYear : response[0]
          );
        }
      } else {
        response &&
        response.length > 0 &&
        getAllModulesFromYearFun(
            commonState.selectedYear ? commonState.selectedYear : response[0]
        );
      }
    } catch (error: any) {
      setYears([]);
    }
  };

  const getAllModulesFromYearFun = async (
      year: any,
      force: boolean = false,
      newLevelSelected: boolean = false
  ) => {
    dispatch(setSelectedYear(year));
    try {
      const query = new URLSearchParams(useLocatio.search);
      const group =
          query.get("group") || history.location.state?.selectedGroupId;
      const response: any = await dispatch(getAllModulesFromYear(year._id));
      setModules([...response]);

      if (group && !force) {
      } else {
        if (response && response.length > 0) {
          if (!commonState.selectedGroupId || newLevelSelected) {
            setHasTopLevelApplied(false);
            onSelectGroupId(response[0]._id);
          }
        } else {
          onSelectGroupId(year._id);
        }
      }
    } catch (error: any) {
      setModules([]);
    }
  };

  const getRecentUploadedDocument = async (filters: any = null) => {
    const filterMgs = isMgs
        ? { type: "summary,ebook,other,lesson_slides" }
        : {};
    const { documents, pagination }: any = await dispatch(
        getAllDocumentsNewFlow({
          page: 1,
          page_size: 1,
          ...filterMgs,
        })
    );
    if (documents && documents.length > 0) {
      setRecentUploadedDocument(documents[0]);
    }
  };

  const confirmRenameHandler = async (data: any) => {
    getAllYearsFun(false);
    updateModalHandler();
  };

  const updateModalHandler = () => {
    setShowRenameModal(!showRenameModal);
  };

  const updateParentHandler = (
      name?: string,
      parent_id?: string,
      isEdit: boolean = false,
      depth: number = 0
  ) => {
    setSelectedParentId(parent_id);
    setSelectedModuleName(name);
    setModuleEditMode(isEdit);
    setModuleDepth(depth);
    updateModalHandler();
  };

  const [deleteModuleModalShow, setDeleteModuleModalShow] = useState(false);
  const [deletedModuleId, setDeletedModuleId] = useState([]);
  const deleteModuleModalHandler = () => {
    setDeleteModuleModalShow(!deleteModuleModalShow);
  };
  const confirmDeleteModuleHandler = async (e: any, deleteId: string) => {
    try {
      const response: any = await dispatch(deleteKnowledgeGroup(deleteId));
      getAllYearsFun(false);
      deleteModuleModalHandler();
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const getUnansweredCount = async () => {
    try {
      const res = await service.getLearnerQuestions({
        answer_status: "unanswered",
        page: "1",
        page_size: "10",
        filter_from_date: moment(subDays(new Date(), 30)).format("YYYY-MM-DD"),
        filter_to_date: moment(new Date()).format("YYYY-MM-DD"),
        sort_field: "created_at",
        sort_order: "dsc",
      });
      setUnansweredCount(res?.data?.data?.pagination?.total_items);
    } catch (error: any) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setTotalRows(allKnowledgeSuccess?.pagination?.total_items);
  }, [allKnowledgeSuccess]);
  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = (page: number) => {
    // e.preventDefault();
    // console.log('[[[[[ DISPATCHED ]]]]]', currentPage, page)
    setCurrentPage(page);
    // console.log('in')
    dispatch(
        getAllKnowledge(
            3,
            page,
            perPage,
            query,
            topicIds,
            {},
            selectedNodes.length > 0,
            props.documentId,
            commonState.selectedGroupId,
            hasTopLevelApplied
        )
    );
  };
  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    // console.log('in')
    dispatch(
        getAllKnowledge(
            3,
            page,
            newPerPage,
            query,
            topicIds,
            {},
            selectedNodes.length > 0,
            props.documentId,
            commonState.selectedGroupId,
            hasTopLevelApplied
        )
    );
  };

  /**
   * Set debounce time for while searching in any input
   */
  const [query, setQuery] = useState("");
  const [isTyping, setTyping] = useState(false);
  useEffect(() => {
    // Added 500 as debounce time will wait for 500 miliseconds and then we will fetch intents based on query
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        setCurrentPage(1);
        // console.log('in')
        dispatch(
            getAllKnowledge(
                3,
                1,
                perPage,
                query,
                topicIds,
                {},
                selectedNodes.length > 0,
                props.documentId,
                commonState.selectedGroupId ? commonState.selectedGroupId : null,
                query && query.length > 0 ? true : false
            )
        );
        setTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    setTyping(true);
  };
  /**
   * State to manage tags | categories as Nodes | Tags whatever we can say
   */
  const [selectedNodes, setSelectedNodes] = useState([]);

  /**
   * Used to suggest knowledge based on ID
   * @param e
   * @param selectedRow
   */
  const suggestKnowledgeHandler = (
      e: any,
      selectedRow: string,
      suggested: boolean
  ) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(suggestKnowledgeById(selectedRow, !suggested));
  };
  /**
   * Used to suggest knowledge based on ID
   * @param e
   * @param selectedRow
   */
  const suggestBulkKnowledgeHandler = async (e: any, suggestedRows: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (suggestedRows.length > 0) {
      await dispatch(bulkSuggestKnowledgeByIds(suggestedRows));
      setSelectedRows([]);
    }
  };

  /**
   * Used to delete multiple knowledge based on IDS
   * @param e
   * @param selectedRowIds
   */
  const deleteConfirmed = async (
      selectedRowIds: string[],
      isArchiving: boolean = false
  ) => {
    setLoading(true);
    await dispatch(deleteKnowledgeById(selectedRowIds, isArchiving));
    // console.log('in')
    dispatch(
        getAllKnowledge(
            3,
            currentPage,
            perPage,
            query,
            topicIds,
            {},
            selectedNodes.length > 0,
            props.documentId,
            commonState.selectedGroupId,
            hasTopLevelApplied
        )
    );
    setLoading(false);
    setSelectedNodes([]);
  };
  /**
   * Set multiple rows in state on change of checkbox
   * @param param0
   */
  const onSelectedRowsHandler = ({
                                   selectedRows,
                                 }: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => {
    const ids = selectedRows.map((r) => r._id);
    setSelectedRows(ids);
  };

  /**
   * Used to sort data using API
   * @param param0
   * @param direction
   */
  const onSortHandler = (selector: any, direction: string) => {
    // For now we are allowing server side sorting on above two fields
    // console.log('in')
    dispatch(
        getAllKnowledge(
            3,
            currentPage,
            perPage,
            query,
            topicIds,
            {
              sort_field: selector === "name" ? "question" : selector,
              sort_order: direction === "desc" ? "dsc" : "asc",
            },
            selectedNodes.length > 0,
            props.documentId,
            commonState.selectedGroupId,
            hasTopLevelApplied
        )
    );
  };

  /**
   * Used to sort data using API
   * @param param
   * @param direction
   */
  const [hasTopLevelApplied, setHasTopLevelApplied] = useState(true);

  useEffect(() => {
    // if (allKnowledgeLoading) {
    //   return
    // }
    if (!commonState.selectedGroupId) {
      return;
    }

    if (activeTab === "viewKnowledge") {
      dispatch(
          getAllKnowledge(
              3,
              currentPage,
              perPage,
              query,
              topicIds,
              {},
              selectedNodes.length > 0,
              props.documentId,
              !props.documentId ? commonState.selectedGroupId : null,
              hasTopLevelApplied
          )
      );
    }
  }, [commonState.selectedGroupId, activeTab]);

  const getLearnings = async (groupId: string) => {
    setIsTabloading(true);
    setIsLoadingItems(true);
    const res = await knowledgeService.getLearningOutcomesByGroup(groupId);
    setLearningOutcomes(res?.data?.data?.learning_outcomes);
    setIsLoadingItems(false);
    setIsTabloading(false);
    // setDocuments(documentsResult);
  };

  useEffect(() => {
    if (activeTab === "viewLearningOutcomes") {
      getLearnings(commonState.selectedGroupId);
    }
  }, [activeTab, commonState.selectedGroupId]);

  const onSelectGroupId = (level: string) => {
    // For now we are allowing server side sorting on above two fields
    dispatch(setSelectedGroupId(level));
  };

  /**
   * Custom sort function
   * @param rows
   * @param selector
   * @param direction
   * @returns
   */
  const customSort = (rows: any[], selector: string, direction: string) => {
    return rows;
  };

  const resetSuggestionView = () => {
    setSuggestionMode(!suggestionMode);
  };

  const btnLeftRightHandler = (e: any) => {
    const el = document.getElementsByClassName("table-wraper");
    let scrollLeft = el[0].scrollLeft;
    if (e.target.dataset.pos === "right") {
      el[0].scrollLeft += 200;
      setScrollRight((scrollLeft += 200));
    } else {
      el[0].scrollLeft -= 200;
      setScrollRight((scrollLeft -= 200));
    }
  };

  // Show bulk assign category modal
  const [bulkAssignCategoryShow, setBulkAssignCategoryShow] = useState(false);
  const [bulkCategoryIds, setBulkCategoryIds] = useState([]);
  const BulkAssignCategoryhandler = (value: boolean) => {
    setBulkAssignCategoryShow(value);
  };
  const bulkAssignCategoryKnowledgeHandler = (id: string[]) => {
    setBulkCategoryIds(id);
    setBulkAssignCategoryShow(true);
  };

  const confirmCategoryUpdateHandler = async (selectedOption: any) => {
    try {
      BulkAssignCategoryhandler(false);
      await dispatch(bulkAssignModules(selectedOption, selectedNodes));
      setSelectedNodes([]);
      getAllYearsFun();
    } catch (error: any) {
      setSelectedNodes([]);
    }
  };

  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] = [...selectedNodes];
    if (event.target.checked) {
      const isPresent = data.indexOf(id);
      if (isPresent < 0) {
        data.push(id);
      }
    } else {
      const oldData = [...selectedNodes];
      const index = oldData.indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedNodes([..._.uniq(data)]);
  };

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (
          allKnowledgeSuccess &&
          allKnowledgeSuccess.intents &&
          allKnowledgeSuccess.intents.length > 0
      ) {
        let data: any[] = [
          ...allKnowledgeSuccess.intents.map((intent: any) => intent._id),
        ];
        setSelectedNodes([..._.uniq(data)]);
      }
    } else {
      setSelectedNodes([]);
    }
  };

  const ContextAwareToggle = ({
                                children,
                                eventKey,
                                callback,
                                is_suggested,
                                breadcrumbs,
                              }: any) => {
    const currentEventKey = useContext(AccordionContext);
    setActiveKey(currentEventKey);
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});
    return (
        <>
          <input
              type="checkbox"
              className="mr-2"
              onChange={(e) => {
                addToSelectedRows(e, eventKey);
              }}
              checked={selectedNodes.indexOf(eventKey) >= 0}
          />

          <span
              className={`${is_suggested ? "is_suggested" : ""}`}
              onClick={decoratedOnClick}
          >
          {children}
            <small className="d-block k-breadcrumb">
            {breadcrumbs.map((b: any) => b.name).join("/")}
          </small>
        </span>
        </>
    );
  };

  // get module names
  const getLevelNames = (levels: string[]) => {
    let moduleAsString: string[] = [];
    levels.forEach((level) => {
      const moduleObj = modules.find((m: any) => m.value === level);
      if (moduleObj) {
        moduleAsString.push(moduleObj.label);
      }
    });
    return moduleAsString.length === 0
        ? "Uncategorised"
        : moduleAsString.join(", ");
  };

  const knowledgeHtml = (
      <>
        <div className="all-knowledge-search d-flex flex-wrap align-items-center mb-2">
          <Dropdown className="mr-2 sort-dropdown">
            <Dropdown.Toggle
                className="btn btn-outline-primary font-600 py-1 my-1 "
                variant="default"
                id="dropdown-basic"
            >
              <img src={sortIcon} alt="" className="mr-2" />
              {t("Sort")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                  onClick={() => {
                    onSortHandler("updated_at", "desc");
                  }}
              >
                {t("Most Recent")}
              </Dropdown.Item>
              <Dropdown.Item
                  onClick={() => {
                    onSortHandler("name", "asc");
                  }}
              >
                {t("Ascending")}
              </Dropdown.Item>
              <Dropdown.Item
                  onClick={() => {
                    onSortHandler("name", "desc");
                  }}
              >
                {t("Descending")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <div className="web-links my-0 mr-0">
            <input
                type="text"
                className="form-control h-auto"
                placeholder={t("Search for Questions")}
                onChange={onInputChangeHandler}
            />
          </div>
          <PermissionWrapper>
            <Dropdown className="sort-dropdown ml-auto">
              <Dropdown.Toggle
                  className="btn btn-outline-primary font-600 border-0 py-1"
                  variant="default"
                  id="dropdown-basic"
              >
                {t("+ Add")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                    onClick={() => {
                      history.push(
                          KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH +
                          (!hasTopLevelApplied
                              ? `?group=${commonState.selectedGroupId}&parent=${commonState.selectedYear._id}&view-all=true`
                              : "")
                      );
                    }}
                >
                  {t("Create from Scratch")}
                </Dropdown.Item>
                {/* onClick={() => {
                history.push(
                  KNOWLEDGE +
                    (!hasTopLevelApplied
                      ? `?group=${selectedGroupId}&parent=${selectedYear._id}`
                      : "")
                );
              }} */}
                <Dropdown.Item
                    onClick={() => {
                      history.push(
                          TOOLKIT_GENERATE_QNA_UPLOAD_DOC +
                          `?group=${commonState.selectedGroupId}`
                      );
                    }}
                >
                  {t("Create from Document")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </PermissionWrapper>
        </div>
        <div className="d-flex flex-wrap justify-content-between align-items-center mt-1">
          <div className="d-flex align-items-center">
            <div className="custom-checkbox mr-3">
              <input
                  type="checkbox"
                  className="mr-2"
                  id="select-all"
                  checked={
                      allKnowledgeSuccess &&
                      allKnowledgeSuccess.intents &&
                      allKnowledgeSuccess.intents.length > 0 &&
                      selectedNodes.length === allKnowledgeSuccess.intents.length
                  }
                  onChange={onSelectAllRows}
              />
              <label htmlFor="select-all" className="mb-0">
                {t("Select all")}
              </label>
            </div>
            {selectedNodes && selectedNodes.length > 0 && (
                <PermissionWrapper>
                  <button
                      className="btn btn-suggest py-0 font-sm"
                      onClick={(e) => suggestBulkKnowledgeHandler(e, selectedNodes)}
                  >
                    {t("Suggest Selected")}
                  </button>

                  <button
                      className="btn btn-suggest py-0 font-sm"
                      onClick={(e) =>
                          bulkAssignCategoryKnowledgeHandler(selectedRows)
                      }
                  >
                    {t("Move Selected")}
                  </button>
                  <button
                      className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                      onClick={() => {
                        setDeletedRowId(selectedNodes);
                        setShowDeleteConfirmationModal(true);
                      }}
                  >
                    {t("Delete Selected")}
                  </button>
                </PermissionWrapper>
            )}
          </div>
        </div>
        <Accordion
            defaultActiveKey={
              allKnowledgeSuccess && allKnowledgeSuccess.intents.length > 0
                  ? allKnowledgeSuccess.intents[0]._id
                  : null
            }
            className="accordion-viewby mt-3 scroll-wrap"
        >
          {allKnowledgeSuccess &&
              allKnowledgeSuccess.intents &&
              allKnowledgeSuccess.intents.map((knowledge: any, index: number) => {
                const parent = allIntents.find(
                    (intent: any) => intent._id === knowledge.parent_id
                );
                return (
                    <Card
                        className={cx({
                          active: activeKey === "0",
                          notactive: activeKey !== "0",
                        })}
                        key={knowledge?._id}
                    >
                      <Card.Header>
                        <ContextAwareToggle
                            is_suggested={knowledge.is_suggested}
                            eventKey={knowledge?._id}
                            breadcrumbs={
                              knowledge.breadcrumbs && knowledge.breadcrumbs.length > 0
                                  ? knowledge.breadcrumbs
                                  : []
                            }
                        >
                          {knowledge.questions && knowledge.questions.length > 0
                              ? knowledge.questions[knowledge.questions.length - 1].text
                              : ""}
                        </ContextAwareToggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={knowledge?._id}>
                        <Card.Body>
                          <div>
                            <h3 className="h6 clr-black mb-1">{t("Answer:")}</h3>
                            {knowledge.responses && knowledge.responses.length > 0 ? (
                                <ReactMarkdown>
                                  {knowledge.responses[0].text}
                                </ReactMarkdown>
                            ) : (
                                ""
                            )}
                          </div>
                          <Row className="mt-3">
                            <Col md={4} className="my-2 my-md-0">
                              <h3 className="h6 clr-black mb-1">
                                {t("Generated from:")}
                              </h3>
                              <div>{knowledge?.document_name}</div>
                            </Col>
                            <Col md={3} className="my-2 my-md-0">
                              <h3 className="h6 clr-black mb-1">{t("Created by")}</h3>
                              <div>{knowledge?.author_email}</div>
                            </Col>
                            <Col md={5} className="my-2 my-md-0">
                              <h3 className="h6 clr-black mb-1">
                                {t("Last Updated(DD/MM/YYYY hh:mm A)")}
                              </h3>
                              <div>
                                <Moment utc local format="DD/MM/YYYY hh:mm A">
                                  {knowledge.updated_at}
                                </Moment>
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={4} className="my-2 my-md-0">
                              <h3 className="h6 clr-black mb-1">
                                {t("Level (Module)")}
                              </h3>
                              <div>
                                {getLevelNames(
                                    knowledge.knowledge_group_ids
                                        ? knowledge.knowledge_group_ids
                                        : []
                                )}
                              </div>
                            </Col>
                          </Row>
                          <div className="mt-3">
                            <h3 className="h6 clr-black mb-1">{t("Keywords")}</h3>
                            <TagsComponent row={knowledge} />
                          </div>
                          <div className="mt-3 d-none">
                            <h3 className="h6 clr-black mb-1">
                              {t("Possible questions")}
                            </h3>
                            <ol>
                              {knowledge.questions.length > 0
                                  ? knowledge.questions.map(
                                      (q: { text: string }, i: number) => {
                                        return (
                                            <li key={"q_" + knowledge._id + "_" + i}>
                                              <p>{q.text}</p>
                                            </li>
                                        );
                                      }
                                  )
                                  : "-"}
                            </ol>
                          </div>
                          <div className="mt-3 d-none">
                            <h3 className="h6 clr-black mb-1">
                              {t("Possible responses")}
                            </h3>
                            <ol>
                              {knowledge.responses.length > 0
                                  ? knowledge.responses.map(
                                      (
                                          response: {
                                            text: string;
                                            group_ids: any;
                                          },
                                          i: number
                                      ) => {
                                        return (
                                            <li
                                                key={"response_" + knowledge._id + "_" + i}
                                            >
                                              <p>
                                                {convertDataToMarkdownFormat(
                                                    response.text
                                                )}
                                              </p>
                                            </li>
                                        );
                                      }
                                  )
                                  : "-"}
                            </ol>
                          </div>
                          <div className="mt-3 d-none">
                            <h3 className="h6 clr-black mb-1">
                              {t("Parent question")}
                            </h3>

                            {parent ? parent.name : "-"}
                          </div>
                          <div className="mt-3 text-right">
                            <button
                                className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2"
                                onClick={(e) =>
                                    suggestKnowledgeHandler(
                                        e,
                                        knowledge._id,
                                        knowledge.is_suggested
                                    )
                                }
                            >
                              {knowledge.is_suggested
                                  ? t("Remove as suggested")
                                  : t("Suggest")}
                            </button>

                            <button
                                className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2"
                                onClick={() => editKnowledgehandler(knowledge)}
                            >
                              {t("Edit")}
                            </button>
                            <button
                                className="btn btn-outline-danger font-weight-normal mx-2 py-1 py-md-2"
                                onClick={() => {
                                  setDeletedRowId([knowledge._id]);
                                  setShowDeleteConfirmationModal(true);
                                }}
                            >
                              {t("Delete")}
                            </button>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                );
              })}
        </Accordion>

        {/* empty state */}
        {allKnowledgeSuccess &&
            allKnowledgeSuccess.intents.length === 0 &&
            !props.manageDocument && (
                <div className="empty-knowledge text-center my-5">
                  <h3 className="h6 clr-grey-txt7 mb-3">
                    {t("You have yet to create knowledge")}
                  </h3>
                  <figure className="h-auto">
                    <img src={emptyKnowledge} alt="" />
                  </figure>
                  <div className="clr-primary font-weight-bold">
                    {/* Drop a document here <span className="clr-grey-txt7">or</span>{" "} <br /> */}
                    <Link
                        to={
                            KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH +
                            (!hasTopLevelApplied
                                ? `?group=${commonState.selectedGroupId}&parent=${
                                    commonState.selectedYear
                                        ? commonState.selectedYear._id
                                        : ""
                                }`
                                : "")
                        }
                    >
                      {t("Click here to get started")}
                    </Link>
                  </div>
                </div>
            )}

        {/* empty state */}
        {allKnowledgeSuccess &&
            allKnowledgeSuccess.intents.length === 0 &&
            props.manageDocument && (
                <div className="text-center bg-grey radius-8 p-4">
                  <div className="font-600 font-16">
                    {t(
                        "You have not saved any knowledge from this document yet. Click the button below to view generated knowledge."
                    )}
                  </div>
                  {/* props.summaryParamType === "summary" &&  */}
                  <button
                      className="btn btn-outline-primary mt-3"
                      onClick={() => {
                        props.manageDocument
                            ? props.tabsChangeHandler("questions")
                            : history.push(
                                KNOWLEDGE_KNOWLEDGE_CREATE_FROM_SCRATCH +
                                (!hasTopLevelApplied
                                    ? `?group=${commonState.selectedGroupId}&parent=${commonState.selectedYear._id}`
                                    : "")
                            );
                      }}
                  >
                    {t("Generate From Document")}
                  </button>
                </div>
            )}

        {allKnowledgeSuccess &&
            allKnowledgeSuccess.pagination &&
            allKnowledgeSuccess.intents.length > 0 && (
                <Pagination
                    showScrollButtons={false}
                    scrollRightValue={scrollRight}
                    scrollWrapper={"table-wraper"}
                    currentPage={currentPage}
                    pagination={allKnowledgeSuccess.pagination}
                    paginationPerPage={perPage}
                    pageChange={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            )}
      </>
  );

  const handleChangeLO = (value: any, i: any) => {
    const copy = [...learningOutcomes];
    copy[i].text = value;
    setLearningOutcomes(copy);
  };

  const handleRemoveLO = (i: any) => {
    const copy = [...learningOutcomes];
    copy.splice(i, 1);
    setLearningOutcomes(() => [...copy]);
  };

  const renderLOinput = (value: any, i: any) => {
    return (
        <div className="learning-outcome-input">
          <input
              className="form-control border-0 p-0"
              defaultValue={value}
              value={value}
              placeholder={t("Input learning outcomes")}
              onChange={(e) => handleChangeLO(e.target.value, i)}
          />
          <div
              className="delete-outcome"
              role="button"
              onClick={() => handleRemoveLO(i)}
          >
            <b className="clr-pink">x</b>
          </div>
        </div>
    );
  };

  const saveOutcomes = async () => {
    if (learningOutcomes.length >= 0) {
      setIsTabloading(true);
      const outcomes: any = learningOutcomes
          .filter((o) => o.text !== "")
          .map((o) => ({
            text: o?.text,
          }));
      try {
        const res = await knowledgeService.saveLearningOutcomesByGroup(
            commonState.selectedGroupId,
            outcomes
        );
        setLearningOutcomes(res.data.data.learning_outcomes);
        setIsTabloading(false);
        helperService.showToaster(t("Success"), "success");
        setIsEditLearningOutcomes(false);
      } catch (error) {
        setIsTabloading(false);
        helperService.showToaster("Error when save learning outcomes");
      }
    }
  };

  const getUnansweredQuestionCountHandler = async () => {
    let filter = {
      page: 1,
      page_size: 10,
      sort_field: "created_at",
      sort_order: "dsc",
      to_date: moment(new Date()).format("YYYY-MM-DD"),
      from_date: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
      answer_status: "unanswered",
    };
    try {
      const res = await service.getLearnerQuestions(filter);
      // const res = await service.getDiscussion(filter);

      if (res.status === 200) {
        setUnansweredCount(res.data.data.pagination.total_items);
      }
    } catch (error) {}
  };

  const renderOutcomes = () => {
    if (isEditLearningOutcomes) {
      return (
          <div className="edit-knowledge-sidewrap learning">
            <div className="page-mid-wraper edit-learning-outcome p-3">
              <div className="d-flex  justify-content-between ">
                <b className="font-16">{t("Edit Learning Outcomes")}</b>
                <div
                    onClick={() => setIsEditLearningOutcomes(false)}
                    role="button"
                >
                  <b>x</b>
                </div>
              </div>
              <div className="bottom-hight-light-box">
                <div className="clr-blue font-16">{t("Learning Outcomes")}</div>
              </div>
              <div className="d-flex  justify-content-end mt-2">
                <button
                    className="btn btn-outline-primary border-0"
                    disabled={
                        learningOutcomes.length > 0 && learningOutcomes[0].text === ""
                    }
                    onClick={() => {
                      const copy = [...learningOutcomes];
                      setLearningOutcomes([{ text: "" }, ...copy]);
                    }}
                >
                  {t("+ Add")}
                </button>
              </div>

              <div className="scroll-wrap outcomes-container">
                {learningOutcomes.length > 0 &&
                    learningOutcomes.map((l: any, i: any) =>
                        renderLOinput(l.text, i)
                    )}
                {learningOutcomes.length === 0 && (
                    <div className="d-flex h-100 text-center justify-content-center align-items-center flex-column">
                      <div className="w-50 mb-3">
                        {t("You have yet to create any learning outcomes")}
                      </div>
                      <figure className="h-auto">
                        <img src={emptyKnowledge} alt="" />
                      </figure>
                      {!isLoadingItems && (
                          <button
                              className="btn btn-link m-2 font-weight-normal"
                              onClick={() => {
                                history.push(
                                    TOOLKIT_GENERATE_QNA_UPLOAD_DOC +
                                    `?group=${commonState.selectedGroupId}`
                                );
                              }}
                          >
                            {t("Create learning outcomes")}
                          </button>
                      )}
                    </div>
                )}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <button
                    className="btn btn-primary m-2 font-weight-normal"
                    onClick={() => saveOutcomes()}
                >
                  {t("Save")}
                </button>
                <button
                    className="btn btn-outline-primary m-2 font-weight-normal"
                    onClick={() => setIsEditLearningOutcomes(false)}
                >
                  {t("Cancel")}
                </button>
              </div>
            </div>
          </div>
      );
    }

    return (
        <div className="accordion-viewby learning">
          <div className="d-flex align-items-center justify-content-between  mb-3 ">
            <div className="d-flex align-items-center">
              <img src={learningIcon} alt="" />
              <b className="pl-2"> {t("Learning Outcomes")}</b>
            </div>
          </div>
          <div className="scroll-wrap outcomes-container">
            {learningOutcomes.length > 0 &&
                learningOutcomes.map((l: any) => (
                    <div className="learning-outome-item mb-2">{l.text}</div>
                ))}
            {learningOutcomes.length === 0 && (
                <div className="d-flex h-100 text-center justify-content-center align-items-center flex-column">
                  <div className="w-50 mb-3">
                    {t("You have yet to create any learning outcomes")}
                  </div>
                  <figure className="h-auto">
                    {isLoadingItems ? (
                        <img src={loadingimg} alt="" />
                    ) : (
                        <img src={emptyKnowledge} alt="" />
                    )}
                    {/* <img src={emptyKnowledge} alt="" /> */}
                  </figure>
                  {!isLoadingItems && (
                      <button
                          className="btn btn-link m-2"
                          onClick={() => {
                            history.push(
                                `/knowledge/setup/learning-outcomes` +
                                `?group=${commonState.selectedGroupId}`
                            );
                          }}
                      >
                        {t("Click here to get started")}
                      </button>
                  )}
                </div>
            )}
          </div>
          {learningOutcomes.length > 0 && (
              <div className="d-flex align-items-center justify-content-end">
                <button
                    className="btn btn-outline-primary m-2 font-weight-normal"
                    disabled={learningOutcomes.length === 0}
                    onClick={() => {
                      history.push(
                          `${LEARNING_OUTCOMES_UPDATE}?group=${commonState.selectedGroupId}`,
                          {
                            selectedGroupId: commonState.selectedGroupId,
                            selectedYear: commonState.selectedYear,
                            activeTab,
                          }
                      );
                    }}
                >
                  {t("Update learning outcomes")}
                </button>
                <button
                    className="btn btn-outline-primary m-2 font-weight-normal"
                    onClick={() => setIsEditLearningOutcomes(true)}
                >
                  {t("Edit")}
                </button>
              </div>
          )}
        </div>
    );
  };

  if (allKnowledgeLoading && !allKnowledgeError) {
    // return <Loading />;
  }

  if (loading) {
    return <Loading />;
  }

  return (
      <>
        <KnowledgeConfirmationModal
            show={showDeletePermanentlyModal}
            title={t("Confirm Delete")}
            message={
                t("Are you sure you want to delete? Deleted Items will be ") +
                '<b class="clr-red">' +
                t("deleted permanently.") +
                "</b>"
            }
            innerHtml
            size={"lg"}
            leftButtonText={t("Delete")}
            leftButtonClassName="btn btn-outline-danger font-weight-normal mx-2"
            rightButtonText={t("Cancel")}
            rightButtonClassName="btn btn-outline-primary font-weight-normal mx-2"
            onClickLeftButton={() => {
              setShowDeletePermanentlyModal(false);
              deleteConfirmed(deletedRowId);
            }}
            onClickRightButton={() => {
              setShowDeletePermanentlyModal(false);
            }}
            onClickClose={() => setShowDeletePermanentlyModal(false)}
        />
        <KnowledgeConfirmationModal
            show={showDeleteConfirmationModal}
            title={t("Remove Knowledge")}
            message={t(
                "Would you like to archive or delete the knowledge(s) from Knowledge base?"
            )}
            size={"lg"}
            leftButtonText={t("Delete")}
            leftButtonClassName="btn btn-outline-danger font-weight-normal mx-2"
            rightButtonText={t("Archive")}
            rightButtonClassName="btn btn-primary font-weight-normal mx-2"
            onClickLeftButton={() => {
              setShowDeleteConfirmationModal(false);
              setShowDeletePermanentlyModal(true);
            }}
            onClickRightButton={() => {
              setShowDeleteConfirmationModal(false);
              deleteConfirmed(deletedRowId, true);
            }}
            onClickClose={() => setShowDeleteConfirmationModal(false)}
        />
        <ConfirmationModal
            message={t(
                "Knowledge Group will be permanently deleted. All knowledge items/Quizzes in the knowledge group will be shifted to uncategorised"
            )}
            size={"md"}
            show={deleteModuleModalShow}
            selectedRow={deletedModuleId}
            onHide={deleteModuleModalHandler}
            confirmHandler={confirmDeleteModuleHandler}
        />

        <UpdateModuleModal
            show={showRenameModal}
            onHide={updateModalHandler}
            name={selectedModuleName}
            parent_id={selectedParentId}
            editMode={moduleEditMode}
            confirmHandler={confirmRenameHandler}
            moduleDepth={moduleDepth}
        />

        <MoveSelectedModal
            show={bulkAssignCategoryShow}
            onHide={() => BulkAssignCategoryhandler(false)}
            confirmCategoryUpdate={confirmCategoryUpdateHandler}
        />

        {props.manageDocument && (
            <>
              <Row className="mb-4 position-relative manage-document-knowledge">
                <Col lg={12} md={12} className="knowledge-modules mb-4 mb-md-0">
                  <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column">
                    {/* {console.log('allKnowledgeLoading', allKnowledgeLoading)} */}
                    {!allKnowledgeLoading && knowledgeHtml}
                  </div>
                </Col>
                {editMode && (
                    <div className="edit-knowledge-sidewrap">
                      <KnowledgeCreateFromScratch
                          resetEditKnowledgehandler={resetEditKnowledgehandler}
                          selectedIntent={selectedIntent}
                          editMode={editMode}
                          viewall
                      />
                    </div>
                )}
              </Row>
            </>
        )}

        {!props.manageDocument && (
            <>
              {!props.createFromUploadedDocument && !props.buildKnowledge && (
                <KnowledgeCrousel/>
              )}
              <div>
                {/* {!props.buildKnowledge && ( */}
                <div
                    className={classNames({
                      "levels-row mb-4 d-flex align-items-center flex-wrap justify-content-between":
                          !props.createFromUploadedDocument,
                      "levels-row mb-4 d-flex": props.createFromUploadedDocument,
                    })}
                >
                  <div
                      className={classNames({
                        "d-flex align-items-center flex-wrap": true,
                        "levels-row-width": props.createFromUploadedDocument,
                      })}
                  >
                    <h2 className="mb-0 mr-3 font-1">
                      {isMgs ? t("Subjects:") : t("Level:")}
                    </h2>

                    {years.map((year: any) => {
                      return (
                          <button
                              className={classNames({
                                "btn btn-tabs ": true,
                                active:
                                    commonState.selectedYear &&
                                    year._id === commonState.selectedYear._id,
                              })}
                              onClick={() => {
                                setHasTopLevelApplied(true);
                                getAllModulesFromYearFun(
                                    year,
                                    props.buildKnowledge ? true : false,
                                    true
                                );
                              }}
                          >
                            {year?.name}
                          </button>
                      );
                    })}
                    {commonState.selectedYear &&
                        commonState.selectedYear._id &&
                        commonState.selectedYear &&
                        !props.createFromUploadedDocument &&
                        !props.buildKnowledge && (
                            <button
                                className="btn btn-link"
                                onClick={() => {
                                  history.push(
                                      MANAGEKNOWLEDGEGROUP +
                                      "?level=" +
                                      `${
                                          commonState.selectedYear &&
                                          commonState.selectedYear.group_type === "system"
                                              ? ""
                                              : commonState.selectedYear._id
                                      }`
                                  );
                                }}
                            >
                              {t("+ Edit")}
                            </button>
                        )}
                  </div>
                  {props.createFromUploadedDocument && (
                      <div className="ml-auto text-nowrap">
                        {" "}
                        <button
                            className="btn btn-primary font-400 font-16 p-1 px-3"
                            onClick={() => {
                              const queTypes =
                                  props.queTypes && props.queTypes.length > 0
                                      ? `&queTypes=${props.queTypes.join(",")}`
                                      : "";
                              const quizId =
                                  props.isEditQuiz && props.quizId
                                      ? `&quizId=${props.quizId}`
                                      : "";
                              let docs =
                                  props.document_Id && props.document_Id.length > 0
                                      ? [...props.document_Id]
                                      : [];
                              if (docs && docs.length > 0) {
                                docs.unshift(selectedDoc[0]);
                              }
                              const documents =
                                  docs && docs.length > 0 ? [...docs] : selectedDoc;
                              history.push(
                                  REVIEW_QUIZ_QUESTIONS +
                                  `?document=${documents.join(
                                      ","
                                  )}${quizId}${queTypes}&reference=add-new`,
                                  {
                                    createFromUploadedDocument:
                                        props.isEditQuiz && props.quizId
                                            ? !props.createFromUploadedDocument
                                            : props.createFromUploadedDocument,
                                    queryString: props.queryString
                                        ? props.queryString
                                        : props.selectedGroupIdForDocument
                                            ? "?group=" + props.selectedGroupIdForDocument
                                            : `?group=${commonState.selectedGroupId}&parent=${
                                                commonState.selectedYear
                                                    ? commonState.selectedYear._id
                                                    : ""
                                            }`,
                                    isEditQuiz: props.isEditQuiz,
                                    quizId: props.quizId,
                                    questionBank: props.questionBank,
                                  }
                              );
                            }}
                            disabled={selectedDoc?.length === 0}
                        >
                          {t("View Generated Knowledge")}
                        </button>
                      </div>
                  )}
                </div>
                {/* )}  */}

                <Row className="mb-4 position-relative">
                  {commonState.selectedYear &&
                      commonState.selectedYear.group_type !== "system" && (
                          <Col
                              lg={3}
                              md={4}
                              className={`knowledge-modules mb-4 mb-md-0 ${
                                  props.buildKnowledge ? "toolkit-wrap" : ""
                              }`}
                          >
                            <div className="knowledge-box p-0 build-knowbox text-left d-flex flex-column">
                              <div className="d-flex justify-content-between align-items-center px-3 py-2">
                                <h2 className="mb-0 font-1">
                                  {isMgs ? t("Chapters") : t("Modules")}
                                </h2>
                                {!props.createFromUploadedDocument &&
                                    !props.buildKnowledge && (
                                        <button
                                            className="btn btn-link font-sm px-0 py-1"
                                            onClick={() => {
                                              updateParentHandler(
                                                  null,
                                                  commonState.selectedYear._id,
                                                  false,
                                                  -1
                                              );
                                            }}
                                        >
                                          {t("+ Add")}
                                        </button>
                                    )}
                              </div>
                              {modules.length > 0 && (
                                  <div className="modules-list flex-grow-1 scroll-wrap">
                                    <ul className="list-unstyled">
                                      {modules.map((level: any) => {
                                        return (
                                            <li
                                                onClick={() => {
                                                  if (level.depth === 0) {
                                                    setSelectedParentModule(level);
                                                  }
                                                }}
                                                key={
                                                    "child_padding_dev_" +
                                                    level.value +
                                                    "_" +
                                                    Math.floor(Math.random() * 9999)
                                                }
                                                style={{
                                                  paddingLeft: `${
                                                      level.depth > 0
                                                          ? level.depth * 10 + 10
                                                          : 10
                                                  }px`,
                                                  display: `${
                                                      isBranchUnderSelectedModule(level) ||
                                                      level.depth === 0
                                                          ? "block"
                                                          : "none"
                                                  }`,
                                                }}
                                                className={classNames({
                                                  "clr-grey-txt": level.depth > 0,
                                                  active:
                                                      commonState.selectedGroupId ===
                                                      level.value,
                                                })}
                                            >
                                              <a
                                                  href="javascript:void(0);"
                                                  onClick={() => {
                                                    setSelectedNodes([]);
                                                    setHasTopLevelApplied(false);
                                                    setCurrentPage(1);
                                                    onSelectGroupId(level.value);
                                                  }}
                                                  className={classNames({
                                                    "clr-grey-txt": level.depth > 0,
                                                  })}
                                              >
                                                {level?.label}
                                              </a>
                                              <Dropdown className="ml-auto action-dropdown">
                                                <Dropdown.Toggle
                                                    variant="default"
                                                    id="dropdown-basic"
                                                >
                                                  <svg
                                                      width="14"
                                                      height="4"
                                                      viewBox="0 0 14 4"
                                                      fill="none"
                                                  >
                                                    <path
                                                        d="M2 3.5C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5C2.82843 0.5 3.5 1.17157 3.5 2C3.5 2.82843 2.82843 3.5 2 3.5ZM7 3.5C6.17157 3.5 5.5 2.82843 5.5 2C5.5 1.17157 6.17157 0.5 7 0.5C7.82843 0.5 8.5 1.17157 8.5 2C8.5 2.82843 7.82843 3.5 7 3.5ZM12 3.5C11.1716 3.5 10.5 2.82843 10.5 2C10.5 1.17157 11.1716 0.5 12 0.5C12.8284 0.5 13.5 1.17157 13.5 2C13.5 2.82843 12.8284 3.5 12 3.5Z"
                                                        fill="#6B7085"
                                                    />
                                                  </svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                      onClick={() => {
                                                        updateParentHandler(
                                                            level.name,
                                                            level._id,
                                                            true,
                                                            level.depth === 0 ? -1 : level.depth
                                                        );
                                                      }}
                                                  >
                                                    {t("Edit")}
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                      onClick={() => {
                                                        setDeleteModuleModalShow(true);
                                                        setDeletedModuleId(level._id);
                                                      }}
                                                  >
                                                    {t("Delete")}
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                      onClick={() => {
                                                        updateParentHandler(
                                                            "",
                                                            level._id,
                                                            false,
                                                            level.depth
                                                        );
                                                      }}
                                                  >
                                                    {t("Add")}{" "}
                                                    {level.depth === 0 || !level.depth
                                                        ? "Topic"
                                                        : level.depth > 0
                                                            ? "Subtopic"
                                                            : "Module"}
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                              )}
                              {modules.length === 0 && (
                                  <div className="empty-knowledge text-center my-auto">
                                    <button
                                        className="btn"
                                        onClick={() => {
                                          setSelectedParentId(commonState.selectedYear._id);
                                          setSelectedModuleName("");
                                          updateModalHandler();
                                        }}
                                    >
                                      <img src={plusIcon} alt="" />
                                    </button>
                                    <h3 className="h6 clr-primary mb-0 font-weight-bold">
                                      {t("Click on Add a Module")}
                                    </h3>
                                  </div>
                              )}
                              {!props.createFromUploadedDocument &&
                                  !props.buildKnowledge && (
                                      <div className="text-center px-3 py-2">
                                        <Link to={`/knowledge/archived`}>
                                          <img src={archivedKnowledgeImg} alt="" />
                                          <button className="btn btn-link">
                                            {t("Archived")}
                                          </button>
                                        </Link>
                                      </div>
                                  )}
                              {/* empty state */}
                            </div>
                          </Col>
                      )}

                  {/* {selectedYear.group_type === "system" && (
              <Col lg={12} md={12} className="knowledge-viewby">
              )}
              {selectedYear.group_type !== "system" && (
              )} */}

                  <div
                      className={classNames({
                        "knowledge-viewby": true,
                        "col-lg-9 col-md-8":
                            commonState.selectedYear &&
                            commonState.selectedYear.group_type !== "system",
                        "col-lg-12 col-md-12":
                            commonState.selectedYear &&
                            commonState.selectedYear.group_type === "system",
                      })}
                  >
                    <div className="knowledge-box px-3 py-2 build-knowbox text-left">
                      {!props.isContexualised && !props.buildKnowledge && (
                          <h2 className="mb-0 mr-3 font-1 d-md-inline-block">
                            {t("View by:")}{" "}
                          </h2>
                      )}
                      {props.isContexualised && (
                          <UploadedDocuments
                              isArchived={false}
                              activeTab={activeTab}
                              queryString={
                                props.selectedGroupIdForDocument
                                    ? "?group=" + props.selectedGroupIdForDocument
                                    : `?group=${commonState.selectedGroupId}&parent=${
                                        commonState.selectedYear
                                            ? commonState.selectedYear._id
                                            : ""
                                    }`
                              }
                              hasTopLevelApplied={hasTopLevelApplied}
                              selectedGroupId={commonState.selectedGroupId}
                              isMgs={isMgs}
                              createFromUploadedDocument={
                                props.createFromUploadedDocument
                              }
                              isContexualised={props.isContexualised}
                              sendSelectedDocsToParent={props.sendSelectedDocsToParent}
                              buildKnowledge={props.buildKnowledge}
                              toolkitQuestionTypes={props.toolkitQuestionTypes}
                              toolkitQuizId={props.toolkitQuizId}
                              toolkitSelectedGroupId={props.toolkitSelectedGroupId}
                              showAllDocuments={props.showAllDocuments}
                              type={props.type}
                              setSelectedDoc={setSelectedDoc}
                              questionBank={props.questionBank}
                              document_Id={props.document_Id}
                              addMoreQuestionFromDocument={
                                props.addMoreQuestionFromDocument
                              }
                              flowKey={props.flowKey}
                              popupProperties={props.popupProperties}
                          />
                      )}

                      <Tabs
                          defaultActiveKey={
                            isMgs || props.createFromUploadedDocument
                                ? "viewDocument"
                                : defaultTab
                          }
                          onSelect={(e: any) => {
                            if (props.onTabChanges) {
                              props.onTabChanges(e);
                            }
                            setActiveTab(e);
                          }}
                      >
                        {!props.isContexualised && (
                            <Tab eventKey="viewDocument" title={t("Documents")}>
                              <UploadedDocuments
                                  isArchived={false}
                                  activeTab={activeTab}
                                  queryString={
                                    props.selectedGroupIdForDocument
                                        ? "?group=" + props.selectedGroupIdForDocument
                                        : `?group=${commonState.selectedGroupId}&parent=${
                                            commonState.selectedYear
                                                ? commonState.selectedYear._id
                                                : ""
                                        }`
                                  }
                                  hasTopLevelApplied={hasTopLevelApplied}
                                  selectedGroupId={commonState.selectedGroupId}
                                  isMgs={isMgs}
                                  createFromUploadedDocument={
                                    props.createFromUploadedDocument
                                  }
                                  isContexualised={props.isContexualised}
                                  isEditQuiz={props.isEditQuiz}
                                  quizId={props.quizId}
                                  onSetRecentUploadedDoc={(e: any) => {
                                    if (e) {
                                      getRecentUploadedDocument();
                                    }
                                  }}
                                  buildV2={props.buildV2}
                                  buildKnowledge={props.buildKnowledge}
                                  toolkitQuestionTypes={props.toolkitQuestionTypes}
                                  toolkitQuizId={props.toolkitQuizId}
                                  toolkitSelectedGroupId={props.toolkitSelectedGroupId}
                                  showAllDocuments={props.showAllDocuments}
                                  type={props.type}
                                  setSelectedDoc={setSelectedDoc}
                                  questionBank={props.questionBank}
                                  document_Id={props.document_Id}
                                  addMoreQuestionFromDocument={
                                    props.addMoreQuestionFromDocument
                                  }
                                  flowKey={props.flowKey}
                                  popupProperties={props.popupProperties}
                              />
                              {/* {activeTab === "viewDocument" && (
                        )} */}
                            </Tab>
                        )}

                        {!isMgs &&
                            !props.createFromUploadedDocument &&
                            !props.buildKnowledge && (
                                <Tab eventKey="viewKnowledge" title={t("Knowledge")}>
                                  {knowledgeHtml}
                                </Tab>
                            )}

                        {!isMgs &&
                            !props.createFromUploadedDocument &&
                            !props.buildKnowledge && (
                                <Tab
                                    eventKey="viewLearningOutcomes"
                                    title={t("Learning Outcomes")}
                                >
                                  {renderOutcomes()}
                                </Tab>
                            )}
                      </Tabs>
                    </div>
                  </div>
                  {isTabLoading && (
                      <div className="tab-loading">
                        <span className="doc-loader spinner-width-40"></span>
                      </div>
                  )}
                  {editMode && (
                      <div className="edit-knowledge-sidewrap">
                        <KnowledgeCreateFromScratch
                            resetEditKnowledgehandler={resetEditKnowledgehandler}
                            selectedIntent={selectedIntent}
                            editMode={editMode}
                            viewall
                        />
                      </div>
                  )}

                  {isEditLearningOutcomes && renderOutcomes()}
                </Row>
              </div>
              {props.buildKnowledge && (
                  <ProgressSteps
                      currentStep={1}
                      steps={props.summariseDocument ? 3 : 4}
                  />
              )}
            </>
        )}
      </>
  );
}

export default KnowledgeDatatableComponentGridView;
