import React, { useState, useEffect } from 'react'
import { Image, Modal, ModalFooter } from 'react-bootstrap'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import DataTable from 'react-data-table-component'
import Moment from 'react-moment'
import Pagination from '../../../../../components/UI/Pagination/Pagination'
import { HelperService } from '../../../../../services/helper.service'
import { ChartTableSortIcon } from '../../../../Knowledge/AllKnowledge'
import { conversationsCustomStyles, customStyles } from './learningUtils'
import close from '../../../../../assets/images/close-blk.svg'

import { useTranslation } from "react-i18next";

const ConversationsModal = (props: any) => {
  const { t } = useTranslation('translation');
  const helperService = new HelperService()
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationState, setPaginationState] = useState(null)
  const [paginatedrecords, setPaginatedRecords] = useState([])
  const tutoringFlowColumns = [
    {
      name: t('Date asked'),
      selector: 'date',
      wrap: true,
    },
    {
      name: t('User asked'),
      selector: 'userAsked',
      wrap: true,
    },
    {
      name: t('Bot replied'),
      selector: 'botReplied',
      wrap: true,
    },
    {
      name: t('Topic'),
      selector: 'topic',
      wrap: true,
    },
    {
      name: t('Subtopic'),
      selector: 'subtopic',
      wrap: true,
    },
  ]

  useEffect(() => {
    paginate(props.showConversationsData)
    return () => {}
  }, [currentPage, perPage])

  const paginate = (matchedRecords: any[]) => {
    const paginationData = helperService.paginator(
      matchedRecords,
      currentPage,
      perPage
    )
    setPaginationState({
      ...paginationState,
      page: currentPage,
      page_size: perPage,
      total_items: matchedRecords.length,
      total_pages: paginationData.total_pages,
    })
    setPaginatedRecords([...paginationData.data])
  }

  const handlePageChange = async (page: number) => {
    await setCurrentPage(page)
  }

  const transformModalTableData = (data: any) => {
    return data.map((item: any) => {
      return {
        date: <Moment format='DD-MM-YYYY'>{item.created_at}</Moment>,
        userAsked: item.question.text,
        botReplied: item.reply.text,
        topic:
          item.context &&
          item.context.topic_id &&
          props.topicsMap[item.context.topic_id]
            ? props.topicsMap[item.context.topic_id]
            : '-',
        subtopic:
          item.context &&
          item.context.subtopic_id &&
          props.subtopicsMap[item.context.subtopic_id]
            ? props.subtopicsMap[item.context.subtopic_id]
            : '-',
      }
    })
  }

  return (
    <Modal
      show={props.show}
      size={props.size}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      onHide={props.onHide}
    >
      <ModalHeader className=''>
        <ModalTitle id='contained-modal-title-vcenter'>
          <button className='btn' onClick={props.onHide}>
            <Image src={close} />
          </button>
        </ModalTitle>
      </ModalHeader>
      <ModalBody className='py-4'>
        <div className='px-4'>
          <DataTable
            className='table-wraper'
            noContextMenu
            noHeader
            fixedHeader
            fixedHeaderScrollHeight='700px'
            columns={tutoringFlowColumns}
            data={transformModalTableData(paginatedrecords)}
            customStyles={conversationsCustomStyles}
            sortIcon={<ChartTableSortIcon />}
            // paginationPerPage={perPage}
            // paginationDefaultPage={1}
            // paginationResetDefaultPage={false}
            // paginationTotalRows={totalRows}
            // onChangeRowsPerPage={handlePerRowsChange}
            // onChangePage={handlePageChange}
            // progressPending={loading} //
            // progressComponent={
            //   <div style={{ padding: 10, width: '100%' }}>
            //     <QuestionListSkeleton count={12} />
            //   </div>
            // }
            defaultSortAsc={true}
          />

          {/* -----------------  Pagination   --------------------------- */}
          {paginatedrecords && paginatedrecords.length > 0 && paginationState && (
            <Pagination
              currentPage={currentPage}
              pagination={{
                ...paginationState,
              }}
              paginationPerPage={perPage}
              pageChange={(newPerPage: number, page: number) => {
                setPerPage(newPerPage)
                setCurrentPage(1)
              }}
              onChangePage={handlePageChange}
              hideSuggestedQuestion
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConversationsModal
