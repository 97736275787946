import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Image,
  Modal,
  ModalFooter,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import closeimg from "../../../assets/images/close-blk.svg";
import { HelperService } from "../../../services/helper.service";
import { useDispatch } from "react-redux";
import {
  updateAgentById,
  updateSelectedAgent,
} from "../../../store/agents/actions";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../../context/GlobalContext";
import closeImg from "../../../assets/New-images/build-knowledge-close-img.svg";
import pinkInfoCircle from "../../../assets/images/pink-warning.svg";
import KnowledgeService from "../../../services/KnowledgeService";

const helperService = new HelperService();

export default function UpdateAgent(props: any) {
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const [name, setname] = useState(null);
  const [description, setdescription] = useState(null);
  const [id, setid] = useState(null);
  const [loading, setloading] = useState(false);
  const { setSelectedAgentName } = useGlobalContext();
  const [selectedPersona, setSelectedPersona] = useState({
    value: "",
    label: "",
  });
  const [agentSettings, setAgentSetting] = useState<any>({ settings: {} });
  const service = new KnowledgeService();
  const [selectedAgent, setSelectedAgent] = useState(
    localStorage.getItem("selectedAgent")
  );
  const [personaOptions, setPersonaOptions] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    setname(props.name);
    setdescription(props.description);
    setid(props.agentid);
    return () => {};
  }, [props]);

  useEffect(() => {
    if (props.show) {
      let personas: any[] = [];
      const settings = props.editAgent?.settings;
      const getAgentPersona = async (id: any) => {
        const { status, data } = await service.getAgentPersona(id);
        if (status === 200) {
          personas = data?.data?.personas;
          if (personas && personas.length > 0) {
            setPersonaOptions(
              personas.map((i: any) => {
                return {
                  value: i.key,
                  label: i.name,
                };
              })
            );
            if (settings && "chat_persona" in settings) {
              const { chat_persona } = settings;
              const defaultPersona = personas.find(
                (i: any) => i.key === chat_persona
              );
              setSelectedPersona({
                value: defaultPersona.key,
                label: defaultPersona.name,
              });
            }
          }
        }
      };
      getAgentPersona(props.agentid);
      return;
    }
    return;
  }, [props.show]);

  const inputChangeHandler = (e: any) => {
    e.preventDefault();
    if (e.target.name === "name") {
      setname(e.target.value);
    }

    if (e.target.name === "description") {
      setdescription(e.target.value);
    }
  };

  const saveAgentInfoHandler = async (e: any) => {
    e.preventDefault();
    if (!name || name.length === 0) {
      helperService.showToaster(t("Agent name is required."));
      return;
    }

    if (!description || description.length === 0) {
      helperService.showToaster(t("Agent description is required."));
      return;
    }
    await setloading(true);
    try {
      const updatedAgent: any = await dispatch(
        updateAgentById(id, {
          name,
          description,
        })
      );
      if (selectedAgent === updatedAgent._id) {
        await setSelectedAgentName(name);
        const response: any = await dispatch(
          updateSelectedAgent({
            states: {
              selectedAgentName: name,
            },
          })
        );
      }
      await setloading(false);
      return props.hideAfterUpdate(updatedAgent._id, selectedPersona);
    } catch (error) {
      await setloading(false);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <ModalHeader>
        <ModalTitle id='contained-modal-title-vcenter'>
          <Button onClick={props.onHide}>{<Image src={closeimg} />}</Button>
        </ModalTitle>
      </ModalHeader> */}
      <ModalHeader className="clr-black py-3 px-4 justify-content-between border-bottom">
        <ModalTitle id="contained-modal-title-vcenter">
          <h2 className="mb-0 font-1">{t("Edit Agent")}</h2>
        </ModalTitle>
        <button className="btn" onClick={props.onHide}>
          <img src={closeImg} alt="" />
        </button>
      </ModalHeader>
      <ModalBody className="px-4">
        <label htmlFor="" className="font-600">
          {t("Agent Name")}
        </label>
        <input
          type="text"
          className="form-control h-auto radius-4 mb-4"
          onChange={(e: any) => {
            inputChangeHandler(e);
          }}
          placeholder="Agent Name"
          value={name}
          name="name"
        />
        <h3 className="font-600">{t("Agent Description")}</h3>
        <textarea
          className="form-control radius-4 mb-4"
          onChange={(e: any) => {
            inputChangeHandler(e);
          }}
          placeholder="Description of Agent"
          value={description}
          name="description"
        />
        <div
          className="agent-persona position-relative d-inline-flex align-items-center mb-2"
          ref={ref}
        >
          <h3 className="font-600 mb-0">{t("Agent Persona")}</h3>
          <OverlayTrigger
            placement="right-start"
            container={ref}
            overlay={
              <Tooltip className="persona-tooltip" id="tooltip-prsnoa">
                {/* <div> */}
                <span>{t("Select the chatbot behaviour:")}</span>
                <ul className="pl-4 mb-0">
                  <li>
                    <span className="font-600">{t("Learning Tutor:")}</span>
                    {t(
                      " Provides guidance by answering learner questions and offering explanations on specific topics or subjects."
                    )}
                  </li>
                  <li>
                    <span className="font-600">
                      {t("Learning Facilitator:")}
                    </span>
                    {t(" Promotes learning via socratic dialogue.")}
                  </li>
                </ul>
                {/* </div> */}
              </Tooltip>
            }
          >
            <button className="btn py-0 px-2">
              <Image src={pinkInfoCircle} />
            </button>
          </OverlayTrigger>
        </div>
        <Select
          // key={selectedBtn?.reference_id}
          isSearchable
          className="select-sort select-target shadow-none"
          classNamePrefix="select-target-prefix"
          components={{
            IndicatorSeparator: () => null,
          }}
          defaultValue={selectedPersona}
          value={selectedPersona}
          options={personaOptions}
          onChange={(e: any) => {
            setSelectedPersona({
              value: e?.value,
              label: e?.label,
            });
            // setIsDisableSave(false);
          }}
        />
      </ModalBody>
      <ModalFooter className="border-0 pb-4">
        <button
          className="btn btn-outline-primary"
          disabled={loading}
          onClick={props.onHide}
        >
          {t("Cancel")}
        </button>
        <Button
          disabled={loading}
          onClick={saveAgentInfoHandler}
          className="ml-3"
        >
          {t("Save")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
