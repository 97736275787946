import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import {
  Button,
  Col,
  Container,
  Image,
  ProgressBar,
  Row,
} from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import * as _ from "lodash";
import closeimg from "../../../assets/images/close-red.svg";
import pptimg from "../../../assets/images/file-ppt.svg";
import pdfimg from "../../../assets/images/file-pdf.svg";
import docximg from "../../../assets/images/file-doc.svg";
import backArrow from "../../../assets/images/arrow-left.svg";

import DragAndDrop from "../../../components/UI/DragAndDrop";
import IconLabelButton from "../OldPortalComponents/IconLabelButton";
import InfoBlock from "../OldPortalComponents/InfoBlock";
import "./index.scss";

import { useDispatch } from "react-redux";
import { HelperService } from "../../../services/helper.service";
import { uploadFaqUrlAndDocument } from "../../../store/knowledge/actions";
import { useTranslation } from "react-i18next";
import {
  getAllQuestionBank,
  getAllQuestionBankNoPaging,
  getQuizDocumentsContents,
  quizDocumentQuestions,
  saveQuizQuestionsBasedOnFile,
  updateQuiz as updateQuizAction,
} from "../../../store/quizzes/actions";
import { IMPORTQUESTIONBANK_PREVIEW } from "../../../config";
import Loading from "../../../components/UI/Loading/Loading";

const ImportQuestionBank = (props: any) => {
  let history = useHistory();
  const { t } = useTranslation("translation");
  const dispatch = useDispatch();
  const useLocatio = useLocation();
  let re = /(?:\.([^.]+))?$/;
  const helper = new HelperService();
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const [documentContent, setDocumentContent] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [school, setSchool] = useState(null);
  const [schools, setSchools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUploadType, setSelectedUploadType] =
    useState("customtemplates");
  const [questionBankDocumentId, setQuestionBankDocumentId] = useState("");
  const [isFileInValid, setIsFileInValid] = useState(false);
  const [documents, setDocuments] = React.useState([]);
  const [showError, setShowError] = useState({
    state: false,
    message: "Please enter a valid URL",
  });
  const isMgs = process.env.REACT_APP_CISCO === "mgs"
  //   const useDidMountEffect = (func, deps) => {
  //   const didMount = useRef(false);

  const getSchools = async() => {
    try {
      setIsLoading(true)
      const response: any = await dispatch(
        getAllQuestionBankNoPaging({includes_all_subgroups: true})
      );
      setIsLoading(false)
      const allSchools = response
        .filter(
          (s: any) =>
            s.custom_attribute_1 !== undefined && s.custom_attribute_1 !== null
        )
        .map((s: any) => ({
          value: s.custom_attribute_1,
          selected: false,
          label: s.custom_attribute_1,
          _id: s.custom_attribute_1
        }));

      setSchools(_.uniqBy(allSchools, "value"));
    } catch (error) {
      setIsLoading(false)
      console.log("[ error ]", error);
    }
  }

  useEffect(() => {
    setDocuments([]);
    if(isMgs){
      getSchools()
    }
  }, []);

  const handleDocRemove = (e: React.MouseEvent<HTMLElement>, index: number) => {
    const newDocuments = [...documents];
    const slicedDoc = newDocuments.splice(index, 1);
    // if (slicedDoc && slicedDoc[0] && slicedDoc[0].isUploaded) {
    //   dispatch(removeDocumentByDocumentId(slicedDoc[0]._id));
    // }
    setDocuments(newDocuments);
  };

  const getFileDocumentsHandler = async (d: File[], type: any) => {
    setIsFileInValid(false);
    setIsFileUploading(false);
    setSelectedFileType(type);
    let totalDocuments = 0;
    totalDocuments = documents.length + d.length;
    if (totalDocuments > 1) {
      helper.showToaster(t("You can upload only single file."));
      return;
    }
    const oldDocuments = [...documents];
    await setDocuments([
      ...oldDocuments,
      ...d.map((doc: any) => {
        doc.sizeError = doc.size / (1024 * 1024) > 10;
        return doc;
      }),
    ]);
    // updateFileData(oldDocuments[0], type);
    setHasFileUploaded(false);
  };

  const uploadFile = async () => {
    // if (selectedFileType === "customtemplates") {
    //   getQuizDocumentContent();
    //   return;
    // }

    if (documents.length === 0) {
      setIsFileInValid(true);
      helper.showToaster(
        t("Please choose documents or add url before proceeding.")
      );
      return;
    }

    const formData = new FormData();
    formData.append("file", documents[0]);
    formData.append("filepath", documents[0].name);
    const requestOptions = {
      method: "POST",
      body: formData,
    };

    try {
      const questionsBasedOnFileResponse: any = await dispatch(
        saveQuizQuestionsBasedOnFile(formData)
      );
      updateQuiz(questionsBasedOnFileResponse.questions);
    } catch (error) {
      helper.showToaster(t("Oops! Could not upload."));
    }
  };

  const savetoDatabase = async () => {
    if (documents.length === 0) {
      setIsFileInValid(true);
      helper.showToaster(
        t("Please choose document before proceeding.")
      );
      return;
    } else {
      setIsFileUploading(true);

      const oldDocuments = [...documents];
      let document: any;
      const query = new URLSearchParams(useLocatio.search);
      const group = query.get("group");

      if (documents.length > 0) {
        document = oldDocuments[0];
        document.isUploading = true;
      }

      const uploadedDocument: any = await dispatch(
        uploadFaqUrlAndDocument(
          {
            file: documents ? documents[0] : null,
            file_name: documents[0].name,
            faqType: "quiz",
            group,
          },
          (event: any) => {
            if (documents.length > 0) {
              document.progress = Math.round(
                (100 * event.loaded) / event.total
              );
              setDocuments([...oldDocuments]);
            }
          }
        )
      );

      if (documents.length > 0) {
        if (uploadedDocument && uploadedDocument.data.status.code !== 200) {
          document.isUploaded = false;
          document.isError = true;
          document.isUploading = false;
          document.message = uploadedDocument.status.message;
          setDocuments([...oldDocuments]);
        } else {
          getQuizDocumentContent(uploadedDocument.data.data.document._id);
          setQuestionBankDocumentId(uploadedDocument.data.data.document._id);
        }
      }
    }
  };

  const getQuizDocumentContent = async (documentId: any) => {
    const query = new URLSearchParams(useLocatio.search);
    const group = query.get("group");

    try {
      const documentContent: any = await dispatch(
        getQuizDocumentsContents(documentId)
      );
      setDocumentContent(documentContent.document_content);
      if (documentContent.document_content) {
        const groupQuery = group? `?group=${group}`: ''
        history.push(`/import-questions-bank/preview/${documentId}${groupQuery}`, {
          quizId: props.location.state?.quizId,
          school: school? school?.value: ''
        })
      } else {
        let quizArray: any[] = [];
        history.push(`/quizzes/edit/${props.location.state.quizId}`, {
          selectedFileType: selectedFileType === "customtemplates",
          documentContent: documentContent.document_content,
          quizArray,
          selectedFile: selectedFile,
          documentId: documentId,
          documentType: documentContent.document_type,
          group: group
        });
      }
      return;
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  const updateQuiz = async (
    questions: any,
    content: any = null,
    documentId: any = null,
    documentType: any = null
  ) => {
    
    const query = new URLSearchParams(useLocatio.search);
    const group = query.get("group");

    let quizArray: any = { questions: [] };
    questions.forEach(function (element: any) {
      let quiz = {
        ...element,
        id: element.id ? element.id : uuidv4(),
      };
      if (quiz.ideal_answer === null) {
        quiz.ideal_answer = "";
      }
      
      quizArray.questions.push({
        ...quiz,
        text: quiz.text.replaceAll('\n', "<br>")
      });
    });

    if (selectedFileType === "customtemplates") {
      history.push("/quizzes/edit/" + uuidv4(), {
        selectedFileType: selectedFileType === "customtemplates",
        documentContent: content,
        quizArray,
        selectedFile: selectedFile,
        documentId: documentId,
        documentType,
        isMgs: true,
        group: group
      });
      return;
    }

    try {
      await dispatch(
        updateQuizAction(props.location.state.quizId, quizArray)
      );
      history.push("/quizzes/edit/" + props.location.state.quizId, {
        selectedFileType: selectedFileType === "customtemplates",
      });
    } catch (e) {}
  };

  const handleNextClick = () => {
    if (selectedFileType === "customtemplates") {
      savetoDatabase();
    } else {
      uploadFile();
    }
  };

  const handleRadioChange = (value: any) => {
    setSelectedUploadType(value);
    setSelectedFile("");
    setSelectedName("");
  };

  const createNewSchool = (value: string) => {
    const newSchool = { _id: value, label: value, value: value }
    setSchools([...schools, newSchool]);
    setSchool(newSchool)
  };

  const RenderSelectedDocuments =
    documents &&
    documents.map((doc: any, index: number) => {
      const fileType = re.exec(doc.name)[1];
      let img = pdfimg;
      if(fileType.includes('doc')){
        img= docximg
      } else if (fileType.includes('ppt')){
        img= pptimg
      }

      return (
        <div className="files d-flex align-items-center" key={index}>
          <Image src={img} />
          <div className="mx-3">
            <strong className={doc.sizeError ? "f-error" : ""}>
              {doc.name}
            </strong>
            {!doc.sizeError && <span>{helper.formatBytes(doc.size)}</span>}
            {doc.sizeError && (
              <span className="f-error">
                {t("File not uploaded. File size is more than 10 MB.")}
              </span>
            )}
            <ProgressBar now={doc.progress} />
            {doc.isError && <span>{doc.message}</span>}
          </div>
          {!doc.isUploading && !isFileUploading && (
            <button
              className="btn ml-auto p-0"
              onClick={(e) => handleDocRemove(e, index)}
            >
              <Image src={closeimg} />
            </button>
          )}
        </div>
      );
    });

  if(isLoading){
    return <Loading />
  }

  return (
    // I want to use Noodle Factory template to upload quiz questions

    <section
      className={classNames({
        "page-mid-wraper": true,
        "h-without-foter": true,
      })}
    >
      <div>
        <Row>
          <Col md={12}>
            <button
              className="btn p-0 btn-back"
              onClick={() => {
                history.goBack();
              }}
            >
              <img src={backArrow} alt="" />
              {t("Return to previous page")}
            </button>
            <h1 className="m-0 d-flex align-items-center">
              {t("Upload a Test")}
            </h1>
            <h2 className="mb-24 font-weight-normal">
              {t("Select the test document to upload")}
            </h2>
          </Col>
        </Row>
        <div className="upload-document-page-container add-faq-container">
          <div className="page-content-container">
            {selectedUploadType !== "customtemplates" && (
              <div className="clearfix" />
            )}
            {selectedUploadType === "customtemplates" && (
              <React.Fragment>
                <InfoBlock>
                  {t(
                    "Here are some tips for you to clean your document before upload. This will help us to extract questions more accurately."
                  )}
                  <ul>
                    <li>
                      {t("It is recommended that the document consists of")}{" "}
                      <b>{t("only question and answer pairs.")}</b>{" "}
                      {t(
                        "Avoid any other details apart from question and answer pairs."
                      )}
                    </li>

                    <li>
                      {t("It is good if the")}{" "}
                      <b>
                        {t(
                          "question and answer pairs are separated by one or more empty/new lines."
                        )}
                      </b>{" "}
                      {t(
                        "Avoid adding empty/new lines within the same question and answer pairs."
                      )}
                    </li>

                    <li>
                      {t("We recommend that you")}{" "}
                      <b>{t("number the question and answer pairs.")}</b>{" "}
                      {t("Example, 1., 2., 3., or 1), 2), 3) and so on.")}
                    </li>

                    <li>
                      {t(
                        "If you have multiple choice questions, then it is good to"
                      )}{" "}
                      <b>{t("number the options")}</b>{" "}
                      {t(
                        "using alphabets like A., B., C. or a), b), c) and so on."
                      )}
                    </li>

                    <li>
                      {t(
                        "If you have paragraph type question and answer pairs, it is recommended to have answers starting with the 'Answer' keyword. Example,"
                      )}{" "}
                      <b>{t("Answer: This is the answer of the question.")}</b>
                    </li>
                  </ul>
                </InfoBlock>
                {isMgs &&  
                  <div className="mt-3">
                    <div>
                      <b>{t("Name the school that this test belongs to")}</b>
                    </div>  
                    <div className="font-400" style={{ color: "#9398AB" }}>
                      {t(
                        "Questions generated from this document would be tagged to this school"
                      )}
                    </div>
                    <Row>
                      <Col md={4}>
                           <div className="my-3">
                            <CreatableSelect
                              className="select-target radius-2"
                              classNamePrefix="select-creatable-prefix"
                              isClearable
                              onChange={(value: any)=>{
                                if(value){
                                  setSchool(value)
                                }else{
                                  setSchool(null)
                                }
                              }}
                              placeholder={t("Input school name")}
                              onCreateOption={createNewSchool}
                              value={school}
                              options={schools}
                            />
                          </div>
                      </Col>
                    </Row>
                </div>
                }
               

                <div className="faq-upload-container mt-4 text-center mb-3">
                  {/* <FileUpload
                    {...props}
                    uploadOnly
                    updateFileData={(data) =>
                      updateFileData(data, "customtemplates")
                    }
                    supportedTypes={".docx, .txt, .pdf"}
                  /> */}

                  <DragAndDrop
                    getFileDocumentsHandler={(documents: any[]) => {
                      if (!documents.length) {
                        return;
                      }
                      getFileDocumentsHandler(documents, "customtemplates");
                    }}
                    // supportedTypes={".xlsx"}
                    supportedTypes={".docx, .txt, .pdf"}
                    message={`<h2 className='mb-1'>Drop your files here or click to browse.</h2><div>Supported File types: .docx, .txt, .pdf}</div>`}
                    maxFiles={1}
                    fileSizeMessage={showError ? showError.message : ""}
                    {...props}
                  />
                </div>

                <div className="justify-content-center text-center">
                  <div className="selected-files">
                    {RenderSelectedDocuments}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>

          <div className="page-content-container mt-4 d-none">
            {/* Unformatted Upload Radio */}
            <div className="custom-control custom-radio mb-0">
              <input
                id="nfaquiztemplates-radio"
                type="radio"
                value="nfaquiztemplates"
                className="custom-control-input faq-option-radio"
                name="faq-upload-option"
                checked={selectedUploadType === "nfaquiztemplates"}
                onChange={(e) => handleRadioChange(e.target.value)}
              />
              <label
                className="custom-control-label font-weight-bold"
                htmlFor="formatted-radio"
              >
                {t(
                  "I want to use Noodle Factory template to upload quiz questions"
                )}
              </label>
            </div>
            <div className="radio-label-wrapper pl-4">
              {t(
                "Choose this if you have a formatted list of quiz questions and answers in a document. You can download the"
              )}{" "}
              <IconLabelButton
                showIcon
                iconClass="icon-download"
                buttonLabel="sample quiz template"
                useDownload
                // fileSrc={SampleQuizTemplate}
                fileSrc={
                  "https://oldportal.staging.noodlefactory.ai/static/media/sample-quiz-template.79b29ea8.docx"
                }
              />
              {t(
                "and edit it before uploading. Note that the document must be in the given format."
              )}
            </div>

            {/* /////////////////  */}
            {/* <div className='right-align'>
                <IconLabelButton
                    showIcon
                    iconClass='icon-download'
                    buttonLabel='DOWNLOAD QUIZ SAMPLE'
                    useDownload
                    fileSrc={SampleQuizTemplate}
                />
                </div>
                <InfoBlock>
                Please follow the exact template for importing quiz, otherwise quiz
                may fail to import.
                </InfoBlock> */}

            {selectedUploadType === "nfaquiztemplates" && (
              //   <FileUpload
              //     {...props}
              //     updateFileData={(data) =>
              //       updateFileData(data, "nfaquiztemplates")
              //     }
              //     supportedTypes={".docx, .txt"}
              //     showError={showError}
              //   />
              <>
                <div className="faq-upload-container mt-4 text-center mb-3">
                  <DragAndDrop
                    getFileDocumentsHandler={(documents: any[]) => {
                      if (!documents.length) {
                        return;
                      }
                      getFileDocumentsHandler(documents, "nfaquiztemplates");
                    }}
                    // supportedTypes={".xlsx"}
                    supportedTypes={".docx, .txt"}
                    message={`<h2 className='mb-1'>Drop your files here or click to browse.</h2><div>Supported File types: .docx, .txt</div>`}
                    maxFiles={1}
                    fileSizeMessage={showError ? showError.message : ""}
                    {...props}
                  />
                </div>
                <div className="justify-content-center text-center">
                  <div className="selected-files">
                    {RenderSelectedDocuments}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="page-footer p-0">
            <Button
              //   disabled={isFileInValid ? false : true}
              className="btn btn-primary"
              onClick={handleNextClick}
            >
              {t("Import Quiz")}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImportQuestionBank;
