import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  Dropdown,
  Row,
  useAccordionToggle,
} from "react-bootstrap";

import sortIcon from "../../../assets/New-images/sort-blue-icon.svg";
import arrowIcon from "../../../assets/New-images/dropdown-arow-blue.svg";
import "./styles.scss";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import {
  deleteQuestionBankById,
  getAllQuestionBank,
  getAllQuestionBankNoPaging,
  restoreQuestion,
} from "../../../store/quizzes/actions";
import { useHistory } from "react-router";
import classNames from "classnames";
import Moment from "react-moment";
import * as _ from "lodash";
import MoveSelectedModal from "../../../components/UI/KnowledgeDatatableComponent/MoveSelectedModal";
import { bulkAssignModules } from "../../../store/knowledge/actions";
import {
  EDIT_QUESTIONS_BANK,
  EDIT_QUESTION_BANK,
  EXPORT_QUESTION_BANK,
} from "../../../config";
import CreateQuizModal from "../CreateQuizModal";
import { TwoButtonModal } from "../../../components/UI/Common";
import CreateQuestionsOptions from "./CreateQuestionsOptions";
import HeaderDescriptionEditor from "../OldPortalComponents/HeaderDescriptionEditor";
import draftjsToMd from "../../../components/UI/RichTextArea/converters/draftJsToMd";
import { convertToRaw } from "draft-js";
import QuizTableEditor from "../OldPortalComponents/QuizTableEditor";
import MatchEditor from "../OldPortalComponents/MatchEditor/MatchEditor";
import CreateQuizModalTeachersToolkit from "../../TeachersToolkit/popups/createQuizModalTeachersToolkit";
import { useGlobalContext } from "../../../context/GlobalContext";

function QuizQuestionBank({
  knowledge_group_ids,
  includes_all_subgroups,
  getAllYearsFun,
  setRecentQuestion,
  isArchive,
  isMgs,
  getSelectedNodes,
  selectedYear,
  createFromQuestionBank,
  setSelectedQuestions,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [isTyping, setTyping] = useState(false);
  const [filters, setFilters] = useState({
    sort_field: "updated_at",
    sort_order: "dsc",
    topic_ids: null,
    q: null,
    knowledge_group_ids: null,
    includes_all_subgroups: null,
    isArchive: isArchive,
    custom_attribute_1: null,
  });
  const [questions, setQuestions] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [bulkAssignCategoryShow, setBulkAssignCategoryShow] = useState(false);
  const [bulkCategoryIds, setBulkCategoryIds] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deletedRowId, setDeletedRowId] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [createQuizModal, setCreateQuizModal] = useState(false);
  const [schools, setSchools] = useState([]);
  const { setExportQuestions } = useGlobalContext();

  useEffect(() => {
    if (!knowledge_group_ids) {
      return;
    }
    getAllQuestionBankHandler();
    return () => {};
  }, [knowledge_group_ids, includes_all_subgroups, filters]);

  useEffect(() => {
    getSelectedNodes([...selectedNodes]);
  }, [selectedNodes]);

  // Reset selected nodes on change of year for export feature
  useEffect(() => {
    setSelectedNodes([]);
    getSelectedNodes([]);
  }, [selectedYear]);

  const createQuizModalHandler = () => {
    setCreateQuizModal(!createQuizModal);
  };

  const goEditMultipleQuestions = () => {
    history.push(EDIT_QUESTIONS_BANK.replace(":isMultiple", "true"), {
      questionList: selectedNodes,
    });
  };

  const getAllQuestionBankHandler = async () => {
    try {
      const response: any = await dispatch(
        getAllQuestionBank({
          ...filters,
          knowledge_group_ids: knowledge_group_ids,
          includes_all_subgroups: includes_all_subgroups,
        })
      );
      setQuestions(response.quiz_questions);
      if (setRecentQuestion && response.length) {
        setRecentQuestion(
          response[0].text ? response[0].text.replace(/<(.|\n)*?>/g, "") : ""
        );
      }
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  const onSortHandler = (selector: any, direction: string) => {
    setFilters({
      ...filters,
      sort_field: selector,
      sort_order: direction,
    });
  };

  const getAllQuestionsUpdateSchools = async () => {
    try {
      const response: any = await dispatch(
        getAllQuestionBankNoPaging({
          ...filters,
          knowledge_group_ids: knowledge_group_ids,
          includes_all_subgroups: includes_all_subgroups,
        })
      );
      const allSchools = response
        .filter(
          (s: any) =>
            s.custom_attribute_1 !== undefined && s.custom_attribute_1 !== null
        )
        .map((s: any) => ({
          value: s.custom_attribute_1,
          selected: false,
          label: s.custom_attribute_1,
        }));

      setSchools(_.uniqBy(allSchools, "value"));
    } catch (error) {
      console.log("[ error ]", error);
    }
  };

  useEffect(() => {
    if (!knowledge_group_ids) {
      return;
    }
    getAllQuestionsUpdateSchools();
  }, [knowledge_group_ids]);

  useEffect(() => {
    const selectedSchools = schools.filter((s: any) => s.selected);
    let schoolFilter = "";
    switch (selectedSchools.length) {
      case 1:
        schoolFilter = selectedSchools[0].value;
        break;
      case 0:
        schoolFilter = "";
        break;
      default:
        schoolFilter = selectedSchools.reduce(
          (a, b) => a.value + "," + b.value
        );
    }
    setFilters({
      ...filters,
      custom_attribute_1: schoolFilter,
    });
  }, [schools]);

  useEffect(() => {
    // Added 500 as debounce time will wait for 500 miliseconds and then we will fetch intents based on query
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        setCurrentPage(1);
        setFilters({
          ...filters,
          q: query,
        });
        setTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    setTyping(true);
  };

  const ContextAwareToggle = ({
    children,
    eventKey,
    callback,
    is_suggested,
    breadcrumbs,
  }: any) => {
    const currentEventKey = useContext(AccordionContext);
    setActiveKey(currentEventKey);
    const decoratedOnClick = useAccordionToggle(eventKey, () => {});
    return (
      <>
        <input
          type="checkbox"
          className="mr-2"
          onChange={(e) => {
            addToSelectedRows(e, eventKey);
          }}
          checked={selectedNodes.map((e) => e.rowId).indexOf(eventKey) >= 0}
        />

        <span
          className={`${is_suggested ? "is_suggested" : ""}py-2 py-lg-3`}
          onClick={decoratedOnClick}
        >
          {/* <span
            dangerouslySetInnerHTML={{
              __html: children,
            }}
          ></span> */}
          {children.replace(/<(.|\n)*?>/g, "")}
          <small className="d-block k-breadcrumb">
            {breadcrumbs.map((b: any) => b.name).join("/")}
          </small>
        </span>
      </>
    );
  };

  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] = [...selectedNodes];
    if (event.target.checked) {
      const isPresent = data.map((e) => e.rowId).indexOf(id);
      if (isPresent < 0) {
        data.push({ rowId: id, yearId: selectedYear._id });
      }
    } else {
      const oldData = [...selectedNodes];
      const index = oldData.map((e) => e.rowId).indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedNodes([..._.uniq(data)]);
    setSelectedQuestions([..._.uniq(data)]);
  };

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (questions.length > 0) {
        let data: any[] = [
          ...questions.map((intent: any) => {
            return {
              rowId: intent.id,
              yearId: selectedYear._id,
            };
          }),
        ];
        setSelectedNodes([..._.uniq(data)]);
        setSelectedQuestions([..._.uniq(data)]);
      }
    } else {
      setSelectedQuestions([]);
      setSelectedNodes([]);
    }
  };

  const BulkAssignCategoryhandler = (value: boolean) => {
    setBulkAssignCategoryShow(value);
  };

  const bulkAssignCategoryKnowledgeHandler = (id: string[]) => {
    setBulkCategoryIds(id);
    setBulkAssignCategoryShow(true);
  };

  const confirmCategoryUpdateHandler = async (selectedOption: any) => {
    try {
      const nodes: string[] = [];
      selectedNodes.forEach((d) => {
        const question: any = questions.find(
          (q) => q.id === (d.rowId || d.rowId)
        );
        if (question) {
          nodes.push(question.id);
        }
      });
      BulkAssignCategoryhandler(false);
      await dispatch(bulkAssignModules(selectedOption, nodes, "question"));
      setSelectedNodes([]);
      getAllQuestionBankHandler();
    } catch (error: any) {
      setSelectedNodes([]);
    }
  };

  const deleteConfirmed = async (
    e: any,
    selectedRowIds: string[],
    isArchive: boolean
  ) => {
    try {
      for await (const id of selectedRowIds) {
        await dispatch(deleteQuestionBankById(id, isArchive));
      }
      setSelectedNodes([]);
      setShowDeleteConfirmationModal(false);
      getAllQuestionBankHandler();
    } catch (error) {
      setShowDeleteConfirmationModal(false);
    }
  };

  const restoreQuestionsHandle = async (ids: any[]) => {
    const promises = ids.map((id) => dispatch(restoreQuestion(id.rowId)));
    Promise.all(promises)
      .then(() => {
        setSelectedNodes([]);
        getAllQuestionBankHandler();
      })
      .catch(() => setShowDeleteConfirmationModal(false));
  };

  const restoreQuestionHandle = async (id: string) => {
    try {
      await dispatch(restoreQuestion(id));
      getAllQuestionBankHandler();
    } catch (error) {
      setShowDeleteConfirmationModal(false);
    }
  };

  const innerDeleteHtmlMsg = `<span>${t(
    "Are you sure you would like to delete? Deleted items would be "
  )} <b style="color:red;">${t("deleted permanently")}</b></span>`;

  const renderSelectSchool = () => {
    const selectedSchool = schools.filter((s: any) => s.selected);

    switch (selectedSchool.length) {
      case 0:
        return t("All School");
      case 1:
        return selectedSchool[0].label;
      default:
        return t("Multiple Schools Selected");
    }
  };

  // const renderQuesAnwser = (sub: any) => {
  //   console.log({sub})
  //   return null
  // }
  const convertToMarkdown = (data: any) => {
    const content = data.getCurrentContent();
    let markup = draftjsToMd(convertToRaw(content), null);
    let splitMarkup = markup.replace("&nbsp;", " ");

    let transformedMarkup = splitMarkup.trim();
    return transformedMarkup;
  };

  const renderQuesAnwser = (sub: any) => {
    const { type } = sub;
    if (type === "text") {
      return (
        <input
          type="text"
          className="form-control h-auto radius-8"
          value={sub?.ideal_answer || ""}
          readOnly
        />
      );
    }

    if (type === "radio") {
      return sub.options.map((opt: any) => (
        <div className="custom-control custom-radio">
          <input
            type="radio"
            className="custom-control-input"
            checked={opt?.is_correct}
            disabled
          />
          <label className="custom-control-label" htmlFor="peep-radio1">
            {opt?.text}
          </label>
        </div>
      ));
    }

    if (type === "checkbox") {
      return sub.options.map((opt: any) => (
        <>
          <div className="custom-checkbox mr-3 d-flex align-items-center">
            <input
              type="checkbox"
              // disabled
              className="mr-2"
              checked={opt?.is_correct}
              onChange={onSelectAllRows}
            />
            <label className="mb-0">{opt?.text}</label>
          </div>
        </>
      ));
    }

    if (type === "description") {
      return (
        <HeaderDescriptionEditor
          hideActions={true}
          disableHeader={true}
          onTableChange={() => {}}
          tableValues={JSON.parse(sub.ideal_answer)}
        />
      );
    }

    if (type === "table") {
      const { answer_key_settings, ideal_answer_items, scored_by } = sub;
      return (
        <QuizTableEditor
          disableHeader={true}
          viewOnly={true}
          onHeaderChange={() => {}}
          onColumnHeaderChange={() => {}}
          onAnswerKeyTableChange={() => {}}
          tableValues={ideal_answer_items}
          horizontalHeaderValues={
            "headers" in sub.answer_key_settings
              ? sub.answer_key_settings
              : ["", "", ""]
          }
          verticalHeaderValues={
            "column_headers" in answer_key_settings
              ? answer_key_settings.column_headers
              : [""]
          }
          rowCount={
            "row_count" in answer_key_settings
              ? answer_key_settings.row_count
              : null
          }
          columnCount={
            "column_count" in answer_key_settings
              ? answer_key_settings.column_count
              : null
          }
          scoredBy={scored_by}
        />
      );
    }

    if (type === "newbullet" || type === "bullet") {
      return (
        <ul>
          {sub && sub.ideal_answer_items ? (
            sub.ideal_answer_items.map((i: any) => <li> {i.text}</li>)
          ) : (
            <></>
          )}
        </ul>
      );
    }

    if (type === "matching") {
      return (
        <div className="quiz-question-editor-card">
          <MatchEditor
            viewOnly={true}
            data={sub.matches}
            onChange={() => {}}
            onHeaderChange={() => {}}
            headerValues={
              "headers" in sub?.answer_key_settings
                ? sub?.answer_key_settings.headers
                : ["", ""]
            }
          />
        </div>
      );
    }

    return <></>;
  };

  const renderSubQuestions = (subQues: any, qIndex: any) => {
    return (
      <Accordion className="sub-questions" defaultActiveKey={null}>
        {subQues.map((sub: any, index: any) => (
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={sub.id}>
              <div>{t("Question ") + `${qIndex + 1}.${index + 1}`}</div>
              <div>{sub.text.replace(/<[^>]*>/g, "")}</div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={sub.id}>
              <Card.Body>
                <Col className="mt-3 col-12">
                  <h3 className="font-sm">{t("Answer:")}</h3>
                </Col>
                <Col className="mt-3 col-12">
                  <div>{renderQuesAnwser(sub)}</div>
                </Col>
                <Col className="mt-3 col-12">
                  <h3 className="font-sm">{t("Keywords")}</h3>
                  <div className="key-tags d-flex flex-wrap align-items-center">
                    {sub.tags &&
                      sub?.tags.map((tag: string) => {
                        return (
                          <div className="bg-none" key={tag}>
                            <span>{tag}</span>
                          </div>
                        );
                      })}
                  </div>
                </Col>
                <Col className="mt-3 col-12">
                  <h3 className="font-sm">{t("Matched to Document")}</h3>
                  {sub.document_sections &&
                    sub.document_sections.map((dS: any) => {
                      return (
                        <>
                          <Row className="mb-2">
                            <Col sm={6} lg={7} key={"name_" + dS.document_id}>
                              <div className="match-doc">
                                {dS?.document_name}
                              </div>
                            </Col>
                            <Col
                              sm={6}
                              lg={5}
                              className="mt-2 mt-sm-0 pl-sm-0"
                              key={"name_" + dS._id}
                            >
                              <div className="match-doc">{dS?.name}</div>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                </Col>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    );
  };

  return (
    <>
      <MoveSelectedModal
        show={bulkAssignCategoryShow}
        onHide={() => BulkAssignCategoryhandler(false)}
        confirmCategoryUpdate={confirmCategoryUpdateHandler}
      />

      <CreateQuizModalTeachersToolkit
        show={createQuizModal}
        selectedGroupId={knowledge_group_ids}
        onHide={createQuizModalHandler}
        selectedQuestionsFromBank={selectedNodes}
        confirmHandler={() => {
          // getAllQuizzesFun(filters);
          createQuizModalHandler();
        }}
      />

      <TwoButtonModal
        show={isShowDeleteModal}
        title={t("Delete Quiz")}
        message={t("Would you like to archive or delete the quiz?")}
        size={"lg"}
        leftButtonText={t("Delete")}
        leftButtonClassName="btn btn-outline-danger font-weight-normal mx-2"
        rightButtonText={t("Archive")}
        rightButtonClassName="btn btn-primary font-weight-normal mx-2"
        onClickLeftButton={() => {
          setIsShowDeleteModal(false);
          setShowDeleteConfirmationModal(true);
        }}
        onClickRightButton={(e) => {
          setIsShowDeleteModal(false);
          deleteConfirmed(e, deletedRowId, true);
        }}
        onClickClose={() => {
          setDeletedRowId([]);
          setIsShowDeleteModal(false);
        }}
      />

      <TwoButtonModal
        show={showDeleteConfirmationModal}
        title={t("Confirm Delete")}
        message={innerDeleteHtmlMsg}
        innerHtml={true}
        size={"lg"}
        leftButtonText={t("Delete")}
        leftButtonClassName="btn btn-outline-danger font-weight-normal mx-2"
        rightButtonText={t("Cancel")}
        rightButtonClassName="btn btn-primary font-weight-normal mx-2"
        onClickLeftButton={(e) => {
          setShowDeleteConfirmationModal(false);
          deleteConfirmed(e, deletedRowId, false);
        }}
        onClickRightButton={(e) => {
          setShowDeleteConfirmationModal(false);
        }}
        onClickClose={() => {
          setDeletedRowId([]);
          setShowDeleteConfirmationModal(false);
        }}
      />

      <div className="all-knowledge-search d-flex flex-wrap align-items-center mb-2">
        {!createFromQuestionBank && (
          <Dropdown className="mr-2 sort-dropdown">
            <Dropdown.Toggle
              className="btn btn-outline-primary font-600 py-1 my-1 "
              variant="default"
              id="dropdown-basic"
            >
              <img src={sortIcon} alt="" className="mr-2" />
              {t("Sort")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  onSortHandler("updated_at", "dsc");
                }}
              >
                {t("Most Recent")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onSortHandler("name", "asc");
                }}
              >
                {t("Ascending")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onSortHandler("name", "dsc");
                }}
              >
                {t("Descending")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        <div className="web-links my-0 mr-0">
          <input
            type="text"
            className="form-control h-auto"
            placeholder={t("Search for quiz question")}
            onChange={onInputChangeHandler}
          />
        </div>
        {isMgs && (
          <Dropdown className="ml-3 school-dropdown">
            <Dropdown.Toggle
              className="btn btn-outline-primary font-600 py-1 my-1"
              variant="default"
              id="agent-dropdown"
            >
              {renderSelectSchool()}
              <img src={arrowIcon} alt="" className="ml-2" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {schools.map((s: any, index: number) => (
                <div
                  className="school-dropdown-item custom-checkbox mr-3 dropdown-item d-flex align-items-center"
                  key={s.value}
                >
                  <input
                    type="checkbox"
                    checked={s.selected}
                    onChange={(e) => {
                      e.stopPropagation();
                      setSchools((prev) => {
                        const newS = [...prev];
                        newS[index].selected = e.target.checked;
                        return newS;
                      });
                    }}
                    className="mr-2"
                  />
                  <label className="mb-0">{s.value}</label>
                </div>
              ))}
              <div className="d-flex align-items-center justify-content-center">
                <button
                  className="btn-outline-primary btn font-400 border-0"
                  onClick={() => {
                    setSchools((prev) => {
                      const newS = [...prev];
                      newS.forEach((s: any) => (s.selected = false));
                      return newS;
                    });
                  }}
                >
                  {t("Unselect All")}
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {!createFromQuestionBank && (
          <CreateQuestionsOptions
            knowledge_group_ids={knowledge_group_ids}
            questionBank
          />
        )}
      </div>

      <div className="d-flex flex-wrap justify-content-between align-items-center mt-1">
        <div className="d-flex align-items-center">
          {questions.length !== 0 && (
            <div className="custom-checkbox mr-3">
              <input
                type="checkbox"
                className="mr-2"
                id="select-all"
                checked={
                  selectedNodes.length === questions.length &&
                  questions.length !== 0
                }
                onChange={onSelectAllRows}
              />
              <label htmlFor="select-all" className="mb-0">
                {t("Select all")}
              </label>
            </div>
          )}

          {selectedNodes &&
            selectedNodes.length > 0 &&
            !createFromQuestionBank && (
              <PermissionWrapper>
                {!isArchive && !isMgs && (
                  <button
                    className="btn btn-suggest py-0 font-sm"
                    onClick={createQuizModalHandler}
                  >
                    {t("Create Quiz")}
                  </button>
                )}
                {!isArchive && (
                  <button
                    className="btn btn-suggest py-0 font-sm"
                    onClick={goEditMultipleQuestions}
                  >
                    {t("Edit Selected")}
                  </button>
                )}

                {/* restoreQuestionsHandle */}
                {isArchive ? (
                  <button
                    className="btn btn-suggest py-0 font-sm"
                    onClick={(e) => restoreQuestionsHandle(selectedNodes)}
                  >
                    {t("Restore")}
                  </button>
                ) : (
                  <button
                    className="btn btn-suggest py-0 font-sm"
                    onClick={(e) =>
                      bulkAssignCategoryKnowledgeHandler(selectedRows)
                    }
                  >
                    {t("Move Selected")}
                  </button>
                )}

                {!isArchive && (
                  <button
                    className="btn btn-suggest py-0 font-sm"
                    onClick={() => {
                      setExportQuestions({
                        rows:
                          selectedNodes && selectedNodes.length > 0
                            ? [...selectedNodes]
                            : null,
                        selectedYear: selectedYear._id,
                      })
                      history.push(
                        EXPORT_QUESTION_BANK
                      );
                    }}
                  >
                    {t("Export Selected")}
                  </button>
                )}

                {!isArchive && (
                  <button
                    className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                    onClick={() => {
                      setDeletedRowId(selectedNodes.map((e) => e.rowId));
                      if (isArchive) {
                        setShowDeleteConfirmationModal(true);
                      } else {
                        setIsShowDeleteModal(true);
                      }
                    }}
                  >
                    {t("Delete Selected")}
                  </button>
                )}
              </PermissionWrapper>
            )}
        </div>
      </div>

      <Accordion
        defaultActiveKey={questions.length > 0 ? questions[0]._id : null}
        className="accordion-viewby mt-3 scroll-wrap"
      >
        {questions.map((question: any, index: number) => {
          const subQues = question.sub_questions;
          return (
            <Card
              // className={cx({
              //   active: activeKey === "0",
              //   notactive: activeKey !== "0",
              // })}
              key={question?._id}
            >
              <Card.Header>
                <ContextAwareToggle
                  is_suggested={question.is_suggested}
                  eventKey={question?.id}
                  breadcrumbs={
                    question.breadcrumbs && question.breadcrumbs.length > 0
                      ? question.breadcrumbs
                      : []
                  }
                >
                  {question.text ? question.text : ""}
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={question?.id}>
                <Card.Body>
                  {!subQues && (
                    <Row>
                      <Col>
                        <h3 className="font-sm">{t("Answer")}:</h3>
                        <input
                          type="text"
                          placeholder={t(
                            "This text would be the answer to the question"
                          )}
                          className="form-control h-auto radius-8"
                          value={question?.ideal_answer || ""}
                          readOnly
                        />
                      </Col>
                      <Col className="mt-3 col-12">
                        <h3 className="font-sm">{t("Keywords")}</h3>
                        <div className="key-tags d-flex flex-wrap align-items-center">
                          {question.tags &&
                            question?.tags.map((tag: string) => {
                              return (
                                <div className="bg-none" key={tag}>
                                  <span>{tag}</span>
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                      <Col className="mt-3 col-12">
                        <h3 className="font-sm">{t("Matched to Document")}</h3>
                        {question.document_sections &&
                          question.document_sections.map((dS: any) => {
                            return (
                              <>
                                <Row className="mb-2">
                                  <Col
                                    sm={6}
                                    lg={7}
                                    key={"name_" + dS.document_id}
                                  >
                                    <div className="match-doc">
                                      {dS?.document_name}
                                    </div>
                                  </Col>
                                  <Col
                                    sm={6}
                                    lg={5}
                                    className="mt-2 mt-sm-0 pl-sm-0"
                                    key={"name_" + dS._id}
                                  >
                                    <div className="match-doc">{dS?.name}</div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                      </Col>
                    </Row>
                  )}

                  {subQues &&
                    subQues.length > 0 &&
                    renderSubQuestions(subQues, index)}

                  <Row>
                    <Col sm={6} lg={3} className="mt-3">
                      <h3 className="font-sm">{t("Created by")}</h3>
                      <div className="font-sm">{question?.author?.email}</div>
                    </Col>
                    <Col sm={6} lg={3} className="mt-3">
                      <h3 className="font-sm">{t("Difficulty Level")}</h3>
                      <div className="dificulty-level d-flex">
                        {[1, 1, 1, 1, 1].map((d: any, index: number) => {
                          return (
                            <span
                              key={"DL_" + index}
                              className={classNames({
                                levels: true,
                                active: index + 1 <= question.difficulty_level,
                              })}
                            ></span>
                          );
                        })}
                      </div>
                    </Col>
                    <Col sm={6} lg={3} className="mt-3">
                      <h3 className="font-sm">
                        {t("Last Updated")} (DD/MM/YYYY)
                      </h3>
                      <div className="font-sm">
                        <Moment utc local format="DD/MM/YYYY hh:mm A">
                          {question.updated_at}
                        </Moment>
                      </div>
                    </Col>
                    {isMgs && (
                      <Col sm={6} lg={3} className="mt-3">
                        <h3 className="font-sm">{t("School")}</h3>
                        <div className="font-sm">
                          {question?.custom_attribute_1}
                        </div>
                      </Col>
                    )}
                  </Row>

                  {/* <div className="mt-3">
                    <h3 className="h6 clr-black mb-1">{t("Keywords")}</h3>
                    <TagsComponent row={question} />
                  </div> */}

                  {!createFromQuestionBank && (
                    <div className="mt-5 text-right">
                      {isArchive ? (
                        <button
                          className="btn btn-outline-primary font-weight-normal mx-2 py-2"
                          onClick={() => {
                            if (isArchive) {
                              restoreQuestionHandle(question?.id);
                            }
                          }}
                        >
                          {t("Restore")}
                        </button>
                      ) : (
                        <button
                          className="btn btn-outline-primary font-weight-normal mx-2 py-2"
                          onClick={() => {
                            history.push(
                              EDIT_QUESTION_BANK.replace(
                                ":questionId",
                                question.id
                              )
                            );
                          }}
                        >
                          {t("Edit")}
                        </button>
                      )}

                      <button
                        className="btn btn-outline-danger font-weight-normal mx-2 py-2"
                        onClick={() => {
                          setDeletedRowId([question.id]);
                          setIsShowDeleteModal(true);
                        }}
                      >
                        {t("Delete")}
                      </button>
                    </div>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>

      {questions && questions.length === 0 && (
        <div className="empty-knowledge text-center my-5">
          <h3 className="h6 clr-grey-txt7 mb-3">{t("No questions")}</h3>
        </div>
      )}
    </>
  );
}

export default QuizQuestionBank;
