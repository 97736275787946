import React, { useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Loading from "../../components/UI/Loading/Loading";
import QuizzesGridView from "../Quizzes/QuizzesGridView";
import arrowLeft from "../../assets/images/arrow-left.svg";

import { useTranslation } from "react-i18next";

interface Props {}

export const QuizzesBeta = (props: any) => {
  const { t } = useTranslation("translation");
  const isMgs = process.env.REACT_APP_CISCO === "mgs";
  const history = useHistory();
  const windoeObj: any = window;
  const [iframeLoading, setiframeLoading] = useState(false);
  useEffect(() => {
    setiframeLoading(true);
  }, []);

  const iframeLoadedHandler = () => {
    setiframeLoading(false);
  };

  const { isArchive } = props;

  return (
    <>
      {/* {iframeLoading && <Loading />} */}

      <section className="page-mid-wraper h-without-foter">
        <div className="row">
          {isArchive && (
            <div className="col-md-12">
              <button
                className="btn p-0 btn-back"
                onClick={() => {
                  history.goBack();
                }}
              >
                <Image src={arrowLeft} />
                {t("Return to previous page")}
              </button>
            </div>
          )}
          <div className="col-md-12">
            <div className="mb-12 d-flex justify-content-between align-items-center">
              <div>
                <h1 className="mb-2">
                  {isArchive
                    ? t("Archived Items")
                    : isMgs
                    ? t("Assessments")
                    : t("Quizzes")}
                </h1>
                <p className="mb-0 font-16 font-600">
                  {!isArchive &&
                    (isMgs
                      ? t("Create or manage your assessments here")
                      : t("Create or manage your quizzes here"))}
                </p>
              </div>
            </div>
            <QuizzesGridView isArchive={isArchive} />
          </div>
        </div>
      </section>
    </>
  );
};

export default QuizzesBeta;
