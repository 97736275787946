import { subDays } from "date-fns";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import DateRangePickerComponent from "../../../../../components/UI/DateRangePicker/DateRangePicker";
import Pagination from "../../../../../components/UI/Pagination/Pagination";
import QuestionListSkeleton from "../../../../../components/UI/Skeletons/QuestionListSkeleton/QuestionListSkeletons";
import { ChartTableSortIcon } from "../../../../../routes/Knowledge/AllKnowledge";
import { HelperService } from "../../../../../services/helper.service";
import {
  exportLearnerAttendance,
  getLearnerAttendance,
} from "../../../../../store/knowledge/actions";
import {
  customStyles,
  transformAttendanceData,
} from "./learningUtils";

import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";

import dotImg from "../../../../../assets/images/dot-vertical.svg";
import sortIcon from "../../../../../assets/New-images/sort-blue-icon.svg";

export const LearnerAttendance = (props: any) => {
  const { t } = useTranslation("translation");
  const helperService = new HelperService();
  const history = useHistory();
  const dispatch = useDispatch();
  const [matchedRecords, setMatchedRecords] = useState([]);
  const [paginationState, setPaginationState] = useState(null);
  const [paginatedrecords, setPaginatedComments] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const tableWrapperRef = useRef(null);
  const [filters, setFilters] = useState({
    filteredStudentId: "",
    filteredName: "",
    filteredTutorGroup: "",
    topicQuery: "",
    from_date: "",
    to_date: "",
    sort_field: "",
    sort_order: "",
  });
  const [datePickerClicked, setDatePickerClicked] = useState(false);
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false);
  const [dateParameters, setDateParameters] = useState(null);

  useEffect(() => {
    if (loading || !dateParameters || !dateParameters.value) {
      return;
    }

    if (
      dateParameters &&
      dateParameters.value &&
      dateParameters.value === "custom"
    ) {
      return setCustomDatePickerValue(true);
    }

    setTimeout(async () => {
      const filter: any = {
        ...filters,
        from_date:
          dateParameters && dateParameters.value
            ? moment(subDays(new Date(), +dateParameters.value)).format(
                "YYYY-MM-DD"
              )
            : null,
        to_date:
          dateParameters && dateParameters.value
            ? moment(new Date()).format("YYYY-MM-DD")
            : null,
      };

      setCustomDatePickerValue(false);
      await setFilters(filter);
    }, 100);
  }, [dateParameters]);

  useEffect(() => {
    if (
      loading ||
      !props.filtersData.filter_from_date ||
      !props.filtersData.filter_to_date
    ) {
      return;
    }

    const fn = async () => {
      // console.log('[ filtersData ]', props.filtersData)
      const filter = {
        ...filters,
        from_date: props.filtersData.filter_from_date,
        to_date: props.filtersData.filter_to_date,
      };

      if (props.filtersData.dateParameters) {
        await setDateParameters(props.filtersData.dateParameters);
      }

      await setFilters(filter);
    };
    fn();
    setDatePickerClicked(false);
    return () => {};
  }, [props.filtersData]);

  useEffect(() => {
    getMatchedFunctions();
  }, [filters]);

  const getMatchedFunctions = async () => {
    try {
      setLoading(true);
      const studentTopics: any = await dispatch(getLearnerAttendance(filters));
      await paginate(studentTopics);
      await setMatchedRecords(studentTopics);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const dateChangehandler = async (e: any) => {
    if (!e.filter_from_date || !e.filter_to_date) {
      return;
    }

    await setFilters({
      ...filters,
      from_date: e.filter_from_date,
      to_date: e.filter_to_date,
    });
  };

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false);
  };

  useEffect(() => {
    paginate(matchedRecords);
    return () => {};
  }, [currentPage, perPage]);

  const paginate = (matchedRecords: any[]) => {
    const paginationData = helperService.paginator(
      matchedRecords,
      currentPage,
      perPage
    );
    setPaginationState({
      ...paginationState,
      page: currentPage,
      page_size: perPage,
      total_items: matchedRecords.length,
      total_pages: paginationData.total_pages,
    });
    setPaginatedComments([...paginationData.data]);
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page);
  };

  const onFilterInputChangeHandler = (value: any, type: any) => {
    setFilters({
      ...filters,
      [type]: value,
    });
  };

  const exportReport = async (name: string, type: string) => {
    try {
      const blob: any = await dispatch(exportLearnerAttendance(filters, type));

      if (!blob) {
        return;
      }

      let extension =
        blob &&
        blob.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? "xlsx"
          : "csv";
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "students-report." + extension);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {}
  };

  const onSortHandlerNew = async (selector: string, direction: string) => {
    await setFilters({
      ...filters,
      sort_field: selector,
      sort_order: direction === "desc" ? "dsc" : "asc",
    });
  };

  const attendanceColumns = [
    {
      name: t('Name'),
      selector: 'name',
      grow: 2,
    },
    {
      name: t('Learner ID'),
      selector: 'studentId',
      grow: 2,
    },
    {
      name: t('Tutorial Group'),
      selector: 'tutorGroup',
    },
    {
      name: t('Last Session'),
      selector: 'lastSession',
      grow: 2,
    },
    {
      name: t('Session Count'),
      selector: 'sessionCount',
    },
    {
      name: t('Pre-test attemps'),
      selector: 'preTestAttempts',
    },
    {
      name: t('Post-test attempts'),
      selector: 'postTestAttempts',
      grow: 3,
    },
    {
      name: t('Last Login Date'),
      selector: 'lastLoginDate',
    }
  ]

  return (
    <React.Fragment>
      <div className="graph-box px-0 py-3 position-relative">
        <div className="filters d-flex flex-wrap justify-content-between align-items-center border-0">
          <h3 className="mb-0">{t("Learner Attendance")}</h3>
          <div className="d-flex flex-wrap sugested-next-ques"></div>
          {/* {!loading && ( */}

          <div className="d-flex flex-wrap sugested-next-ques">
            <Dropdown className="sort-dropdown">
              <Dropdown.Toggle
                className="btn btn-outline-primary font-600 py-1 my-1 mr-3"
                variant="success"
                id="dropdown-basic"
              >
                <img src={sortIcon} alt="" className="mr-2" />
                {t("Sort")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    onSortHandlerNew("last_login_at", "desc");
                  }}
                >
                  {t("Most Recent")}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    onSortHandlerNew("last_login_at", "asc"); //
                  }}
                >
                  {t("Ascending")}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    onSortHandlerNew("last_login_at", "desc"); //
                  }}
                >
                  {t("Descending")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Select
              className="select-sort select-target"
              classNamePrefix="select-target-prefix"
              components={{
                IndicatorSeparator: () => null,
              }}
              value={dateParameters ? { ...dateParameters } : null}
              onChange={(e: any) => {
                setDatePickerClicked(e.value === "custom");
                setDateParameters(e);
              }}
              placeholder={t('Select...')}
              options={[
                { value: "60", label: t("Last 60 days") },
                { value: "30", label: t("Last 30 days") },
                { value: "7", label: t("Last 7 days") },
                { value: "custom", label: t("Custom") },
              ]}
            />
            <Dropdown className="export-report p-0">
              <Dropdown.Toggle id="dropdown-basic">
                <img src={dotImg} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {/* <Dropdown.Item onClick={() => exportReport("", "csv")}>
                  {t("Export as csv")}
                </Dropdown.Item> */}
                <Dropdown.Item onClick={() => exportReport("", "xlsx")}>
                  {t("Export as xlsx")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* // )} */}
        </div>
        {/* {loading && (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border spinner-border-lg text-primary'></div>
          </div>
        )} */}
        {/* {!loading && ( */}
        <React.Fragment>
          {customDatePickerValue && datePickerClicked && (
            <DateRangePickerComponent
              dateValue={{
                startDate:
                  filters && filters.from_date
                    ? new Date(filters.from_date)
                    : new Date(),
                endDate:
                  filters && filters.to_date
                    ? new Date(filters.to_date)
                    : new Date(),
              }}
              onDateChange={dateChangehandler}
              onCloseDateRangeHandler={closeDateRangeHandler}
            />
          )}
          <div className="px-4" ref={tableWrapperRef}>
            <DataTable
              className="table-wraper"
              noContextMenu
              noHeader
              fixedHeader
              fixedHeaderScrollHeight="700px"
              columns={attendanceColumns}
              data={
                paginatedrecords
                  ? transformAttendanceData({
                      paginatedrecords,
                      onFilterInputChange: onFilterInputChangeHandler,
                      filters,
                    }, t)
                  : []
              }
              customStyles={customStyles}
              sortIcon={<ChartTableSortIcon />}
              noDataComponent={t("There are no records to display")}
              // paginationPerPage={perPage}
              // paginationDefaultPage={1}
              // paginationResetDefaultPage={false}
              // paginationTotalRows={totalRows}
              // onChangeRowsPerPage={handlePerRowsChange}
              // onChangePage={handlePageChange}
              // progressPending={loading} //
              // progressComponent={
              //   <div style={{ padding: 10, width: '100%' }}>
              //     <QuestionListSkeleton count={12} />
              //   </div>
              // }
              defaultSortAsc={true}
            />

            {/* -----------------  Pagination   --------------------------- */}
            {matchedRecords && matchedRecords.length > 0 && paginationState && (
              <Pagination
                currentPage={currentPage}
                pagination={{
                  ...paginationState,
                }}
                paginationPerPage={perPage}
                pageChange={(newPerPage: number, page: number) => {
                  setPerPage(newPerPage);
                  setCurrentPage(1);
                }}
                onChangePage={handlePageChange}
                hideSuggestedQuestion
              />
            )}
          </div>
        </React.Fragment>
        {/* // )} */}
      </div>
    </React.Fragment>
  );
};

export default LearnerAttendance;
