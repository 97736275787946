import { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import backimg from "../../../../assets/images/arrow-left.svg";
import exportDataImg from "../../../../assets/New-images/export-data.svg";
import TotalAvgImg from "../../../../assets/New-images/total-avg.svg";
import TotalSubImg from "../../../../assets/New-images/total-sub.svg";
import TotalTimeImg from "../../../../assets/New-images/total-time.svg";
import chevronDown from "../../../../assets/images/chevron-down-blk.svg";
import chevronUp from "../../../../assets/images/chevron-up-blk.svg";
import avgScoreImg from "../../../../assets/New-images/Avg-score.svg";
import chevLeft from "../../../../assets/New-images/chevron-left-blk.svg";
import highScoreImg from "../../../../assets/New-images/highest-score.svg";
import medianScoreImg from "../../../../assets/New-images/median-score.svg";
import TotalLearnerImg from "../../../../assets/New-images/total-learner.svg";

import { DashboardFilters } from "../../../../components/UI/Dashboard";
import { HelperService } from "../../../../services/helper.service";
import {
  getQuestionAnalyticsReport,
  getQuestionAnalyticsSubmissionReport,
  getQuizByQuizId,
  getQuizDetailFirstSubmissionSummary,
  getQuizTotalNUmberOfSubmissionsCount,
} from "../../../../store/dashboard/actions";
import QuizQuestonBreakdownResultTable from "./QuizQuestonBreakdownResultTable";
import BarChart from "../../../../components/UI/BarChart/BarChart";

const QuizQuestionBreakdown = (props: any) => {
  const { t } = useTranslation("translation");
  const helperService = new HelperService();
  const dispatch = useDispatch();
  const history = useHistory();
  // const [loading, setLoading] = useState(false);
  const [breakdownLoading, setBreakdownLoading] = useState(false);
  const [quizName, setQuizName] = useState(null);
  const [quizQuestionsBreakDownData, setQuizQuestionsBreakDownData] = useState(
    []
  );
  const [quizTotalScore, setQuizTotalScore] = useState(0);
  const [filtersData, setFiltersData] = useState({
    // type: 'daily',
    connection_ids: null,
    filter_from_date: null,
    filter_to_date: null,
    channel: null,
    dateParameters: null,
  });
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [studentsCurrentPage, setStudentsCurrentPage] = useState(1);
  const [decimalPlaces, setDecimalPlaces] = useState(2);
  const [totalNumberOfSubmissions, setTotalNumberOfSubmissions] = useState(1);
  const [averageTotalScore, setAverageTotalScore] = useState(0);
  const [totalNumberOfSubmissionsLoading, setTotalNumberOfSubmissionsLoading] =
    useState(false);
  const [
    quizDetailFirstSubmissionSummaryLoading,
    setQuizDetailFirstSubmissionSummaryLoading,
  ] = useState(false);
  const [averageFirstAttemptScore, setAverageFirstAttemptScore] = useState(0);

  useEffect(() => {
    getQuestionAnalyticsReportFunc();
  }, [ filtersData ]);

  const randomRgba = () => {
    const lum = -0.25;
    let hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    let rgb = '#',
        c, i;
    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ('00' + c).substr(c.length);
    }
    return rgb;
  }

  const getQuestionAnalyticsReportFunc = async () => {
    try {
      setBreakdownLoading(true);
      const questionAnalyticsReport: any = await dispatch(
        getQuestionAnalyticsReport(props.match.params.quizId, {
          ...filtersData,
          from_date: filtersData.filter_from_date,
          to_date: filtersData.filter_to_date
        })
      );
      setQuizQuestionsBreakDownData(
        questionAnalyticsReport.map((res: any) => {
          return {
            ...res,
            learner_choices: res.learner_choices.map((learnerChoice: any) => {
              return {
                ...learnerChoice,
                result_bucket: learnerChoice.result_bucket
              }
            }),
            chartData: res.learner_choices.map((learnerChoice: any) => {
              return {
                color: randomRgba(),
                type: 'QUIZANALYTICS',
                id: res.result_buckets.find((bucket: any) => bucket.id === learnerChoice.result_bucket)?.label,
                value: learnerChoice.learner_count
              }
            }),
            result_buckets: res.result_buckets.map((d: any) => {
              return {
                ...d,
                value: d.id,
              };
            }),
          };
        })
      );
      setBreakdownLoading(false);
    } catch (error) {
      setBreakdownLoading(false);
      setQuizQuestionsBreakDownData([]);
    }
  };

  useEffect(() => {
    // if (loading) {
    //   return;
    // }

    const fun = async () => {
      try {
        const query = new URLSearchParams(props.location.search);
        const type = query.get("type");
        const { quizDetail }: any = await dispatch(
          getQuizByQuizId(props.match.params.quizId)
        );
        setQuizName(quizDetail?.name);
        if (quizDetail && quizDetail.questions.length) {
          setQuizTotalScore(
            quizDetail.questions.reduce((score: any, question: any) => {
              return score + question.score;
            }, 0)
          );
        }

        if (
          "score_decimal_number" in quizDetail &&
          quizDetail.score_decimal_number !== null
        ) {
          setDecimalPlaces(quizDetail.score_decimal_number.toString());
        } else {
          setDecimalPlaces(2);
        }

        
      } catch (e: any) {
        // await setLoading(false);
      }
    };
    // setLoading(true);
    fun();
  }, [props.match.params.quizId]);

  // Total number of submissions
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const fromdate = query.get("fromdate");
    const todate = query.get("todate");

    if (
      totalNumberOfSubmissionsLoading ||
      !(
        (filtersData.filter_from_date && filtersData.filter_to_date) ||
        (fromdate && todate)
      )
    ) {
      return;
    }

    const func = async () => {
      try {
        const totalNumberOfSubmissions: any = await dispatch(
          getQuizTotalNUmberOfSubmissionsCount(props.match.params.quizId, {
            ...filtersData,
            filter_from_date: filtersData.filter_from_date
              ? filtersData.filter_from_date
              : fromdate
              ? fromdate
              : filtersData.filter_from_date,
            filter_to_date: filtersData.filter_to_date
              ? filtersData.filter_to_date
              : todate
              ? todate
              : filtersData.filter_to_date,
            filter_is_attempted: "no",
            page: studentsCurrentPage,
            page_size: studentsPerPage,
          })
        );
        setTotalNumberOfSubmissions(
          totalNumberOfSubmissions.length
            ? totalNumberOfSubmissions[0].total_number_of_submissions
            : 0
        );
        setAverageTotalScore(
          totalNumberOfSubmissions.length
            ? totalNumberOfSubmissions[0].avg_total_score
            : 0
        );
        setTotalNumberOfSubmissionsLoading(false);
      } catch (error) {
        setTotalNumberOfSubmissionsLoading(false);
      }
    };
    func();
    return () => {};
  }, [filtersData, props.match.params.quizId]);

  // getQuizDetailFirstSubmissionSummary
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const fromdate = query.get("fromdate");
    const todate = query.get("todate");

    if (
      quizDetailFirstSubmissionSummaryLoading ||
      !(
        (filtersData.filter_from_date && filtersData.filter_to_date) ||
        (fromdate && todate)
      )
    ) {
      return;
    }

    const func = async () => {
      try {
        const totalNumberOfSubmissions: any = await dispatch(
          getQuizDetailFirstSubmissionSummary(props.match.params.quizId, {
            ...filtersData,
            filter_from_date: filtersData.filter_from_date
              ? filtersData.filter_from_date
              : fromdate
              ? fromdate
              : filtersData.filter_from_date,
            filter_to_date: filtersData.filter_to_date
              ? filtersData.filter_to_date
              : todate
              ? todate
              : filtersData.filter_to_date,
            filter_is_attempted: "no",
            page: studentsCurrentPage,
            page_size: studentsPerPage,
          })
        );
        setAverageFirstAttemptScore(
          totalNumberOfSubmissions.length
            ? totalNumberOfSubmissions[0].avg_1st_attempt_score
            : 0
        );
        setTotalNumberOfSubmissionsLoading(false);
      } catch (error) {
        setTotalNumberOfSubmissionsLoading(false);
      }
    };
    func();
    return () => {};
  }, [filtersData, props.match.params.quizId]);

  const hasFilterUpdated = async (e: any) => {
    await setFiltersData({
      ...filtersData,
      ...e,
    });
  };

  const getAnalyticsSubmissions = async (data: any) => {
    const oldAnalytics: any = [...quizQuestionsBreakDownData];
    const analytics: any = oldAnalytics[data.index];

    if (
      analytics.hasOwnProperty("submissions") &&
      analytics.submissions.length
    ) {
      oldAnalytics[data.index].toggle = !oldAnalytics[data.index].toggle;
      setQuizQuestionsBreakDownData([...oldAnalytics]);
      return;
    }

    try {
      oldAnalytics[data.index].loading = true;
      oldAnalytics[data.index].toggle = true;
      const response: any = await dispatch(
        getQuestionAnalyticsSubmissionReport(props.match.params.quizId, data)
      );
      oldAnalytics[data.index].submissions = response;
      oldAnalytics[data.index].loading = false;
      setQuizQuestionsBreakDownData([...oldAnalytics]);
    } catch (error) {
      oldAnalytics[data.index].toggle = false;
    }
  };


  return (
    <section className="page-mid-wraper h-without-foter">
      <div>
        <div className="row">
          <div className="col-md-12">
            <button
              className="btn p-0 btn-back"
              onClick={() => history.goBack()}
            >
              <Image src={backimg} /> {t("Return to previous page")}
            </button>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-start mb-4">
          <div>
            <h2 className="mb-1">{t("Results Breakdown")}</h2>
            <div className="clr-grey font-600">
              {t("Edit and manage Knowledge generated from Document")}
            </div>
          </div>
        </div>

        <div className="flex-wrap justify-content-between align-items-center blue-filters">
          <DashboardFilters
            hasFilterUpdated={hasFilterUpdated}
            search={props.location.search}
          />
        </div>

        <Row>
          <Col lg={4} md={6} className="mb-4">
            <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
              <img src={TotalSubImg} alt="" />
              <div className="pl-3">
                <h2 className="clr-grey-txt7 h6 mb-1">
                  {t("Total Submissions")}
                </h2>
                <h2 className="mb-0 clr-pink">{totalNumberOfSubmissions} </h2>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} className="mb-4">
            <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
              <img src={TotalAvgImg} alt="" />
              <div className="pl-3">
                <h2 className="clr-grey-txt7 h6 mb-1">
                  {t("Avg Total Score")}
                </h2>
                <h2 className="mb-0 clr-pink">
                  {averageTotalScore.toString().split(".").length
                    ? averageTotalScore.toFixed(2)
                    : averageTotalScore}
                  /{quizTotalScore}
                </h2>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} className="mb-4">
            <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
              <img src={TotalTimeImg} alt="" />
              <div className="pl-3">
                <h2 className="clr-grey-txt7 h6 mb-1">
                  {t("Avg 1st Attempt Score")}
                </h2>
                <h2 className="mb-0 clr-pink">
                  {averageFirstAttemptScore.toString().split(".").length
                    ? averageFirstAttemptScore.toFixed(2)
                    : averageFirstAttemptScore}
                  /{quizTotalScore}
                </h2>
              </div>
            </div>
          </Col>

          {/* <Col lg={3} md={6} className="mb-4">
            <div className="knowledge-box p-3 build-knowbox d-flex align-items-center text-left">
              <img src={exportDataImg} alt="" />
              <div className="pl-3">
                <h2 className="clr-grey-txt7 mb-0">{t("Export Data")}</h2>
              </div>
            </div>
          </Col> */}
        </Row>

        {/* view results breakdown */}
        {!breakdownLoading &&
          quizQuestionsBreakDownData.map((quesData: any, index: number) => {
            return (
              <QuizQuestonBreakdownResultTable
                key={quesData.id}
                index={index}
                {...quesData}
                decimalPlaces={decimalPlaces}
              />
            );
          })}
        {/* view results breakdown end*/}
      </div>
    </section>
  );
};

export default QuizQuestionBreakdown;
