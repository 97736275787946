import { subDays } from "date-fns";
import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import BarChart from "../../../../../components/UI/BarChart/BarChart";
import DateRangePickerComponent from "../../../../../components/UI/DateRangePicker/DateRangePicker";
import {
  AxisBottomTick,
  AxisLeftTick,
} from "../../../../../components/UI/StackedBarChart/chart-functions";
import { getQuizSubmissions } from "../../../../../store/dashboard/actions";
import ExportReportShared from "../../../../../components/UI/ExportReportShared/ExportReportShared";
import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import { downloadChart } from "../../../../../utils/appUtils";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import chevronRight from "../../../../../assets/New-images/chevron-right-blue.svg";

interface Props {}

export default function QuizSubmissions(props: any): ReactElement {
  const { t } = useTranslation("translation");
  const [quizSubmissions, setQuizSubmissions] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [chartDownloading, setChartDownloading] = useState(false);
  const [dateParameters, setDateParameters] = useState({
    value: "30",
    label: t("Last 30 days"),
  });
  const [frequencyParameters, setFrequencyParameters] = useState({
    value: "daily",
    label: t("Daily"),
  });
  const [filtersData, setFiltersData] = useState({
    filter_from_date: null,
    filter_to_date: null,
    frequency: null,
    connection_ids: null,
  });
  const [datePickerClicked, setDatePickerClicked] = useState(false);
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false);

  useEffect(() => {
    if (
      loading ||
      !props.filtersData.filter_from_date ||
      !props.filtersData.filter_to_date
    ) {
      return;
    }

    const fn = async () => {
      const filter = {
        ...filtersData,
        ...props.filtersData,
      };

      if (props.filtersData.dateParameters) {
        await setDateParameters(props.filtersData.dateParameters);
      }

      await setFiltersData(filter);
    };
    fn();
    setDatePickerClicked(false);
    return () => {};
  }, [props.filtersData]);

  useEffect(() => {
    if (
      dateParameters &&
      dateParameters.value &&
      dateParameters.value === "custom"
    ) {
      // show custom date component
      return setCustomDatePickerValue(true);
    }

    setTimeout(async () => {
      const filter: any = {
        ...filtersData,
        filter_from_date: dateParameters.value
          ? moment(subDays(new Date(), +dateParameters.value)).format(
              "YYYY-MM-DD"
            )
          : null,
        filter_to_date: dateParameters.value
          ? moment(new Date()).format("YYYY-MM-DD")
          : null,
        frequency: frequencyParameters.value,
      };

      setCustomDatePickerValue(false);
      await setFiltersData(filter);
    }, 100);
  }, [dateParameters, frequencyParameters]);

  const dateChangehandler = async (e: any) => {
    const filter = {
      ...filtersData,
      ...e,
    };
    await setFiltersData(filter);
  };

  useEffect(() => {
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return;
    }
    const fun = async () => {
      await setLoading(true);
      try {
        const quizSubmissionsData: any = await dispatch(
          getQuizSubmissions(filtersData)
        );
        setQuizSubmissions([
          ...quizSubmissionsData.data.map((d: any) => {
            return {
              ...d,
              type: "quizsubmission",
            };
          }),
        ]);
        await setLoading(false);
      } catch (error: any) {
        setQuizSubmissions([]);
        await setLoading(false);
      }
    };
    fun();
  }, [filtersData]);

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false);
  };

  const onDownloadChart = async () => {
    await setChartDownloading(true);
    htmlToImage
      .toJpeg(document.getElementById("quiz-submissions"))
      .then(async function (dataUrl) {
        await download(dataUrl, "quiz-submissions.png");
        await setChartDownloading(false);
      })
      .catch(async (e: any) => {
        await setChartDownloading(false);
        console.log("Error", e);
      });
  };

  const getWeeklyParams = () => {
    const copied = {
      ...filtersData,
    };
    delete copied.connection_ids;
    return copied;
  };

  return (
    <div
      className="graph-box px-0 py-3 position-relative"
      id="quiz-submissions"
    >
      <div className="filters d-flex flex-wrap justify-content-between align-items-center border-0">
        <h3 className="mb-0">{t("Quiz Submissions")}</h3>

        {!loading && !chartDownloading && (
          <div className="d-flex flex-wrap sugested-next-ques">
            <Select
              className="select-sort select-target"
              classNamePrefix="select-target-prefix"
              components={{
                IndicatorSeparator: () => null,
              }}
              value={frequencyParameters ? { ...frequencyParameters } : null}
              onChange={setFrequencyParameters}
              options={[
                { value: "daily", label: t("Daily") },
                { value: "weekly", label: t("Weekly") },
                // { value: 'monthly', label: 'Monthly' },
              ]}
            />
            <Select
              className="select-sort select-target"
              classNamePrefix="select-target-prefix"
              components={{
                IndicatorSeparator: () => null,
              }}
              value={dateParameters ? { ...dateParameters } : null}
              onChange={(e: any) => {
                setDatePickerClicked(e.value === "custom");
                setDateParameters(e);
              }}
              options={[
                { value: "60", label: t("Last 60 days") },
                { value: "30", label: t("Last 30 days") },
                { value: "7", label: t("Last 7 days") },
                { value: "custom", label: t("Custom") },
              ]}
            />

            <ExportReportShared
              name={
                filtersData.frequency === "weekly"
                  ? "weekly-quiz-submissions"
                  : "quiz-submissions"
              }
              filtersData={
                filtersData.frequency === "weekly"
                  ? getWeeklyParams()
                  : filtersData
              }
              removedKeyFromObject="frequency"
              downloadChart={onDownloadChart}
            />
          </div>
        )}
      </div>
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      )}
      {!loading && (
        <React.Fragment>
          {customDatePickerValue && datePickerClicked && (
            <DateRangePickerComponent
              dateValue={{
                startDate:
                  filtersData && filtersData.filter_from_date
                    ? new Date(filtersData.filter_from_date)
                    : new Date(),
                endDate:
                  filtersData && filtersData.filter_to_date
                    ? new Date(filtersData.filter_to_date)
                    : new Date(),
              }}
              onDateChange={dateChangehandler}
              onCloseDateRangeHandler={closeDateRangeHandler}
            />
          )}
          <div
            className="px-4 d-flex justify-content-center align-items-center"
            style={{ width: "100%", height: "300px" }}
          >
            {quizSubmissions && quizSubmissions.length > 0 && (
              <BarChart
                enableGridX={false}
                enableGridY={true}
                axisLeft={{
                  legend: t("No. of submissions "),
                  legendPosition: "middle",
                  legendOffset: -38,
                  tickSize: 0,
                  tickPadding: 5,
                  renderTick: AxisLeftTick,
                }}
                axisBottom={{
                  tickValues: 5,
                  legend: t("Date"),
                  legendPosition: "middle",
                  legendOffset: 38,
                  tickPadding: 35,
                  tickRotation: 45,
                  renderTick: AxisBottomTick,
                }}
                data={quizSubmissions}
                keys="submission_count"
                indexBy="date"
                enableLabel={true}
              />
            )}
            {quizSubmissions && quizSubmissions.length === 0 && (
              <div className="">{t("There are no records to display")}</div>
            )}
          </div>
          <div className="text-right px-4">
            <button
              className="btn p-0 clr-primary font-600 font-sm"
              onClick={() => {
                history.push("/report-quizzes-all-submissions");
              }}
            >
              {t("View all submissions")}
              <img src={chevronRight} alt="" className="ml-2" />
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
