import { subDays } from "date-fns";
import moment from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import Select from "react-select";
import DateRangePickerComponent from "../../../../../components/UI/DateRangePicker/DateRangePicker";
import Pagination from "../../../../../components/UI/Pagination/Pagination";
import { HelperService } from "../../../../../services/helper.service";
import { getDashboardQuizList } from "../../../../../store/dashboard/actions";
import { ChartTableSortIcon } from "../../../../Knowledge/AllKnowledge";
import { customStyles } from "./";
import { NavLink } from "react-router-dom";
import * as CONFIG from "../../../../../config/";
import ExportReportShared from "../../../../../components/UI/ExportReportShared/ExportReportShared";
import { useTranslation } from "react-i18next";

export default function QuizSummary(props: any): ReactElement {
  const { t } = useTranslation("translation");
  const helperService = new HelperService();
  const dispatch = useDispatch();
  const [paginationState, setPaginationState] = useState(null);
  const [paginatedTopUsers, setPaginatedTopUsers] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dateParameters, setDateParameters] = useState({
    value: "30",
    label: t("Last 30 days"),
  });
  const [filtersData, setFiltersData] = useState({
    filter_from_date: null,
    filter_to_date: null,
    frequency: null,
  });
  const [datePickerClicked, setDatePickerClicked] = useState(false);
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false);
  const columns = [
    {
      name: t("Quiz"),
      selector: "quiz_id",
      sortable: true,
      cell: (row: any) => (
        <div>
          <NavLink
            to={
              CONFIG.QUIZZDETAILSBYID.replace(":quizId", row.quiz_id) +
              "?name=" +
              row.quiz_name +
              "&fromdate=" +
              filtersData.filter_from_date +
              "&todate=" +
              filtersData.filter_to_date
            }
            className="btn-link genurl-btn"
          >
            {row.quiz_name}
          </NavLink>
        </div>
      ),
    },
    {
      name: t("No of Submissions"),
      selector: "submission_count",
      sortable: true,
      cell: (row: any) => <div>{row.submission_count}</div>,
    },
    {
      name: t("Last Submitted"),
      selector: "last_submission_at",
      sortable: true,
      cell: (row: any) => (
        <div>
          <Moment format={"DD-MM-yyyy"}>{row.last_submitted}</Moment>
        </div>
      ),
    },
    {
      name: t("Average Score"),
      selector: "average_score",
      sortable: true,
      cell: (row: any) => <div>{row.average_score.toFixed(2)}</div>,
    },
    {
      name: t("No of Reviews"),
      selector: "review_count",
      sortable: true,
      cell: (row: any) => <div>{row.review_count}</div>,
    },
    {
      name: t("Pending Reviews"),
      selector: "pending_count",
      sortable: true,
      cell: (row: any) => <div>{row.pending_count}</div>,
    },
  ];

  useEffect(() => {
    if (
      loading ||
      !props.filtersData.filter_from_date ||
      !props.filtersData.filter_to_date
    ) {
      return;
    }

    const fn = async () => {
      // console.log('[ filtersData ]', props.filtersData)
      const filter = {
        ...filtersData,
        ...props.filtersData,
      };

      if (props.filtersData.dateParameters) {
        await setDateParameters(props.filtersData.dateParameters);
      }

      await setFiltersData(filter);
    };
    fn();
    setDatePickerClicked(false);
    return () => {};
  }, [props.filtersData]);

  useEffect(() => {
    if (
      dateParameters &&
      dateParameters.value &&
      dateParameters.value === "custom"
    ) {
      return setCustomDatePickerValue(true);
    }

    setTimeout(async () => {
      const filter: any = {
        ...filtersData,
        filter_from_date: dateParameters.value
          ? moment(subDays(new Date(), +dateParameters.value)).format(
              "YYYY-MM-DD"
            )
          : null,
        filter_to_date: dateParameters.value
          ? moment(new Date()).format("YYYY-MM-DD")
          : null,
      };

      setCustomDatePickerValue(false);
      await setFiltersData(filter);
    }, 100);
  }, [dateParameters]);

  const dateChangehandler = async (e: any) => {
    const filter = {
      ...filtersData,
      ...e,
    };
    await setFiltersData(filter);
  };

  useEffect(() => {
    if (
      loading ||
      !filtersData.filter_from_date ||
      !filtersData.filter_to_date
    ) {
      return;
    }
    const fun = async () => {
      await setLoading(true);
      try {
        const { quizzes }: any = await dispatch(
          getDashboardQuizList(filtersData)
        );
        await setQuizzes(quizzes);
        await paginate(quizzes);
        await setLoading(false);
      } catch (e: any) {
        await paginate([]);
        await setLoading(false);
      }
    };
    fun();
  }, [filtersData]);

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false);
  };

  useEffect(() => {
    paginate(quizzes);
    return () => {};
  }, [currentPage, perPage]);

  const paginate = (quizzes: any) => {
    const paginationData = helperService.paginator(
      quizzes,
      currentPage,
      perPage
    );
    setPaginationState({
      ...paginationState,
      page: currentPage,
      page_size: perPage,
      total_items: quizzes.length,
      total_pages: paginationData.total_pages,
    });
    setPaginatedTopUsers([...paginationData.data]);
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page);
  };

  return (
    <div className="graph-box px-0 py-3 position-relative">
      <div className="filters d-flex flex-wrap justify-content-between align-items-center border-0">
        <h3 className="mb-0">{t("Quizzes")}</h3>
        <div className="d-flex flex-wrap sugested-next-ques"></div>
        {!loading && (
          <div className="d-flex flex-wrap sugested-next-ques">
            <Select
              className="select-sort select-target"
              classNamePrefix="select-target-prefix"
              components={{
                IndicatorSeparator: () => null,
              }}
              value={dateParameters ? { ...dateParameters } : null}
              onChange={(e: any) => {
                setDatePickerClicked(e.value === "custom");
                setDateParameters(e);
              }}
              options={[
                { value: "60", label: t("Last 60 days") },
                { value: "30", label: t("Last 30 days") },
                { value: "7", label: t("Last 7 days") },
                { value: "custom", label: t("Custom") },
              ]}
            />
            <ExportReportShared name={"quiz-list"} filtersData={filtersData} />
          </div>
        )}
      </div>
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border spinner-border-lg text-primary"></div>
        </div>
      )}
      {!loading && (
        <React.Fragment>
          {customDatePickerValue && datePickerClicked && (
            <DateRangePickerComponent
              dateValue={{
                startDate:
                  filtersData && filtersData.filter_from_date
                    ? new Date(filtersData.filter_from_date)
                    : new Date(),
                endDate:
                  filtersData && filtersData.filter_to_date
                    ? new Date(filtersData.filter_to_date)
                    : new Date(),
              }}
              onDateChange={dateChangehandler}
              onCloseDateRangeHandler={closeDateRangeHandler}
            />
          )}
          <div className="px-4">
            <DataTable
              className="table-wraper-trending-questions"
              noContextMenu
              noHeader
              columns={columns}
              data={paginatedTopUsers}
              customStyles={customStyles}
              sortIcon={<ChartTableSortIcon />}
            />

            {paginatedTopUsers &&
              paginatedTopUsers.length > 0 &&
              paginationState && (
                <Pagination
                  currentPage={currentPage}
                  pagination={{
                    ...paginationState,
                  }}
                  paginationPerPage={perPage}
                  pageChange={(newPerPage: number, page: number) => {
                    setPerPage(newPerPage);
                    setCurrentPage(1);
                  }}
                  onChangePage={handlePageChange}
                  hideSuggestedQuestion
                />
              )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
