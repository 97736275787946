import ApiUtil from "../utils/apiUtil";
import {
  getAgentFromStorage,
} from "../utils/appUtils";


const getIntegrations = async (): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/integrations?agent_id=${getAgentFromStorage()}`,
    method: "GET"
  });
  return res;
} 

const createIntegration = async (data: any): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/integrations`,
    method: "POST",
    data: data
  });
  return res;
}

const updateIntegration = async (id: string, data: any): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/integrations/${id}?agent_id=${getAgentFromStorage()}`,
    method: "PUT",
    data: data
  });
  return res;
} 

const getIntegrationFiles = async (provider: string, course_id: string, resourceType: string): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/${provider}-integrations/current/courses/${course_id}/${resourceType}?agent_id=${getAgentFromStorage()}`,
    method: "GET",
  });
  return res;
} 

const importIntegrationFiles = async (provider: string, fileType: string, data: any): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/${provider}-integrations/current/${fileType}?agent_id=${getAgentFromStorage()}`,
    method: "POST",
    data: data
  });
  return res.data.data;
} 

const getProviderIntegration = async (provider: string, requests_new_state: boolean): Promise<any> => {
  const res = await ApiUtil.SendAsync<any>({
    url: `integration/${provider}-integrations/current?agent_id=${getAgentFromStorage()}&requests_new_state=${requests_new_state ? "true" : "false"}`,
    method: "GET",
  });
  return res.data;
} 

const integrationService =  {
  getIntegrations,
  createIntegration,
  updateIntegration,
  getIntegrationFiles,
  importIntegrationFiles,
  getProviderIntegration
} 

export default integrationService