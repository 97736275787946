import { ReactElement, ReactNode, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useLocation } from "react-router";

interface Props {
  showSidebar: boolean;
  children: ReactNode;
}

export default function MainLayout({
  showSidebar,
  children,
}: Props): ReactElement {
  const [isSidebarShown, setSidebarShown] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setSidebarShown(false);
  }, [location.pathname]);

  return (
    <>
      <div className="tw-h-0">
        {showSidebar && (
          <Sidebar
            isShown={isSidebarShown}
            onToggle={() => setSidebarShown(false)}
          />
        )}
      </div>
      <div className="tw-w-full lg:tw-pl-[240px] tw-flex tw-flex-col tw-h-screen">
        <Header onToggleSidebar={() => setSidebarShown(!isSidebarShown)} />
        <div className="tw-flex-1 tw-overflow-y-auto">{children}</div>
      </div>
    </>
  );
}
