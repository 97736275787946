import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import learningOutcomesImg from "../../../assets/New-images/learning-outcomes-img.svg";
import summariseDocImg from "../../../assets/New-images/summarise-doc-img.svg";
import contextialiseChatImg from "../../../assets/New-images/contextualised-chat-img.svg";
import genQueAnsImg from "../../../assets/New-images/gen-que-ans-img.svg";
import mangeKeyPhrase from "../../../assets/New-images/manage-key-phrase.svg";
import manageConversationFlow from "../../../assets/New-images/manage-conversation-flow.svg";
import { useTranslation } from "react-i18next";
import SelectModalModule from "../../../routes/TeachersToolkit/popups/selectModalModule";
import { useHistory, useLocation } from "react-router";
import {
  CONVERSATION_FLOW,
  KNOWLEDGE_KEYPHRASES,
  TOOLKIT_GENERATE_LEARNING_OUTCOMES,
  TOOLKIT_GENERATE_QNA_UPLOAD_DOC,
  TOOLKIT_SETUP_CONTEXTUALISED_CHAT,
  TOOLKIT_SUMMARISE_DOCUMENTS,
} from "../../../config";

interface CarouselItem {
  component: React.ReactNode;
}

function KnowledgeCrousel(): JSX.Element {
  const [index, setIndex] = useState<number>(0);
  const [itemsPerSlide, setItemsPerSlide] = useState<number>(3);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const groupId = query.get("group");
  const [subHeader, setSubHeader] = useState("");
  const [selectedFlow, setSelectedFlow] = useState("");
  const [showSelectModule, setShowSelectModule] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);

  const { t } = useTranslation("translation");
  const [header, setHeader] = useState("");

  const confirmCategoryUpdate = async (
    selectedOption: any,
    createButtons: boolean = false,
    key: string
  ) => {
    if (selectedOption) {
      setSelectedModule(selectedOption);
      setShowSelectModule(false);
      setHeader("");
      setSubHeader("");
      setSelectedFlow("");
      switch (key) {
        case "CONTEXTUALISED_CHAT":
          history.push(
            `${TOOLKIT_SETUP_CONTEXTUALISED_CHAT}?group=${selectedOption}`
          );
          break;
        case "LEARNING_OUTCOMES":
          history.push(
            `${TOOLKIT_GENERATE_LEARNING_OUTCOMES}?group=${selectedOption}`
          );
          break;
        case "GENERATE_KNOWLEDGE":
          history.push(
            `${TOOLKIT_GENERATE_QNA_UPLOAD_DOC}?group=${selectedOption}`
          );
          break;
        case "SUMMARISE_DOCUMENTS":
          history.push(
            `${TOOLKIT_SUMMARISE_DOCUMENTS}?group=${selectedOption}`
          );
          break;

        default:
          break;
      }
    }
  };

  const showSelectModulesHandler = (
    heading: string,
    subHeading: string,
    key: string
  ) => {
    setSelectedFlow(key);
    setHeader(heading);
    setSubHeader(subHeading);
    if (!groupId) {
      setShowSelectModule(true);
    } else {
      confirmCategoryUpdate(groupId, false, key);
    }
  };

  const handleSelect = (selectedIndex: number): void => {
    if (selectedIndex < totalSlides) {
      setIndex(selectedIndex);
    } else {
      setIndex(totalSlides - 1);
    }
  };
  
  
  
  

  function SetupChat() {
    return (
      <div
        className="teachers-toolkit-box"
        onClick={() => { history.push(`/knowledge/setup/chat`) }}
      >
        <div className="inner-box-crsl">
          <img src={contextialiseChatImg} width={"20px"} height={"20px"} alt="" />
        </div>
        <div className="inner-text p-0">
          <h2 className="">{t("Set Up Contextualised Chat")}</h2>
          <span className="description">
            {t(
              "Upload your content to serve as sources for your AI assistant’s responses"
            )}
          </span>
        </div>
      </div>
    )
  }

  function GeneratQa() {
    return (
      <div
        className="teachers-toolkit-box"
        onClick={() => { history.push(`/knowledge/setup/generate-qa`) }}
       
      >
        <div className="inner-box-crsl">
          <img src={genQueAnsImg} alt="" />
        </div>
        <div className="inner-text">
          <h2 className="">{t("Generate Q&As")}</h2>
          <span className="description">
            {t(
              "Extract essential points from your content and create Q&A flashcards"
            )}
          </span>
        </div>
      </div>
    )
  }

  function LearningOutcome() {
    return (
      <div
        className="teachers-toolkit-box"
        onClick={() => { history.push(`/knowledge/setup/learning-outcomes`) }}

      >
        <div className="inner-box-crsl">
          <img src={learningOutcomesImg} alt="" />
        </div>
        <div className="inner-text">
          <h2 className="">{t("Set Learning Outcomes")}</h2>
          <span className="description">
            {t("Align generated content by setting learning outcomes")}
          </span>
        </div>
      </div>
    )
  }

  function SummariseDocument() {
    return (
      <div
        className="teachers-toolkit-box"
        onClick={() => { history.push(`/knowledge/setup/summarise-doc`) }}
        
      >
        <div className="inner-box-crsl">
          <img src={summariseDocImg} alt="" />
        </div>
        <div className="inner-text">
          <h2 className="">{t("Summarise Document(s)")}</h2>
          <span className="description">
            {t(
              "Create contents by summarising or merging existing documents"
            )}
          </span>
        </div>
      </div>
    )
  }

  function MangeKeyPhrase() {
    return (
      <div
        className="teachers-toolkit-box"
        onClick={() => {

          history.push(KNOWLEDGE_KEYPHRASES);
        }}
      >
        <div className="inner-box-crsl">
          <img src={mangeKeyPhrase} alt="" />
        </div>
        <div className="inner-text">
          <h2 className="">{t("Manage Key Phrases")}</h2>
          <span className="description">
            {t(
              "Define and link key phrases to improve your contextual responses"
            )}
          </span>
        </div>
      </div>
    )
  }

  function ManageConversationFlow() {
    return (
      <div
        className="teachers-toolkit-box"
        onClick={() => {

          history.push(CONVERSATION_FLOW);
        }}
      >
        <div className="inner-box-crsl">
          <img src={manageConversationFlow} alt="" />
        </div>
        <div className="inner-text">
          <h2 className="">{t("Manage Conversation Flow")}</h2>
          <span className="description">
            {t(
              "Set up and connect content for a tailored chat experience"
            )}
          </span>
        </div>
      </div>
    )
  }

  const items: CarouselItem[] = [
    { component: <SetupChat /> },
    { component: <GeneratQa /> },
    { component: <LearningOutcome /> },
    { component: <SummariseDocument /> },
    { component: <MangeKeyPhrase /> },
    { component: <ManageConversationFlow /> },
  ];

  const totalSlides = Math.ceil(items.length / itemsPerSlide);


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 576) {
        setItemsPerSlide(1);
      } else if (window.innerWidth < 992) {
        setItemsPerSlide(2);
      } else {
        setItemsPerSlide(3);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getItemsForSlide = (slideIndex: number) => {
    if (slideIndex === totalSlides - 1) {
      // For the last slide, always show the last 'itemsPerSlide' items
      return items.slice(-itemsPerSlide);
    } else {
      // For other slides, use the normal slice
      return items.slice(slideIndex * itemsPerSlide, (slideIndex + 1) * itemsPerSlide);
    }
  };

  return (
    <>
    <div className='knowledge-carousel-container'>
      <h2 className="mb-0 font-1 mt-16">
        Quick Access
      </h2>

      <SelectModalModule
        show={showSelectModule}
        onHide={() => {
          setShowSelectModule(false);
          setHeader("");
          setSubHeader("");
          setSelectedFlow("");
        }}
        confirmCategoryUpdate={confirmCategoryUpdate}
        header={header}
        subHeader={subHeader}
        isBuildKnowledge
        selectedFlow={selectedFlow}
      />
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null} wrap={false}>
        {Array.from({ length: Math.ceil(items.length / itemsPerSlide) }).map((_, slideIndex) => (
          <Carousel.Item key={slideIndex}>
      <div className="multi-item-slide">
        {getItemsForSlide(slideIndex).map((item, itemIndex) => (
          <div key={itemIndex} className="carousel-item-wrapper">
            {item.component}
          </div>
        ))}
      </div>
    </Carousel.Item>
        ))}
      </Carousel>
      </div>
    </>
  );
}

export default KnowledgeCrousel;

