export const URIUtil = {
  getChatBotLinkFromPortalURI: (uri: string) => {
    return uri.includes("portalus")
      ? uri.includes("staging")
        ? "https://chatbotus.staging.noodlefactory.ai/"
        : "https://chatbotus.noodlefactory.ai/"
      : uri.includes("staging")
      ? "https://chatbot.staging.noodlefactory.ai/"
      : "https://chatbot.noodlefactory.ai/";
  },
};
