import { useHistory, useLocation } from "react-router";
import cx from "classnames";
import { Col, Container, Image, Row } from "react-bootstrap";

import backblue from "../../../assets/images/arrow-left.svg";
import failedDoc from "../../../assets/images/failed-doc.svg";

import { useSSR, useTranslation } from "react-i18next";
import UploadDocument from "./UploadDocument";

import { useEffect, useState } from "react";
import "../style.scss";
import KnowledgeDatatableComponentGridView from "../../../components/UI/KnowledgeDatatableComponent/KnowledgeDatatableComponentGridView";
import browserImg from "../../../assets/images/icon-park-outline_web-page.svg";
import addImg from "../../../assets/images/icon_add.svg";
import removeImg from "../../../assets/images/icon_remove.svg";
import { uploadFaqUrlAndDocument } from "../../../store/knowledge/actions";
import { HelperService } from "../../../services/helper.service";
import { useDispatch } from "react-redux";
import { ValidateUrl } from "../../../utils/appUtils";
import {
  BUILD_MULTI_DOCUMENTS_SECTION,
  BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION,
} from "../../../config";
import MoveSelectedModal from "../../../components/UI/KnowledgeDatatableComponent/MoveSelectedModal";
import ProgressSteps from "../../../components/UI/ProgressSteps/ProgressSteps";
import classNames from "classnames";

function WebURLField({
  onAddClick,
  onDelClick,
  onValChange,
  fieldVal,
  isError,
}: {
  onAddClick: any;
  onDelClick: any;
  onValChange: any;
  fieldVal: string;
  isError: boolean;
}) {
  return (
    <div
      className={cx(`flex page-mid-wraper-build-v2`, { "error-bg": isError })}
    >
      <div>
        {" "}
        <img src={browserImg} alt="url field" />
      </div>
      <div>
        <div>Website Address</div>
        <input
          value={fieldVal}
          type="text"
          placeholder="https://"
          className="weburl-field form-control input-v2"
          onChange={onValChange}
        ></input>
      </div>
      <div>
        <div className="webfield-btn webfield-add">
          <img src={addImg} alt="add" onClick={onAddClick} />
        </div>
        <div className="webfield-btn webfield-del">
          <img src={removeImg} alt="remove" onClick={onDelClick} />
        </div>
      </div>
    </div>
  );
}
const BuildKnowledge = (props: any) => {
  const history = useHistory();
  const useLocatio = useLocation();
  const { t } = useTranslation("translation");
  const [disp, setDisp] = useState("uploader"); // update the current display (on the right) being shown
  const [isFailedUrl, setIsFailedUrl] = useState(false);
  const [val, setVal] = useState([""]);
  const [urlErrors, setUrlErrors] = useState([]);
  const [hideAdd, setHideAdd] = useState("");
  const [hideDel, setHideDel] = useState("");
  const helper = new HelperService();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocatio.search);
  const type = query.get("type");
  const [ids, setIds] = useState([]);
  const [newDocAdded, setNewDocAdded] = useState(false);

  let urls: { url: any; valid: boolean }[] = [];
  let urlIds: string[] = [];

  const handleAdd = () => {
    if (val.length < 3) {
      const abc = [...val, ""];
      setVal(abc);

      if (abc.length == 3) setHideAdd("hideadd");

      setHideDel("");
    }
  };
  const handleChange = (onChangeValue: any, i: number) => {
    const inputdata = [...val];
    inputdata[i] = onChangeValue.target.value;
    setVal(inputdata);
  };
  const handleDelete = (i: number) => {
    if (val.length > 1) {
      const deletVal = [...val];
      deletVal.splice(i, 1);
      setVal(deletVal);
      if (deletVal.length == 1) setHideDel("hidedel");

      setHideAdd("");
    }
  };

  function handleClick(value: string) {
    if (value === "existing") {
      //trigger the document selection
      // cast as HTMLElement so that we can trigger click event
      let doc_btn: HTMLElement = document.querySelector(
        'nav > .nav-item[data-rb-event-key="viewDocument"]'
      ) as HTMLElement;
      if (doc_btn) doc_btn.click();
    }

    setDisp(value);
  }

  useEffect(() => {
    if (urlErrors.find((failedUrl) => val.includes(failedUrl))) {
      setIsFailedUrl(true);
    } else {
      setIsFailedUrl(false);
    }
  }, [urlErrors, val]);

  const processUrl = async () => {
    const query = new URLSearchParams(useLocatio.search);
    const group = query.get("group");

    if (urls.length > 0) {
      let uploadedDocument: any;
      try {
        uploadedDocument = await dispatch(
          uploadFaqUrlAndDocument(
            {
              file: null,
              file_name: urls[0].url,
              faqType: "other",
              group,
              flow: props.isContexualised ? "v1" : "v2",
            },
            (event: any) => {},
            true
          )
        );
      } catch (error) {
        setUrlErrors((errUrls) => [...errUrls, urls[0].url]);
      }

      if (uploadedDocument && uploadedDocument.status === 200) {
        console.log("UPLOADED " + uploadedDocument.data.data.document._id);

        urlIds.push(uploadedDocument.data.data.document._id);
        setIds([...urlIds]);

        if (urls.length > 1) urls.shift();
        else urls = [];
        processUrl();
      }
    } else {
      setNewDocAdded(true);
    }
  };

  function callProcessUrl() {
    let hasInvalid = false;
    setUrlErrors([]);
    let allFields = document.querySelectorAll(".weburl-field");
    for (var i = 0; i < allFields.length; i++) {
      let url = (allFields[i] as HTMLInputElement).value.trim();
      let validate = ValidateUrl(url);
      if (!validate) hasInvalid = true;
      urls.push({ url: url, valid: validate });
    }

    if (hasInvalid) {
      helper.showToaster(
        t(
          "You have entered invalid URLs. Please check that the addresses begin with http."
        )
      );
      return;
    } else {
      console.log(urls);
      processUrl();
    }
  }

  const confirmCategoryUpdate = async (
    selectedOption: any,
    createButtons: boolean = false
  ) => {
    await setNewDocAdded(false);
    history.push(
      `${BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION.replace(":step", "3").replace(
        ":subStep",
        "2"
      )}?group=${
        selectedOption ? selectedOption : props.group
      }&documents=${ids.join(",")}&type=${type}`
    );
  };

  const [groupIds, setGroupIds] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(useLocatio.search);
    const group = query.get("group");
    setGroupIds(group);

    handleClick("existing");
  }, []);

  return (
    <>
      <MoveSelectedModal
        show={newDocAdded}
        onHide={() => setNewDocAdded(false)}
        confirmCategoryUpdate={confirmCategoryUpdate}
        isBuildKnowledge
        groupId={groupIds}
      />
      <section className="build-knowledge-v2 page-mid-wraper h-without-foter">
        <Container>
          <div className="row">
            <div className="col-sm-12">
              <button
                className="btn p-0 btn-back"
                onClick={() => {
                  history.goBack();
                }}
              >
                <Image src={backblue} /> {t("Return to previous page")}
              </button>
              <div className="text-center mb-32">
                <div className="d-flex flex-wrap justify-content-center">
                  <h1 className="clr-primary first-step mb-2 font-weight-bold">
                    {type === "automated"
                      ? t("Automated Setup")
                      : t("Guided Setup")}
                  </h1>
                </div>
                <h2 className="font-600">
                  {/* {t(
                    "Get started by uploading the document(s) you wish to include in your Knowledge Base"
                  )} */}
                  {type === "automated"
                    ? t(
                        "Get started by uploading the document(s) you wish to include in your Knowledge Base"
                      )
                    : t(
                        "Get started by uploading the document(s) you wish to include in your Knowledge Base"
                      )}
                </h2>
              </div>
            </div>
          </div>

          <div className="row mb-4">
            <nav className="nav nav-tabs" role="tablist" data-dispmode={disp}>
              <span
                role="tab"
                className={classNames({
                  "nav-item nav-link": true,
                  active: "existing" === disp,
                })}
                onClick={() => handleClick("existing")}
              >
                {t("Select from Knowledge Base")}
              </span>
              <span
                role="tab"
                className={classNames({
                  "nav-item nav-link": true,
                  active: "uploader" === disp,
                })}
                onClick={() => handleClick("uploader")}
              >
                {t("Upload Documents")}
              </span>
              <span
                role="tab"
                className={classNames({
                  "nav-item nav-link": true,
                  active: "url" === disp,
                })}
                onClick={() => handleClick("url")}
              >
                {t("Website Address(URL)")}
              </span>
            </nav>
          </div>
          <div className="content-container" data-dispmode={disp}>
            <div id="uploader">
              <UploadDocument type={type} />
            </div>
            <div id="url">
              <div className="section-title">
                <p className="clr-grey-txt7 font-16 mb-3">
                  {t(
                    "Please enter the website URL(s) from which you'd like to extract information. You can input up to a maximum of 3 webpages in total."
                  )}
                </p>
                {/* <input type="text" className="form-control input-v2" placeholder="https://" value=""></input> */}
              </div>
              {isFailedUrl && (
                <div className="mb-2 py-2 error-bg font-16 flex align-items-center justify-content-start">
                  <img className="px-3 " src={failedDoc} alt="" />
                  {t(
                    "The following website URL(s) submitted can't be processed. Please use a different link"
                  )}
                </div>
              )}
              <div className={`webfield-parent ${hideAdd} ${hideDel}`}>
                {val.map((data, i) => {
                  return (
                    <WebURLField
                      isError={urlErrors.includes(data)}
                      data-value={i}
                      onAddClick={() => {
                        handleAdd();
                      }}
                      onDelClick={() => {
                        handleDelete(i);
                      }}
                      onValChange={(e: any) => handleChange(e, i)}
                      fieldVal={data}
                    />
                  );
                })}
              </div>

              <div>
                <div className="flex">
                  <button
                    className="btn btn-primary ml-auto mb-0 py-1 mb-0"
                    onClick={callProcessUrl}
                  >
                    {t("Extract from Website(s)")}
                  </button>
                </div>
                <ProgressSteps currentStep={1} steps={4} />
              </div>
            </div>
            <div id="existing">
              <KnowledgeDatatableComponentGridView
                buildKnowledge={true}
                buildV2={true}
                showAllDocuments={true}
                type={type}
              />
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default BuildKnowledge;
