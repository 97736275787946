// Export action types for auth flow
export const LOGIN_PROCESS_STARTED = "LOGIN_PROCESS_STARTED";
export const LOGIN_PROCESS_COMPLETED = "LOGIN_PROCESS_COMPLETED";
export const LOGIN_PROCESS_FAIL = "LOGIN_PROCESS_FAIL";
export const LOGIN_PROCESS_FINISHED = "LOGIN_PROCESS_FINISHED";

// Export action types for bot and greet flow
export const SET_OBJECTIVE = "SET_OBJECTIVE";
export const INIT_BOT_DATA = "INIT_BOT_DATA";
export const FETCH_BOT_DATA_SUCCESS = "FETCH_BOT_DATA_SUCCESS";
export const FETCH_BOT_DATA_FAIL = "FETCH_BOT_DATA_FAIL";
export const INIT_UPDATE_BOT_DATA = "INIT_UPDATE_BOT_DATA";
export const UPDATE_BOT_DATA_SUCCESS = "UPDATE_BOT_DATA_SUCCESS";
export const UPDATE_BOT_DATA_FAIL = "UPDATE_BOT_DATA_FAIL";
export const INIT_USER_GREET = "INIT_USER_GREET";
export const FETCH_USER_GREET_SUCCESS = "FETCH_USER_GREET_SUCCESS";
export const FETCH_USER_GREET_FAIL = "FETCH_USER_GREET_FAIL";
export const INIT_UPDATE_USER_GREET_DATA = "INIT_UPDATE_USER_GREET_DATA";
export const UPDATE_USER_GREET_DATA_SUCCESS = "UPDATE_USER_GREET_DATA_SUCCESS";
export const UPDATE_USER_GREET_DATA_FAIL = "UPDATE_USER_GREET_DATA_FAIL";
export const RESET_DOCUMENTS = "RESET_DOCUMENTS";
export const UPDATE_ALL_DOCUMENTS = "UPDATE_ALL_DOCUMENTS";
export const STORE_DOCUMENTS = "STORE_DOCUMENTS";
export const STORE_DOCUMENT_IDS = "STORE_DOCUMENT_IDS";
export const UPDATE_DOCUMENT_IDS = "UPDATE_DOCUMENT_IDS";
export const INIT_STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS = "INIT_STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS";
export const STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS = "STORE_KEYWORDS_FOR_DOCUMENT_SUCCESS";
export const STORE_KEYWORDS_FOR_DOCUMENT_FAIL = "STORE_KEYWORDS_FOR_DOCUMENT_FAIL";
export const LOCAL_DOCUMENT_UPDATE = "LOCAL_DOCUMENT_UPDATE";
export const GENERATE_ANSWERS_RELOADED = "GENERATE_ANSWERS_RELOADED";
export const INIT_GENERATE_ANSWERS_SCREEN = "INIT_GENERATE_ANSWERS_SCREEN";
export const INIT_STORE_QUESTIONS_FOR_KEYWORDS = "INIT_STORE_QUESTIONS_FOR_KEYWORDS";
export const STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS = "STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS";
export const STORE_QUESTIONS_FOR_KEYWORDS_UPDATE = "STORE_QUESTIONS_FOR_KEYWORDS_UPDATE";
export const STORE_QUESTIONS_FOR_KEYWORDS_FAIL = "STORE_QUESTIONS_FOR_KEYWORDS_FAIL";
export const INIT_STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS = "INIT_STORE_QUESTIONS_FOR_KEYWORDS_SUCCESS";
export const LOCAL_ANSWWER_UPDATE = "LOCAL_ANSWWER_UPDATE";
export const INIT_SAVE_RESPONSE_TO_KNOWLEDGE = "INIT_SAVE_RESPONSE_TO_KNOWLEDGE";
export const SAVE_RESPONSE_TO_KNOWLEDGE_SUCCESS = "SAVE_RESPONSE_TO_KNOWLEDGE_SUCCESS";
export const SAVE_RESPONSE_TO_KNOWLEDGE_FAIL = "SAVE_RESPONSE_TO_KNOWLEDGE_FAIL";

// GENERATED URL
export const LOCAL_UPDATE_URL_DATA = "LOCAL_UPDATE_URL_DATA";
export const INIT_GENERATE_WEBLINKS_FROM_URL = "INIT_GENERATE_WEBLINKS_FROM_URL";
export const LOCAL_UPDATE_WEBLINKS_URL_DATA = "LOCAL_UPDATE_WEBLINKS_URL_DATA";
export const PAGINATE_WEB_LINKS = "PAGINATE_WEB_LINKS";

// QUESTIONS 
export const INIT_STORE_WEBSITE_TO_SERVER = "INIT_STORE_WEBSITE_TO_SERVER";
export const STORE_WEBSITE_TO_SERVER_SUCCESS = "STORE_WEBSITE_TO_SERVER_SUCCESS";
export const STORE_WEBSITE_TO_SERVER_FAIL = "STORE_WEBSITE_TO_SERVER_FAIL";

// all knowledge
export const INIT_ALL_KNOWLEDGE = "INIT_ALL_KNOWLEDGE";
export const FETCH_ALL_KNOWLEDGE_SUCCESS = "FETCH_ALL_KNOWLEDGE_SUCCESS";
export const FETCH_ALL_KNOWLEDGE_ERROR = "FETCH_ALL_KNOWLEDGE_ERROR";

// Topics
export const INIT_TOPICS = "INIT_TOPICS";
export const FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS";
export const FETCH_TOPICS_ERROR = "FETCH_TOPICS_ERROR";
// Update knowledge
export const INIT_KNOWLEDGE_BY_ID = "INIT_KNOWLEDGE_BY_ID";
export const UPDATE_KNOWLEDGE_BY_ID_SUCCESS = "UPDATE_KNOWLEDGE_BY_ID_SUCCESS";
export const UPDATE_KNOWLEDGE_BY_ID_ERROR = "UPDATE_KNOWLEDGE_BY_ID_ERROR";
// Update knowledge
export const CHUNK_KNOWLEDGE_STARTED = "CHUNK_KNOWLEDGE_STARTED";
export const DELETE_KNOWLEDGE_BY_ID_SUCCESS = "DELETE_KNOWLEDGE_BY_ID_SUCCESS";
export const DELETE_KNOWLEDGE_BY_ID_ERROR = "DELETE_KNOWLEDGE_BY_ID_ERROR";
export const CHUNK_KNOWLEDGE_FINISHED = "CHUNK_KNOWLEDGE_FINISHED";

export const GET_STEP_THREE_DATA_SUCCESS = "GET_STEP_THREE_DATA_SUCCESS";
export const GET_STEP_TWO_DATA_SUCCESS = "GET_STEP_TWO_DATA_SUCCESS";

// Local greeting update
export const LOCAL_GREETING_BUTTONS_UPDATE = 'LOCAL_GREETING_BUTTONS_UPDATE';

// Update / create FAQ RESPONSES
export const INIT_STORE_FAQ_RESPONSES_FOR_DOCUMENT = 'INIT_STORE_FAQ_RESPONSES_FOR_DOCUMENT';
export const STORE_FAQ_RESPONSES_FOR_DOCUMENT_SUCCESS = 'STORE_FAQ_RESPONSES_FOR_DOCUMENT_SUCCESS';

export const UPDATE_DOCUMENT_AFTER_INTERVAL = 'UPDATE_DOCUMENT_AFTER_INTERVAL';

// all suggestions
export const INIT_ALL_SUGGESTIONS = "INIT_ALL_SUGGESTIONS";
export const FETCH_ALL_SUGGESTIONS_SUCCESS = "FETCH_ALL_SUGGESTIONS_SUCCESS";
export const FETCH_ALL_SUGGESTIONS_ERROR = "FETCH_ALL_SUGGESTIONS_ERROR";
export const UPDATE_SUGGESTION_BY_ID = "UPDATE_SUGGESTION_BY_ID";