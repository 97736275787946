import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import downarrowblk from '../../../assets/images/chevron-down-blk.svg'
import { transformNestedTopics } from '../../../utils/appUtils'

import { useTranslation } from "react-i18next";

interface Props {
  handleChangeReactSelect: (e: any, data: {}) => void
  options: any[]
  selectedValueAsObject?: {
    value?: string
    label?: string
  }
  selectedValueAsArray?: string[]
}

export const SelectTree: React.FC<any> = ({
  handleChangeReactSelect,
  options,
  selectedValueAsObject,
  selectedValueAsArray,
  className,
  ...props
}) => {
  const { t } = useTranslation('translation');
  const [oldOptions, setoldOptions] = useState([])
  const [searchedOptions, setSearchedOptions] = useState([])
  useEffect(() => {
    setoldOptions(options)
    setSearchedOptions(transformNestedTopics(options))
  }, [ options ]);

  // padding division is used to create a placeholder for select options
  const paddingDivision = (data: any, padding: any = 0) => (
    <div
      key={'child_padding_dev_' + data.value + '_' + Math.floor(Math.random() * 9999)}
      className={cx('child-para', {
        selected:
          selectedValueAsArray && selectedValueAsArray.indexOf(data.value) >= 0
            ? true
            : selectedValueAsObject && selectedValueAsObject.value === data.value
            ? true
            : false,
      })}
      style={{
        paddingLeft: `${padding}px`
      }}
      onClick={(e) => handleChangeReactSelect(e, data)}
    >
      <p key={'child_para_p_' + data.value + '_' + Math.floor(Math.random() * 9999)} className='dropdown-para'>{data.label}</p>
    </div>
  )

  // custom option runs when component mounted do all the necessary functionality in this function
  const CustomOption = ({ data }: any) => {
    return (
      <React.Fragment key={'fragment__' + data.value}>
        {paddingDivision(data, `${
          data.depth > 0 ? ((data.depth * 10) + 10) : 10
        }`)}
      </React.Fragment>
    )
  }
  return (
    <Select
      defaultMenuIsOpen={false}
      {...props}
      placeholder={t("Select category")}
      className={className}
      options={searchedOptions}
      value={selectedValueAsObject && selectedValueAsObject.label && selectedValueAsObject.value ? {
        ...selectedValueAsObject,
        name: selectedValueAsObject?.label,
        _id: selectedValueAsObject?.value
      }: null}
      closeMenuOnSelect
      isSearchable
      getOptionLabel={(option: any) => option['label']}
      getOptionValue={(option: any) => option['value']}
      components={{
        Option: CustomOption,
        DropdownIndicator: (props: any) => (
          <img src={downarrowblk} alt={t('dropdown arrow')} />
        ),
        IndicatorSeparator: () => null,
      }}
      menuPlacement="auto"
    />
  )
}

export default SelectTree
