/* eslint-disable jsx-a11y/anchor-is-valid */
import { subDays } from 'date-fns'
import moment from 'moment'
import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import DateRangePickerComponent from '../../DateRangePicker/DateRangePicker'

import { useTranslation } from "react-i18next";

interface Props {
  hasFilterUpdated?: (e: any) => void
  filtersData?: {
    filter_from_date?: string
    filter_to_date?: string
  }
}

function DashboardFiltersWithDateOnly(props: Props): ReactElement {
  const { t } = useTranslation('translation');
  const dispatch = useDispatch()
  const [dateParameters, setDateParameters] = useState({
    value: '30',
    label: t('Last 30 days'),
  })
  const [loading, setLoading] = useState(true)
  const [filtersData, setFiltersData] = useState({
    // type: 'daily',
    filter_from_date: null,
    filter_to_date: null,
    dateParameters: null,
  })
  const [customDateParameters, setCustomDateParameters] = useState({
    filter_from_date: null,
    filter_to_date: null,
  })
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false)
  const [clickedCustomDatePickerValue, setClickedCustomDatePickerValue] =
    useState(false)

  useEffect(() => {
    setTimeout(async () => {
      const filter: any = {
        filter_from_date:
          dateParameters && dateParameters.value !== 'custom'
            ? moment(subDays(new Date(), +dateParameters.value)).format(
                'YYYY-MM-DD'
              )
            : null,
        filter_to_date:
          dateParameters && dateParameters.value !== 'custom'
            ? moment(new Date()).format('YYYY-MM-DD')
            : null,
        dateParameters: dateParameters
          ? dateParameters
          : null,
      }

      if (dateParameters && dateParameters.value === 'custom') {
        setCustomDatePickerValue(dateParameters.value === 'custom')
        await setFiltersData({
          ...filtersData,
          ...filter,
        })
        props.hasFilterUpdated({ ...filtersData, ...filter })
        return
      }

      setCustomDatePickerValue(
        dateParameters && dateParameters.value === 'custom'
      )
      await setFiltersData({
        ...filtersData,
        ...filter,
      })
      props.hasFilterUpdated({ ...filtersData, ...filter })
    }, 100)
  }, [dateParameters, loading])

  const onDateChangeHandler = async (e: any) => {
    setClickedCustomDatePickerValue(true)
    setDateParameters(e)
  }

  const dateChangehandler = async (e: any) => {
    const filter: any = {
      filter_from_date: e ? e.filter_from_date : null,
      filter_to_date: e ? e.filter_to_date : null,
    }
    await setCustomDateParameters(e)
    await setFiltersData({
      ...filtersData,
      ...filter,
    })
    props.hasFilterUpdated({ ...filtersData, ...filter })
  }

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false)
  }

  return (
    <div className='filters d-flex flex-wrap justify-content-between align-items-center mb-3 position-relative'>
      <h3 className='mb-0'>{t("Filters")}</h3>
      <div className='d-flex flex-wrap sugested-next-ques'>
        <Select
          className='select-sort select-target'
          classNamePrefix='select-target-prefix'
          isSearchable={false}
          components={{
            IndicatorSeparator: () => null,
          }}
          value={dateParameters ? { ...dateParameters } : null}
          onChange={(e: any) => {
            setClickedCustomDatePickerValue(false)
            onDateChangeHandler(e)
          }}
          options={[
            { value: '60', label: t('Last 60 days') },
            { value: '30', label: t('Last 30 days') },
            { value: '7', label: t('Last 7 days') },
            { value: 'custom', label: t('Custom') },
          ]}
        />
        {customDatePickerValue && clickedCustomDatePickerValue && (
          <DateRangePickerComponent
            dateValue={{
              startDate:
                filtersData && filtersData.filter_from_date
                  ? new Date(filtersData.filter_from_date)
                  : new Date(),
              endDate:
                filtersData && filtersData.filter_to_date
                  ? new Date(filtersData.filter_to_date)
                  : new Date(),
            }}
            // search={props.search}
            onDateChange={dateChangehandler}
            onCloseDateRangeHandler={closeDateRangeHandler}
            hasDatePickerValueChanges={customDatePickerValue}
          />
        )}
      </div>
    </div>
  )
}

export default DashboardFiltersWithDateOnly
