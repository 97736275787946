import { subDays } from 'date-fns'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import DateRangePickerComponent from '../../../../../components/UI/DateRangePicker/DateRangePicker'
import Loading from '../../../../../components/UI/Loading/Loading'
import Pagination from '../../../../../components/UI/Pagination/Pagination'
import { ChartTableSortIcon } from '../../../../../routes/Knowledge/AllKnowledge'
import { HelperService } from '../../../../../services/helper.service'
import {
  getConversationsRecords,
  studentTopicsReport,
} from '../../../../../store/knowledge/actions'
import { getAllTopicsSelector } from '../../../../../store/knowledge/selector'
import { transformNestedTopics } from '../../../../../utils/appUtils'
import ConversationsModal from './ConversationsModal'
import {
  getAiMarkerColumns,
  getAiTutorColumns,
  customStyles,
  transformTableData,
} from './learningUtils'
import * as _ from 'lodash'

import { useTranslation } from "react-i18next";

export const LearnerProgress = (props: any) => {
  const { t } = useTranslation('translation');
  const history = useHistory()
  const helperService = new HelperService()
  const allTopics = useSelector(getAllTopicsSelector)
  const dispatch = useDispatch()
  const [matchedRecords, setMatchedRecords] = useState([])
  const [paginationState, setPaginationState] = useState(null)
  const [paginatedrecords, setPaginatedComments] = useState([])
  const [selectedNodes, setSelectedNodes] = useState([])
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const tableWrapperRef = useRef(null)
  const [filters, setFilters] = useState({
    filteredStudentId: '',
    filteredName: '',
    filteredTutorGroup: '',
    topicQuery: '',
    from_date: '',
    to_date: '',
  })
  const [datePickerClicked, setDatePickerClicked] = useState(false)
  const [customDatePickerValue, setCustomDatePickerValue] = useState(false)
  const [dateParameters, setDateParameters] = useState(null)
  const [showConversationsModal, setShowConversationsModal] = useState(false)
  const [showConversationsLoading, setShowConversationsLoading] =
    useState(false)
  const [showConversationsData, setShowConversationsData] = useState([])
  const [topicsMap, setTopicsMap] = useState({})
  const [subtopicsMap, setSubtopicsMap] = useState({})
  const [topicDropdownData, setTopicDropdownData] = useState([])
  useEffect(() => {
    if (loading || !dateParameters || !dateParameters.value) {
      return
    }

    if (
      dateParameters &&
      dateParameters.value &&
      dateParameters.value === 'custom'
    ) {
      return setCustomDatePickerValue(true)
    }

    setTimeout(async () => {
      const filter: any = {
        ...filters,
        from_date:
          dateParameters && dateParameters.value
            ? moment(subDays(new Date(), +dateParameters.value)).format(
                'YYYY-MM-DD'
              )
            : null,
        to_date:
          dateParameters && dateParameters.value
            ? moment(new Date()).format('YYYY-MM-DD')
            : null,
      }

      setCustomDatePickerValue(false)
      await setFilters(filter)
    }, 100)
  }, [dateParameters])

  useEffect(() => {
    if (
      loading ||
      !props.filtersData.filter_from_date ||
      !props.filtersData.filter_to_date
    ) {
      return
    }

    const fn = async () => {
      // console.log('[ filtersData ]', props.filtersData)
      const filter = {
        ...filters,
        from_date: props.filtersData.filter_from_date,
        to_date: props.filtersData.filter_to_date,
      }

      if (props.filtersData.dateParameters) {
        await setDateParameters(props.filtersData.dateParameters)
      }

      await setFilters(filter)
    }
    fn()
    setDatePickerClicked(false)
    return () => {}
  }, [props.filtersData])

  useEffect(() => {
    if (loading) {
      return
    }
    getMatchedFunctions()
  }, [filters])

  useEffect(() => {
    let tempTopicMap: any = {}
    let tempSubtopicMap: any = {}
    props.allTopics.forEach((topic: any) => {
      tempTopicMap[topic._id] = topic.name
      topic.children.forEach((subtopic: any) => {
        tempSubtopicMap[subtopic._id] = subtopic.name
      })
    })
    setTopicsMap(tempTopicMap)
    setSubtopicsMap(tempSubtopicMap)
    setTopicDropdownData(transformNestedTopics(props.allTopics))
  }, [props.allTopics])

  const setShowConversationsModalHandler = async (props: any) => {
    setShowConversationsModal(!showConversationsModal)
    if (props && props.user_id) {
      setShowConversationsLoading(true)
      const conversationrecords: any = await dispatch(
        getConversationsRecords(props.user_id)
      )
      setShowConversationsData(conversationrecords)
      setShowConversationsLoading(false)
    }
  }

  const getMatchedFunctions = async () => {
    try {
      setLoading(true)
      const studentTopics: any = await dispatch(studentTopicsReport(filters))
      await paginate(studentTopics)
      await setMatchedRecords(studentTopics)
      await setLoading(false)
    } catch (error: any) {
      setLoading(false)
    }
  }

  const dateChangehandler = async (e: any) => {
    if (!e.filter_from_date || !e.filter_to_date) {
      return
    }

    await setFilters({
      ...filters,
      from_date: e.filter_from_date,
      to_date: e.filter_to_date,
    })
  }

  const closeDateRangeHandler = () => {
    setCustomDatePickerValue(false)
  }

  useEffect(() => {
    paginate(matchedRecords)
    return () => {}
  }, [currentPage, perPage])

  const paginate = (matchedRecords: any[]) => {
    const paginationData = helperService.paginator(
      matchedRecords,
      currentPage,
      perPage
    )
    setPaginationState({
      ...paginationState,
      page: currentPage,
      page_size: perPage,
      total_items: matchedRecords.length,
      total_pages: paginationData.total_pages,
    })
    setPaginatedComments([...paginationData.data])
  }

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = async (page: number) => {
    await setCurrentPage(page)
  }

  const onFilterInputChangeHandler = (value: any, type: any) => {
    if (type === 'topicQuery') {
      const prevNodes = [...selectedNodes]
      prevNodes.push(value)
      const sortedNodes = _.uniqBy([...prevNodes], 'value')
      setSelectedNodes(sortedNodes)
      setFilters({
        ...filters,
        [type]: sortedNodes.map((d: any) => d.value).join(','),
      })
    } else {
      setFilters({
        ...filters,
        [type]: value,
      })
    }
  }

  // Remove tags
  const removeTagHandler = (e: any, value: any) => {
    e.preventDefault()
    const oldNodes = [...selectedNodes]
    const selectedNodeTopicIndex = oldNodes.findIndex(
      (selectedNodeTopic: any) => selectedNodeTopic.value === value
    )
    if (selectedNodeTopicIndex >= 0) {
      oldNodes.splice(selectedNodeTopicIndex, 1)
      setSelectedNodes([...oldNodes])
    }
    const sortedNodes = _.uniqBy([...oldNodes], 'value')
    setFilters({
      ...filters,
      topicQuery: sortedNodes.map((d: any) => d.value).join(','),
    })
  }

  if (showConversationsLoading) {
    return <Loading />
  }

  return (
    <React.Fragment>
      <ConversationsModal
        size='lg'
        show={showConversationsModal}
        onHide={setShowConversationsModalHandler}
        showConversationsData={[...showConversationsData]}
        topicsMap={topicsMap}
        subtopicsMap={subtopicsMap}
      />
      <div className='graph-box px-0 py-3 position-relative'>
        <div className='filters d-flex flex-wrap justify-content-between align-items-center border-0'>
          <h3 className='mb-0'>{t("Learner Progress")}</h3>
          <div className='d-flex flex-wrap sugested-next-ques'></div>
          {!loading && (
            <div className='d-flex flex-wrap sugested-next-ques'>
              <Select
                className='select-sort select-target'
                classNamePrefix='select-target-prefix'
                placeholder={t('Select...')}
                components={{
                  IndicatorSeparator: () => null,
                }}
                value={dateParameters ? { ...dateParameters } : null}
                onChange={(e: any) => {
                  setDatePickerClicked(e.value === 'custom')
                  setDateParameters(e)
                }}
                options={[
                  { value: '60', label: t('Last 60 days') },
                  { value: '30', label: t('Last 30 days') },
                  { value: '7', label: t('Last 7 days') },
                  { value: 'custom', label: t('Custom') },
                ]}
              />
              {/* <ExportReportShared
                name={'quiz-list'}
                filtersData={filtersData}
              /> */}
            </div>
          )}
        </div>
        {/* {loading && (
          <div className='d-flex justify-content-center'>
            <div className='spinner-border spinner-border-lg text-primary'></div>
          </div>
        )} */}
        {/* {!loading && ( */}
        <React.Fragment>
          {customDatePickerValue && datePickerClicked && (
            <DateRangePickerComponent
              dateValue={{
                startDate:
                  filters && filters.from_date
                    ? new Date(filters.from_date)
                    : new Date(),
                endDate:
                  filters && filters.to_date
                    ? new Date(filters.to_date)
                    : new Date(),
              }}
              onDateChange={dateChangehandler}
              onCloseDateRangeHandler={closeDateRangeHandler}
            />
          )}
          <div className='px-4' ref={tableWrapperRef}>
            <DataTable
              className='table-wraper table-wraper-learner-progress'
              noContextMenu
              noHeader
              fixedHeader
              fixedHeaderScrollHeight='700px'
              columns={
                props.isAiTutor
                  ? getAiTutorColumns({
                      allTopics,
                      onFilterInputChange: onFilterInputChangeHandler,
                      filters,
                      selectedNodes,
                      removeTagHandler
                    })
                  : getAiMarkerColumns({
                      allTopics,
                      onFilterInputChange: onFilterInputChangeHandler,
                      filters,
                      selectedNodes,
                      removeTagHandler
                    })
              }
              data={
                paginatedrecords
                  ? transformTableData({
                      matchedRecords: paginatedrecords,
                      allTopics,
                      onFilterInputChange: onFilterInputChangeHandler,
                      filters,
                      history,
                      setShowConversationsModalHandler,
                    }, t)
                  : []
              }
              customStyles={customStyles}
              sortIcon={<ChartTableSortIcon />}
              // paginationPerPage={perPage}
              // paginationDefaultPage={1}
              // paginationResetDefaultPage={false}
              // paginationTotalRows={totalRows}
              // onChangeRowsPerPage={handlePerRowsChange}
              // onChangePage={handlePageChange}
              // progressPending={loading} //
              // progressComponent={
              //   <div style={{ padding: 10, width: '100%' }}>
              //     <QuestionListSkeleton count={12} />
              //   </div>
              // }
              defaultSortAsc={true}
            />

            {/* -----------------  Pagination   --------------------------- */}
            {matchedRecords && matchedRecords.length > 0 && paginationState && (
              <Pagination
                currentPage={currentPage}
                pagination={{
                  ...paginationState,
                }}
                paginationPerPage={perPage}
                pageChange={(newPerPage: number, page: number) => {
                  setPerPage(newPerPage)
                  setCurrentPage(1)
                }}
                onChangePage={handlePageChange}
                hideSuggestedQuestion
              />
            )}
          </div>
        </React.Fragment>
        {/* )} */}
      </div>
    </React.Fragment>
  )
}

export default LearnerProgress
