import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import emptyKnowledge from "../../assets/New-images/empty-knowledge.svg";
import sortIcon from "../../assets/New-images/sort-blue-icon.svg";
import { TwoButtonModal as DocumentConfirmationModal } from "../../components/UI/Common";
import MoveSelectedModal from "../../components/UI/KnowledgeDatatableComponent/MoveSelectedModal";
import Loading from "../../components/UI/Loading/Loading";
import Pagination from "../../components/UI/Pagination/Pagination";
import PermissionWrapper from "../../components/UI/PermissionWrapper/PermissionWrapper";
import * as CONFIG from "../../config/index";
import { HelperService } from "../../services/helper.service";
import { getCommonState } from "../../store/common/selector";
import {
  bulkAssignModules,
  deleteDocuments,
  getAllDocumentsNewFlow,
  getDocumentPreviewUrl,
  restoreDocuments,
  updateBulkDocumentSettings,
  renameDocument,
} from "../../store/knowledge/actions";
import "../Knowledge/AllKnowledge/AllKnowledge.scss";
import DeleteConfirmModal from "./DeleteDocumentModal/DeleteConfirmModal";

import { useTranslation } from "react-i18next";
import {
  REVIEW_QUIZ_QUESTIONS,
  SMART_QUIZ_SELECT_PAGES,
  TOOLKIT_GENERATE_LEARNING_OUTCOMES_REVIEW,
  TOOLKIT_GENERATE_LEARNING_OUTCOMES_SELECT_PAGES,
  TOOLKIT_GENERATE_QNA_SELECT_PAGES,
  TOOLKIT_SUMMARISE_DOCUMENTS_SELECT_PAGES,
} from "../../config";
import TurnOnOffDocumentPreviewModal from "./TurnOnOffDocumentPreviewModal/TurnOnOffDocumentPreviewModal";
import SelectSummaryType from "../TeachersToolkit/pages/SummariseDocuments/Popup/SelectSummaryTypePopup";
import classNames from "classnames";
import editDocumentImg from "../../assets/New-images/edit_document.svg";
import DocumentRename from "./popup/DocumentRenamePopup";
import SelectModalModule from "../TeachersToolkit/popups/selectModalModule";
import axios from "axios";

export const UploadedDocuments = (props: any) => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const dispatch = useDispatch();
  const commonState = useSelector(getCommonState);
  const [loading, setLoading] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [topicIds, setTopicIds] = useState("");
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [documents, setAllDocuments] = useState([]);
  const [pagination, setPagination] = useState(null);
  const tableWrapperRef = useRef(null);
  const [scrollRight, setScrollRight] = useState(0);

  const [modalShow, setModalShow] = useState(false);
  const modalhandler = (value: boolean) => {
    setModalShow(value);
  };
  const [bulkAssignCategoryShow, setBulkAssignCategoryShow] = useState(false);
  const [bulkCategoryIds, setBulkCategoryIds] = useState([]);
  const [deletedRowId, setDeletedRowId] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [showDeletePermanentlyModal, setShowDeletePermanentlyModal] =
    useState(false);
  const { isMgs = false } = props;
  const helperService = new HelperService();

  const [showSelectSummaryType, setShowSelectSummaryType] = useState(false);
  const [showDocumentRenameModal, setShowDocumentRenameModal] = useState(false);
  const [newDocAdded, setNewDocAdded] = useState(false);
  const [query, setQuery] = useState("");
  const [isTyping, setTyping] = useState(false);

  const contextualisedChatStatus: any = {
    new: "Pending",
    preprocessing: "Pre-processing",
    created: "Syncing",
    uploading: "Syncing",
    uploaded: "Syncing",
    syncing: "Syncing",
    synced: "Synced",
    errored: "Failed",
    deleted: "Deleted",
  };

  const deleteDocumentHandler = (
    id: string[],
    isMultipleDelete: boolean = false
  ) => {
    const msg = isMultipleDelete
      ? t(
          "You are deleting multiple documents. Are you sure you want to delete?"
        )
      : t("Are you sure you want to delete document ?");
    setConfirmationMessage(msg);
    setDeletedRowId(id);
    setModalShow(true);
  };

  const [documentPreviewModalShow, setDocumentPreviewModalShow] =
    useState(false);
  const [documentConfirmationMessage, setDocumentConfirmationMessage] =
    useState("");
  const documentPreviewModalhandler = (value: boolean) => {
    setDocumentPreviewModalShow(value);
  };
  const [documentRowId, setDocumentRowId] = useState([]);
  const [isDocumentTurnOnMode, setIsDocumentTurnOnMode] = useState(false);
  const [selectBKDisabled, setSelectBKDisabled] = useState(true);

  const [editDocument, setEditDocument] = useState(null);
  const [recetlyUsedDocuments, setRecentlyUsedDocuments] = useState([]);
  const [ filters , setFilters] = useState({
    sort_field: "updated_at",
    sort_order: "desc",
    q: "",
    page: 1,
    page_size: 10
  })
  

  const turnOnOffDocumentHandler = (
    id: string[],
    isMultipleDelete: boolean = false,
    turnOnOrOff: boolean = false
  ) => {
    const msg = isMultipleDelete
      ? t("This will ") +
        `${turnOnOrOff ? t("TURN ON ") : t("TURN OFF ")}` +
        t(
          "document preview in WEBCHAT, WIDGET and DIRECT-CHAT for ALL the documents. Do you want to proceed?"
        )
      : t("This will ") +
        `${turnOnOrOff ? t("TURN ON ") : t("TURN OFF ")}` +
        t(
          "document preview in WEBCHAT, WIDGET and DIRECT-CHAT for the documents. Do you want to proceed?"
        );
    setIsDocumentTurnOnMode(turnOnOrOff);
    setDocumentConfirmationMessage(msg);
    setDocumentRowId(id);
    setDocumentPreviewModalShow(true);
  };

  useEffect(() => {
    if (props.activeTab === "viewDocument") {
      fun({ ...filters, sort_field: "updated_at", sort_order: "dsc" });
      setSelectedRows([]);
    }
    // Table wrapper reference to check scroll left value of an element
    if (
      tableWrapperRef &&
      tableWrapperRef.current &&
      tableWrapperRef.current.children[0]
    ) {
      setScrollRight(tableWrapperRef.current.children[0].scrollLeft);
    }

    return () => {
      setScrollRight(0);
    };
  }, [props.selectedGroupId, props.activeTab]);

  useEffect(() => {
    if(!filters) {
      return;
    }
    fun(filters);
  }, [filters])

  useEffect(() => {
    props.sendSelectedDocsToParent &&
      props.sendSelectedDocsToParent(selectedRows);
    return () => {
      props.sendSelectedDocsToParent && props.sendSelectedDocsToParent([]);
    };
  }, [selectedRows]);


  const fun = async (filters: any = null) => {
    const filterMgs = isMgs
      ? { type: "summary,ebook,other,lesson_slides" }
      : props.buildKnowledge && !props.showAllDocuments
      ? props.flowKey === "SUMMARISE_DOCUMENTS"
        ? { type: "other" }
        : { type: "summary" }
      : {};
    const { documents, pagination }: any = await dispatch(
      getAllDocumentsNewFlow(
        filters
          ? {
              ...filters,
              page: currentPage,
              page_size: perPage,
              knowledge_group_ids: props.selectedGroupId,
              ...filterMgs,
            }
          : {
              page: currentPage,
              page_size: perPage,
              knowledge_group_ids: props.selectedGroupId,
              ...filterMgs,
            },
        props.hasTopLevelApplied,
        props.isArchived
      )
    );
    
    let updatedDocuments = documents.map((d: any) => {
      let isRecentlyUsed = false;
      const docuemnt30TimeStamp = new Date(d.updated_at).getTime() + (30 * 24 * 60 * 60 * 1000);
      const current = new Date().getTime();
      
      if (docuemnt30TimeStamp > current) {
        isRecentlyUsed = true;
      }
      return { 
        ...d,
        isRecentlyUsed: isRecentlyUsed
      }
    });

    setAllDocuments(updatedDocuments);
    setPagination(pagination);
  };

  const onSortHandler = (selector: any, direction: string) => {
    setFilters({
      ...filters,
      sort_field: selector,
      sort_order: direction === "desc" ? "dsc" : "asc",
    })
  };

  /**
   *
   * @param page Pagination on change of page
   */
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setFilters({
      ...filters,
      page: page
    })
  };
  /**
   * Used to paginate data based on per page and page number
   * @param newPerPage
   * @param page
   */
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
    setFilters({
      ...filters,
      page: page,
      page_size: newPerPage
    })
  };

  /**
   * Set multiple rows in state on change of checkbox
   * @param param0
   */
  const onSelectedRowsHandler = ({
    selectedRows,
  }: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: any[];
  }) => {
    setSelectedRows(selectedRows);
  };

  const deleteConfirmed = async (
    isArchived: boolean = false,
    isArchiving: boolean = false,
    deleteIntents: boolean = false
  ) => {
    setLoading(true);
    try {
      await dispatch(
        deleteDocuments(
          typeof deletedRowId === "string" ? [deletedRowId] : deletedRowId,
          deleteIntents,
          isArchived,
          isArchiving
        )
      );
      props.onSetRecentUploadedDoc(true);
      await fun();
      setSelectedRows([]);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const restoreArchived = async (selectedRowIds: string[]) => {
    setLoading(true);
    try {
      await dispatch(restoreDocuments(selectedRowIds));
      await fun();
      setSelectedRows([]);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  const updateTurnOnOffConfirmed = async (
    e: React.ChangeEvent<HTMLInputElement>,
    data: {
      selectedRow: string[];
      isDocumentTurnOnMode: boolean;
      is_full_document_allowed: boolean;
    }
  ) => {
    e.preventDefault();
    documentPreviewModalhandler(false);
    setLoading(true);
    try {
      await dispatch(
        updateBulkDocumentSettings(
          typeof data.selectedRow === "string"
            ? [data.selectedRow]
            : data.selectedRow,
          {
            settings: {
              allowed_channels: data.isDocumentTurnOnMode
                ? ["webchat", "widget", "direct-chat"]
                : [],
              is_full_document_allowed: data.isDocumentTurnOnMode
                ? data.is_full_document_allowed
                : false,
            },
          }
        )
      );
      setSelectedRows([]);
      setLoading(false);
    } catch (error: any) {
      setSelectedRows([]);
      setLoading(false);
    }
  };

  const addToSelectedRows = (event: any, id: string) => {
    let data: any[] =
      props.createFromUploadedDocument && !props.isContexualised
        ? []
        : [...selectedRows];
    if (event.target.checked) {
      const isPresent = data.indexOf(id);
      if (isPresent < 0) {
        data.push(id);
      }
    } else {
      const oldData = [...selectedRows];
      const index = oldData.indexOf(id);
      oldData.splice(index, 1);
      data = [...oldData];
    }
    setSelectedRows([..._.uniq(data)]);
    if (props.createFromUploadedDocument) {
      props.setSelectedDoc([..._.uniq(data)]);
    }

    if ([..._.uniq(data)].length > 0 && [..._.uniq(data)].length <= 5)
      setSelectBKDisabled(false);
    else setSelectBKDisabled(true);
  };

  const onSelectAllRows = (event: any) => {
    if (event.target.checked) {
      if (documents && documents.length > 0) {
        let data: any[] = [...documents.map((intent: any) => intent._id)];
        setSelectedRows([...data]);
      }
    } else {
      setSelectedRows([]);
    }
  };

  // Show bulk assign category modal
  const BulkAssignCategoryhandler = (value: boolean) => {
    setBulkAssignCategoryShow(value);
  };
  const bulkAssignCategoryKnowledgeHandler = (id: string[]) => {
    setBulkCategoryIds(id);
    setBulkAssignCategoryShow(true);
  };

  const confirmCategoryUpdateHandler = async (selectedOption: any) => {
    try {
      BulkAssignCategoryhandler(false);
      await dispatch(
        bulkAssignModules(selectedOption, selectedRows, "document")
      );
      setSelectedRows([]);
      fun();
    } catch (error: any) {
      setSelectedRows([]);
    }
  };

  const downloadDocumentHandler = async (documentId: String, index: any) => {
    const oldDocuments = documents;
    const currDocument = oldDocuments[index];
    currDocument.loading = true;
    setAllDocuments([...oldDocuments]);
    try {
      let previewUrlResponse: any = await dispatch(
        getDocumentPreviewUrl(documentId, null, true)
      );
      const data: any = await previewUrlResponse;
      const arr = Object.values(data);
      const url = Object.values(arr[0]);
      let extension = url && url.length > 0 && url[0].preview_url !== null ? url[0].preview_url.split(".").at(-1) : "";

      if (url[0].preview_url !== null) {
        axios.get(url[0].preview_url, {
          headers: {
            "Content-Type": "application/octet-stream"
          },
          responseType: "blob"
        })
          .then(response => {
            const a = document.createElement("a");
            const objUrl = window.URL.createObjectURL(response.data);
            a.href = objUrl;
            a.download = url[0].name.replace(`.${extension}`, "") + "." + extension;
            a.click();
          })
          .catch(err => {
            console.log("error", err);
          });
      } else {
        helperService.showToaster(
          t(
            "We are not able to download the file at the moment. Please try again after sometime."
          )
        );
      }
      currDocument.loading = false;
      setAllDocuments([...oldDocuments]);
    } catch (error) {
      helperService.showToaster(
        t(
          "We are not able to download the file at the moment. Please try again after sometime."
        )
      );
    }
  };

  const docRename = (value: boolean) => {
    setShowDocumentRenameModal(value);
  };

  const showDocumentRename = (doc: any) => {
    if (doc) {
      setEditDocument(doc);
      docRename(true);
    }
  };

  const DocumentRenameHandler = async (fileName: string) => {
    if (fileName) {
      setLoading(true);
      try {
        let res: any = await dispatch(
          renameDocument(editDocument._id, fileName)
        );
        if (res) {
          let temp = [...documents];
          const doc = documents.findIndex((d) => d._id === res._id);
          temp[doc] = res;
          setAllDocuments(temp);
        }
        docRename(false);
        setLoading(false);
        setEditDocument(null);
      } catch (error) {
        docRename(false);
        setLoading(false);
        setEditDocument(null);
      }
    }
  };

  const selectDocumentsHandler = (selectedOption: any = "") => {
    let isAllDocumentTypes: boolean = false;
    let otherDocumentType: any[] = [];
    let summaryDocumentType: any[] = [];
    const group = selectedOption || props.toolkitSelectedGroupId || props.selectedGroupId
    const temp = documents.filter((doc: any) => {
      return selectedRows.some((id: any) => id === doc._id);
    });
    if(temp && temp.length > 0) {
      const groupedDocuments = _.groupBy(temp, "type");
      isAllDocumentTypes = temp.some((i: any) => i.type !== "summary");
      for(const key in groupedDocuments) {
        if(key !== "summary") {
          otherDocumentType.push(groupedDocuments[key].map(i => i._id))
        } else {
          summaryDocumentType = groupedDocuments.summary.map(i => i._id);
        }
      }
    }
    const summaryParam = summaryDocumentType && summaryDocumentType.length > 0 ? `&summaryTypeDocuments=${summaryDocumentType.join(",")}` : "";
    setSelectBKDisabled(true);
    setSelectedRows([]);
    switch (props.flowKey) {
      case "SMART_QUIZ":
        const queTypes =
          props.toolkitQuestionTypes && props.toolkitQuestionTypes.length > 0
            ? `&queTypes=${props.toolkitQuestionTypes.join(",")}`
            : "";

        if (isAllDocumentTypes) {
          history.push(
            `${SMART_QUIZ_SELECT_PAGES}?group=${
              group
            }&documents=${otherDocumentType.join(
              ","
            )}${summaryParam}`,
            {
              questionTypes: props.toolkitQuestionTypes,
              quizId: props.toolkitQuizId,
            }
          );
        } else {
          history.push(
            `${REVIEW_QUIZ_QUESTIONS}?group=${
              group
            }&document=${summaryDocumentType.join(",")}${queTypes}&quizId=${
              props.toolkitQuizId
            }&through=knowledge-base`,
            {
              questionTypes: props.toolkitQuestionTypes,
              quizId: props.toolkitQuizId,
            }
          );
        }
        break;
      case "GENERATE_KNOWLEDGE":
          if (isAllDocumentTypes) {
            history.push(
              `${TOOLKIT_GENERATE_QNA_SELECT_PAGES}?documents=${otherDocumentType.join(
                ","
              )}&group=${
                group
              }${summaryParam}`
            );
          } else {
            history.push(
              `${CONFIG.TOOLKIT_GENERATE_QNA}?question=${summaryDocumentType.join(
                ","
              )}&group=${group}&through=documents`
            );
          }
        break;
      case "SUMMARISE_DOCUMENTS":
        if (selectedRows.length > 1) {
          setShowSelectSummaryType(true);
        } else {
          history.push(
            `${TOOLKIT_SUMMARISE_DOCUMENTS_SELECT_PAGES}?documents=${selectedRows.join(
              ","
            )}&group=${
              group
            }&through=documents&summary-type=single`
          );
        }
        break;
      case "LEARNING_OUTCOMES":
        if (isAllDocumentTypes) {
          history.push(
            `${TOOLKIT_GENERATE_LEARNING_OUTCOMES_SELECT_PAGES}?documents=${otherDocumentType.join(
              ","
            )}&group=${
              group
            }${summaryParam}`
          );
        } else {
          history.push(
            `${TOOLKIT_GENERATE_LEARNING_OUTCOMES_REVIEW}?document=${summaryDocumentType.join(
              ","
            )}&group=${group}`
          );
        }
        break;
      default:
        history.push(
          `${CONFIG.BUILD_SUMMARISE_MULTI_DOCUMENTS_SECTION.replace(
            ":step",
            "3"
          ).replace(":subStep", "2")}?group=${
            group
          }&documents=${selectedRows.join(",")}&type=${props.type}`
        );
        break;
    }
  };

  
  /**
   * Set debounce time for while searching in any input
   */
 
  useEffect(() => {
    // Added 500 as debounce time will wait for 500 miliseconds and then we will fetch intents based on query
    const delayDebounceFn = setTimeout(() => {
      if (isTyping) {
        setCurrentPage(1);
        setFilters({
          ...filters,
          q: query,
        })
        setTyping(false);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [query]);

  const onInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setQuery(e.target.value);
    setTyping(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <DeleteConfirmModal
        show={showDeletePermanentlyModal}
        onClickDelete={(deleteIntents) => {
          setShowDeletePermanentlyModal(false);
          deleteConfirmed(true, false, deleteIntents);
        }}
        onClickCancel={() => {
          setShowDeletePermanentlyModal(false);
        }}
        onClickClose={() => setShowDeletePermanentlyModal(false)}
      />

      <TurnOnOffDocumentPreviewModal
        message={documentConfirmationMessage}
        size={"md"}
        show={documentPreviewModalShow}
        selectedRow={documentRowId}
        isDocumentTurnOnMode={isDocumentTurnOnMode}
        onHide={() => documentPreviewModalhandler(false)}
        confirmHandler={updateTurnOnOffConfirmed}
      />

      <DocumentConfirmationModal
        show={showDeleteConfirmationModal}
        title={t("Remove Document")}
        message={t(
          "Would you like to archive or delete the document(s) from Knowledge base?"
        )}
        size={"lg"}
        leftButtonText={t("Delete")}
        leftButtonClassName="btn btn-outline-danger font-weight-normal mx-2"
        rightButtonText={t("Archive")}
        rightButtonClassName="btn btn-primary font-weight-normal mx-2"
        onClickLeftButton={() => {
          setShowDeleteConfirmationModal(false);
          setShowDeletePermanentlyModal(true);
        }}
        onClickRightButton={() => {
          setShowDeleteConfirmationModal(false);
          deleteConfirmed(false, true, false);
        }}
        onClickClose={() => setShowDeleteConfirmationModal(false)}
      />
      <MoveSelectedModal
        show={bulkAssignCategoryShow}
        onHide={() => BulkAssignCategoryhandler(false)}
        confirmCategoryUpdate={confirmCategoryUpdateHandler}
      />

      <SelectSummaryType
        show={showSelectSummaryType}
        onHide={() => {
          setShowSelectSummaryType(false);
        }}
        documents={selectedRows}
        selectFromKnowledgeBase
        groups={props.toolkitSelectedGroupId || props.selectedGroupId}
      />

      <DocumentRename
        show={showDocumentRenameModal}
        onHide={() => {
          docRename(false);
        }}
        footerButtonAction={DocumentRenameHandler}
        documentName={editDocument?.name || editDocument?.file_name}
      />

      <SelectModalModule
        show={newDocAdded}
        onHide={() => setNewDocAdded(false)}
        confirmCategoryUpdate={(
          selectedOption: any,
          createButtons: boolean = false,
          key: string
        ) => selectDocumentsHandler(selectedOption)}
        header={props.popupProperties?.header}
        subHeader={props.popupProperties?.subHeader}
        isBuildKnowledge
        selectedFlow={props.popupProperties?.selectedFlow}
      />

      <div className="all-knowledge-search d-flex flex-wrap align-items-center">
        {!props.createFromUploadedDocument && (
          <Dropdown className="mr-2 sort-dropdown">
            <Dropdown.Toggle
              className="btn btn-outline-primary font-600 py-1 my-1 "
              variant="default"
              id="dropdown-basic"
            >
              <img src={sortIcon} alt="" className="mr-2" />
              {t("Sort")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  onSortHandler("updated_at", "desc");
                }}
              >
                {t("Most Recent")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onSortHandler("name", "asc");
                }}
              >
                {t("Ascending")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  onSortHandler("name", "desc");
                }}
              >
                {t("Descending")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}

        {!props.createFromUploadedDocument && (
          <div className="web-links my-0 mr-0">
            <input
              type="text"
              className="form-control h-auto"
              placeholder="Search Documents"
              onChange={onInputChangeHandler}
            />
          </div>
        )}

        {!props.createFromUploadedDocument &&
          !props.buildKnowledge &&
          !props.buildV2 && (
            <button
              className="btn btn-link ml-auto py-1"
              onClick={() => {
                // Routing for MGS
                if (isMgs) {
                  history.push(
                    CONFIG.KNOWLEDGE +
                      (props.queryString ? props.queryString : "")
                  );
                  return;
                } else {
                  history.push(
                  CONFIG.SETUP_CONTEXTUALISED_CHAT +
                      (props.queryString ? props.queryString : "")
                  );
                  return;
                }
              }}
            >
              {"+ Add"}
            </button>
          )}

        {props.buildKnowledge && (
          <button
            disabled={selectBKDisabled}
            className="btn btn-primary ml-auto py-1"
            onClick={() => {
              if (
                (props.selectedGroupId &&
                  props.selectedGroupId !== "undefined") ||
                (props.toolkitSelectedGroupId &&
                  props.toolkitSelectedGroupId !== "undefined")
              ) {
                selectDocumentsHandler();
              } else {
                setNewDocAdded(true);
              }
            }}
          >
            {"Select Document(s)"}
          </button>
        )}

        {props.createFromUploadedDocument &&
          !props.addMoreQuestionFromDocument &&
          !props.isContexualised &&
          !props.buildKnowledge && (
            <button
              className="btn btn-primary ml-auto py-1"
              onClick={() => {
                history.push(
                  CONFIG.CREATE_QUIZ_FROM_QUESTION_ID.replace(
                    ":questionId",
                    selectedRows[0]
                  ) +
                    `?quizId=${
                      props.isEditQuiz && props.quizId ? props.quizId : ""
                    }`,
                  {
                    createFromUploadedDocument:
                      props.isEditQuiz && props.quizId
                        ? !props.createFromUploadedDocument
                        : props.createFromUploadedDocument,
                    queryString: props.queryString,
                    isEditQuiz: props.isEditQuiz,
                    quizId: props.quizId,
                  }
                );
              }}
              disabled={selectedRows.length === 0}
            >
              {"Import Knowledge"}
            </button>
          )}
      </div>

      {props.buildKnowledge && (
        <div className="mt-2">
          <small>
            {t("Please select up to a maximum of")} 5 {t("documents in total")}.
          </small>
        </div>
      )}

      {!props.createFromUploadedDocument && (
        <div className="d-flex flex-wrap justify-content-between align-items-center mt-1">
          <div className="d-flex align-items-center">
            {!props.buildKnowledge && (
              <div className="custom-checkbox mr-2">
                <input
                  type="checkbox"
                  className="mr-2"
                  id="select-all"
                  checked={
                    selectedRows.length === documents.length &&
                    documents.length > 0
                  }
                  onChange={(e: any) => onSelectAllRows(e)}
                />
                <label htmlFor="select-all" className="mb-0">
                  {t("Select all")}
                </label>
              </div>
            )}

            <PermissionWrapper>
              {selectedRows.length > 0 && !props.buildKnowledge && (
                <>
                  <button
                    className="btn btn-suggest py-0 font-sm"
                    onClick={(e) =>
                      turnOnOffDocumentHandler(selectedRows, true, true)
                    }
                  >
                    {t("Turn On Document Preview")}
                  </button>
                  <button
                    className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                    onClick={(e) =>
                      turnOnOffDocumentHandler(selectedRows, true, false)
                    }
                  >
                    {t("Turn Off Document Preview")}
                  </button>
                  <button
                    className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                    onClick={() => {
                      setDeletedRowId(selectedRows);
                      if (props.isArchived) {
                        setShowDeletePermanentlyModal(true);
                      } else {
                        setShowDeleteConfirmationModal(true);
                      }
                    }}
                  >
                    {t("Delete Document(s)")}
                  </button>
                  {props.isArchived && (
                    <button
                      className="btn btn-outline-danger border-0 py-0 font-600 font-sm"
                      onClick={() => restoreArchived(selectedRows)}
                    >
                      {t("Restore Document(s)")}
                    </button>
                  )}
                  <button
                    className="btn btn-suggest py-0 font-sm"
                    onClick={(e) =>
                      bulkAssignCategoryKnowledgeHandler(selectedRows)
                    }
                  >
                    {t("Move Selected")}
                  </button>
                </>
              )}
            </PermissionWrapper>
          </div>
        </div>
      )}
      <div
        className={classNames({
          "accordion-viewby document-details mt-3 scroll-wrap": true,
          "document-isContexualised": props.isContexualised,
        })}
      >
        {/* {loading && <Loading />} */}
        {!loading &&
          documents.map((document: any, index: number) => {
            return (
              <Card key={"new_" + document._id + "_" + index}>
                <Card.Header className="d-flex justify-content-between align-items-center">
                  <span
                    className={classNames({
                      "clr-primary font-weight-bold": true,
                      "d-flex justify-content-between align-items-center flex-wrap":
                        true,
                    })}
                  >
                    <p className="clr-pink font-400 w-100">
                      {document.type === "sample"
                        ? t("Sample Document")
                        : document.isRecentlyUsed
                        ? t("Recently Used")
                        : ""}
                    </p>
                    {document.name || document.file_name}
                  </span>
                  <input
                    type="checkbox"
                    className="mr-2"
                    checked={selectedRows.indexOf(document._id) >= 0}
                    onChange={(e) => {
                      addToSelectedRows(e, document._id);
                    }}
                  />
                  {!props.createFromUploadedDocument &&
                    !props.buildKnowledge && (
                      <button
                        className="btn pr-4"
                        onClick={() => {
                          showDocumentRename(document);
                        }}
                      >
                        <img src={editDocumentImg} alt="edit-document" />
                      </button>
                    )}
                </Card.Header>
                {!props.isContexualised && (
                  <Card.Body className="">
                    <Row>
                      <Col
                        md={
                          document &&
                          !document.hasOwnProperty("search_index_status")
                            ? 4
                            : 3
                        }
                        className="my-2 my-md-0"
                      >
                        <h3 className="clr-black mb-1">{t("Uploaded By")}</h3>
                        <div>{document.author_email}</div>
                      </Col>
                      <Col
                        md={
                          document &&
                          !document.hasOwnProperty("search_index_status")
                            ? 4
                            : 3
                        }
                        className="my-2 my-md-0"
                      >
                        <h3 className="clr-black mb-1">{t("Upload Date")}</h3>
                        <div>
                          <Moment utc local format={"DD/MM/yyyy hh:mm A"}>
                            {document.created_at}
                          </Moment>
                        </div>
                      </Col>
                      <Col
                        md={
                          document &&
                          !document.hasOwnProperty("search_index_status")
                            ? 4
                            : 3
                        }
                        className="my-2 my-md-0"
                      >
                        <h3 className="clr-black mb-1">
                          {t("Document Preview")}
                        </h3>
                        <div>
                          {document &&
                            document.settings &&
                            document.settings.allowed_channels.length === 3 &&
                            document.settings.allowed_channels.indexOf(
                              "webchat"
                            ) >= 0 &&
                            document.settings.allowed_channels.indexOf(
                              "widget"
                            ) >= 0 &&
                            document.settings.allowed_channels.indexOf(
                              "direct-chat"
                            ) >= 0 &&
                            t("Both Web & Chat Widget")}

                          {document &&
                            document.settings &&
                            document.settings.allowed_channels.length === 1 &&
                            document.settings.allowed_channels.indexOf(
                              "webchat"
                            ) >= 0 &&
                            t("Web Chat")}

                          {document &&
                            document.settings &&
                            document.settings.allowed_channels.length === 2 &&
                            document.settings.allowed_channels.indexOf(
                              "widget"
                            ) >= 0 &&
                            document.settings.allowed_channels.indexOf(
                              "direct-chat"
                            ) >= 0 &&
                            t("Chat Widget")}
                        </div>
                      </Col>
                      {document &&
                        document.hasOwnProperty("search_index_status") && (
                          <Col md={3} className="my-2 my-md-0">
                            <h3 className="clr-black mb-1">
                              {t("Contextualised Chat")}
                            </h3>

                            <div>
                              {contextualisedChatStatus[document.search_index_status] || ""}
                            </div>
                          </Col>
                        )}
                      {/* will add once we get property for summary */}
                      {/* <Col md={3} className="my-2 my-md-0 pr-3">
                        <h3 className="h6 clr-black mb-1">
                          {t("Document Summary")}
                        </h3>
                        <div>{"Yes"}</div>
                      </Col> */}

                      {/* <Col
                        md={
                          document &&
                          !document.hasOwnProperty("search_index_status")
                            ? 6
                            : 4
                        }
                        className="my-2 my-md-0"
                      >
                        <h3 className="h6 clr-black mb-1">
                          {t("Knowledge Generated")}
                        </h3>
                        <div>
                          {document.is_knowledge_created &&
                            t("Knowledge Generated")}
                          {!document.is_knowledge_created &&
                            t("Knowledge Saved")}
                        </div>
                      </Col> */}
                    </Row>
                    {!props.buildKnowledge && (
                      <div className="mt-3">
                        <h3 className="clr-black">
                          {props.createFromUploadedDocument
                            ? t("Tags")
                            : t("Key Phrases")}
                        </h3>
                        <div className="located-tags d-flex flex-wrap align-items-center">
                          {!document.hasOwnProperty("tags") && (
                            <span>
                              {props.createFromUploadedDocument
                                ? t("No Tags found")
                                : t("No Key Phrase found")}
                            </span>
                          )}
                          {document.hasOwnProperty("tags") &&
                            document.tags.length > 0 &&
                            document.tags
                              .slice(0, 7)
                              .map((tag: string, index: number) => {
                                return <div key={tag + "_" + index}>{tag}</div>;
                              })}
                          {document.tags && document.tags.length > 8 && (
                            <button className="btn p-0 font-weight-bold clr-grey-txt7 py-0 px-2">
                              +{document.tags.length - 8}
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="mt-3 text-right">
                      {!props.isArchived &&
                        document.is_downloadable &&
                        !props.buildKnowledge &&
                        !props.createFromUploadedDocument && (
                          <button
                            className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2 my-1 my-sm-0 font-18-px"
                            onClick={() => {
                              downloadDocumentHandler(document._id, index);
                            }}
                            disabled={!!document.loading}
                          >
                            {document.loading && (
                              <span className="doc-loader spinner-width-20 mr-2 mb-0"></span>
                            )}{" "}
                            {t("Download Document")}
                          </button>
                        )}
                      {!props.isArchived &&
                        !props.buildKnowledge &&
                        !props.createFromUploadedDocument &&
                        (document.flow !== "v2" ||
                          document.hasOwnProperty("search_index_status")) && (
                          <button
                            className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2 my-1 my-sm-0 font-18-px"
                            onClick={() => {
                              history.push(
                                CONFIG.DOCUMENTDETAILBYID.replaceAll(
                                  ":documentId",
                                  document._id
                                ) +
                                  "?question=" +
                                  document._id +
                                  "&type=" +
                                  document?.type,
                                {
                                  showQuestions: true,
                                }
                              );
                            }}
                          >
                            {t("Manage Document")}
                          </button>
                        )}
                      {props.isArchived &&
                        !props.buildKnowledge &&
                        !props.createFromUploadedDocument && (
                          <button
                            className="btn btn-outline-primary font-weight-normal mx-2 py-1 py-md-2 font-18-px"
                            onClick={() => restoreArchived([document._id])}
                          >
                            {t("Restore")}
                          </button>
                        )}
                      {!props.buildKnowledge &&
                        !props.createFromUploadedDocument && (
                          <button
                            className="btn btn-outline-danger font-weight-normal mx-2 py-1 py-md-2 my-1 my-sm-0 font-18-px"
                            onClick={() => {
                              setDeletedRowId([document._id]);
                              if (props.isArchived) {
                                setShowDeletePermanentlyModal(true);
                              } else {
                                setShowDeleteConfirmationModal(true);
                              }
                            }}
                          >
                            {t("Delete")}
                          </button>
                        )}
                    </div>
                  </Card.Body>
                )}
              </Card>
            );
          })}
      </div>

      {pagination && documents && documents.length > 0 && (
        <Pagination
          scrollWrapper="table-wraper"
          scrollRightValue={scrollRight}
          hideSuggestedQuestion={true}
          showScrollButtons={false}
          currentPage={currentPage}
          pagination={pagination}
          paginationPerPage={perPage}
          pageChange={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      )}

      {documents && documents.length === 0 && (
        <div className="empty-knowledge text-center my-5">
          <h3 className="h6 clr-grey-txt7 mb-3">
            {t(
              "No document uploaded in this module. Click the button below to upload document."
            )}
          </h3>
          <figure className="h-auto">
            <img src={emptyKnowledge} alt="" />
          </figure>
          <div className="clr-primary font-weight-bold">
            <Link
              to={
                isMgs
                  ? CONFIG.KNOWLEDGE
                  : CONFIG.TOOLKIT_GENERATE_QNA_UPLOAD_DOC + props.queryString
              }
            >
              {t("Click here to get started")}
            </Link>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UploadedDocuments;
