import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import SelectTree from '../../../../../components/UI/SelectTree/SelectTree'
import Tags from '../../../../../components/UI/Tags/Tags'

import i18n from "../../../../../components/UI/Internationalisation/i18n";
const { t } = i18n;

export const customStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  headRow: {
    style: {
      borderRadius: '10px',
      backgroundColor: '#0923E6',
      borderBottomWidth: '0',
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      button: {
        backgroundColor: 'transparent !important',
        img: {
          width: '10px',
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: 'transparent',
      alignItems: 'center',
    },
  },
  headCells: {
    style: {
      '&:first-child': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '0px',
      },
      '&.chart--table-sort-icon': {
        marginLeft: '10px',
      },
      'input[type="checkbox"]': {
        width: '16px',
        height: '16px',
      },
      backgroundColor: '#0923E6',
      width: '250px',
      padding: '20px 20px',
      fontSize: '14px',
      color: '#fff',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#FFFFFF',
      },
      div: {
        color: '#fff',
      },
    },
  },
  cells: {
    style: {
      color: '#07032B',
      fontSize: '14px',
      paddingTop: '10px',
      paddingBottom: '10px',
      'input[type="checkbox"]': {
        width: '16px',
        height: '16px',
      },
      '&:nth-child(1)': {
        div: {
          textOverflow: 'ellipsis',
          display: ' -webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        },
      },
      '&:nth-child(2)': {
        div: {
          textOverflow: 'ellipsis',
          display: ' -webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        },
      },
    },
  },
}

export const conversationsCustomStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  headRow: {
    style: {
      borderRadius: '10px',
      backgroundColor: '#0923E6',
      borderBottomWidth: '0',
    },
  },
  expanderRow: {
    style: {
      // backgroundColor: '#0923E6',
    },
  },
  expanderCell: {
    style: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      button: {
        backgroundColor: 'transparent !important',
        img: {
          width: '10px',
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: 'transparent',
      alignItems: 'center',
    },
  },
  headCells: {
    style: {
      '&:first-child': {
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '0px',
      },
      '&.chart--table-sort-icon': {
        marginLeft: '10px',
      },
      'input[type="checkbox"]': {
        width: '16px',
        height: '16px',
      },
      backgroundColor: '#0923E6',
      width: '250px',
      padding: '20px 20px',
      fontSize: '14px',
      color: '#fff',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: '#FFFFFF',
      },

      div: {
        color: '#fff',
      },
    },
  },
  cells: {
    style: {
      color: '#07032B',
      fontSize: '14px',
      paddingTop: '10px',
      paddingBottom: '10px',
      'input[type="checkbox"]': {
        width: '16px',
        height: '16px',
      },
      '&:nth-child(1)': {
        div: {
          textOverflow: 'ellipsis',
          display: ' -webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        },
      },
      '&:nth-child(2)': {
        div: {
          textOverflow: 'ellipsis',
          display: ' -webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        },
      },
      '&:nth-child(3)': {
        div: {
          textOverflow: 'ellipsis',
          display: ' -webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
        },
      },
    },
  },
}

const CategoryComponent = ({
  row,
  allTopics,
  index,
  onFilterInputChange,
  filters,
  selectedNodes,
  removeTagHandler
}: any) => {
  return (
    <div className='w-100' key={'div_' + row._id}>
      {index === 0 && (
        <>
          <SelectTree
            className='react-select-category-container'
            classNamePrefix='react-select'
            options={allTopics}
            handleChangeReactSelect={(e: any, data: any) =>
              onFilterInputChange(data, 'topicQuery')
            }
            isSearchable={false}
            isClearable={false}
            // selectedValueAsObject={{
            //   value: row.topic_id,
            //   label: row.topic_name,
            // }}
            defaultMenuIsOpen={false}
          />
          <div className='knowledge-tags d-flex flex-wrap align-items-center'>
            {selectedNodes.length > 0 &&
              selectedNodes.map((value: any, index: any) => {
                return (
                  <Tags
                    key={value + '_' + index}
                    value={value.value}
                    label={value.label}
                    removeTagHandler={removeTagHandler}
                  />
                )
              })}
          </div>
        </>
      )}
      {index > 0 && <>{row.topic}</>}
    </div>
  )
}

export const getAiTutorColumns = ({
  allTopics,
  onFilterInputChange,
  filters,
  selectedNodes,
  removeTagHandler
}: any) => [
  {
    name: t('Session date'),
    selector: 'date',
    minWidth: '100px',
  },
  {
    name: t('Module'),
    selector: 'module',
    wrap: true,
  },
  {
    name: t('Tutorial group'),
    selector: 'tutorGroup',
    sortable: true,
  },
  {
    name: t('Name'),
    selector: 'name',
    // grow: 2,
  },
  {
    name: t('Learner ID'),
    selector: 'studentId',
    sortable: true,
    minWidth: '120px',
  },
  {
    name: t('Category'),
    selector: 'topic',
    wrap: true,
    sortable: true,
    cell: (row: any, index: number) => (
      <CategoryComponent
        onFilterInputChange={onFilterInputChange}
        filters={filters}
        row={row}
        index={index}
        allTopics={allTopics}
        selectedNodes={selectedNodes}
        removeTagHandler={removeTagHandler}
      />
    ),
    // minWidth: "170px",
    // grow: 3,
  },
  {
    name: t('Pre-test high'),
    selector: 'preTestHigh',
    sortable: true,
  },
  {
    name: t('Pre-test avg'),
    selector: 'preTestAvg',
    sortable: true,
  },

  {
    name: t('Post-test high'),
    selector: 'postTestHigh',
    sortable: true,
  },
  {
    name: t('Post-test avg'),
    selector: 'postTestAvg',
    sortable: true,
  },
  {
    name: t('Session duration (mins)'),
    selector: 'duration',
    sortable: true,
  },
  {
    name: t('Action'),
    selector: 'action',
    wrap: true,
  },
]

export const getAiMarkerColumns = ({
  allTopics,
  onFilterInputChange,
  filters,
  selectedNodes,
  removeTagHandler
}: any) => [
  {
    name: t('Session date'),
    selector: 'date',
    minWidth: '100px',
  },
  {
    name: t('Module'),
    selector: 'module',
    wrap: true,
  },
  {
    name: t('Tutorial group'),
    selector: 'tutorGroup',
    sortable: true,
  },
  {
    name: t('Name'),
    selector: 'name',
    // grow: 2,
  },
  {
    name: t('Learner ID'),
    selector: 'studentId',
    sortable: true,
    minWidth: '120px',
  },
  {
    name: t('Category'),
    selector: 'topic',
    wrap: true,
    sortable: true,
    cell: (row: any, index: number) => (
      <CategoryComponent
        onFilterInputChange={onFilterInputChange}
        filters={filters}
        row={row}
        index={index}
        allTopics={allTopics}
        selectedNodes={selectedNodes}
        removeTagHandler={removeTagHandler}
      />
    ),
    // minWidth: "170px",
    // grow: 3,
  },
  {
    name: t('Highest score attained'),
    selector: 'postTestHigh',
    sortable: true,
  },
  {
    name: t('Avg score attained'),
    selector: 'postTestAvg',
    sortable: true,
  },
  {
    name: t('Max allotted score'),
    selector: 'postTestTotal',
    sortable: true,
  },
  {
    name: t('Session duration (mins)'),
    selector: 'duration',
    sortable: true,
  },
  {
    name: t('Action'),
    selector: 'action',
    wrap: true,
  },
]

export const transformTableData = (data: any, t?: any) => {
  let transformedData = []

  transformedData = data.matchedRecords.map((prop: any, key: number) => {
    return {
      date: <Moment format='DD-MM-YYYY'>{prop.creation_date}</Moment>,
      // school: prop.school ? prop.school : "-",
      module: prop.module ? prop.module : '-',
      tutorGroup: prop.tutor_group ? prop.tutor_group : '-',
      name: (
        <div>
          <Link to={`/usagetrends/usersessions/${prop.email}/${prop.user_id}`}>
            {prop.student_name.trim() ? `${prop.student_name}` : t('Name Unknown')}
          </Link>
        </div>
      ),
      studentId: prop.student_id ? prop.student_id : '-',
      topic: prop.topic ? prop.topic : '-',
      preTestHigh:
        prop.pretest_highest_score !== null
          ? Math.round((prop.pretest_highest_score + Number.EPSILON) * 100) /
            100
          : '-',
      preTestAvg:
        prop.pretest_average_score !== null
          ? Math.round((prop.pretest_average_score + Number.EPSILON) * 100) /
            100
          : '-',
      preTestTotal:
        prop.pretest_total_score !== null
          ? Math.round((prop.pretest_total_score + Number.EPSILON) * 100) / 100
          : '-',
      postTestHigh:
        prop.posttest_highest_score !== null
          ? Math.round((prop.posttest_highest_score + Number.EPSILON) * 100) /
            100
          : '-',
      postTestAvg:
        prop.posttest_average_score !== null
          ? Math.round((prop.posttest_average_score + Number.EPSILON) * 100) /
            100
          : '-',
      postTestTotal:
        prop.posttest_total_score !== null
          ? Math.round((prop.posttest_total_score + Number.EPSILON) * 100) / 100
          : '-',
      duration: Math.round(prop.duration_seconds / 60),
      action: (
        <div className='btn-group'>
          <button
            type='button'
            className='btn btn-edit'
            onClick={(e: any) => data.setShowConversationsModalHandler(prop)}
          >
            {t("View Details")}
          </button>
        </div>
        // getConversations(prop.user_id)
      ),
    }
  })

  transformedData.unshift({
    id: 'learner-progress-filter',
    module: '',
    name: (
      <input
        type='text'
        className='form-control'
        placeholder={t('Search name')}
        onChange={(event) =>
          data.onFilterInputChange(event.target.value, 'filteredName')
        }
        value={
          data.filters && data.filters.filteredName
            ? data.filters.filteredName
            : ''
        }
      />
    ),
    tutorGroup: (
      <input
        type='text'
        className='form-control'
        placeholder={t('Search tutor group')}
        value={
          data.filters && data.filters.filteredTutorGroup
            ? data.filters.filteredTutorGroup
            : ''
        }
        onChange={(event) =>
          data.onFilterInputChange(event.target.value, 'filteredTutorGroup')
        }
      />
    ),
    studentId: (
      <input
        type='text'
        className='form-control'
        placeholder={t('Search learner Id')}
        onChange={(event) =>
          data.onFilterInputChange(event.target.value, 'filteredStudentId')
        }
        value={
          data.filters && data.filters.filteredStudentId
            ? data.filters.filteredStudentId
            : ''
        }
      />
    ),
    topic: '',
    preTestAvg: '',
    postTestHigh: '',
    postTestAvg: '',
    duration: '',
    action: '',
  })

  return transformedData
}

export const attendanceColumns = [
  {
    name: t('Name'),
    selector: 'name',
    grow: 2,
  },
  {
    name: t('Learner ID'),
    selector: 'studentId',
    grow: 2,
  },
  {
    name: t('Tutorial Group'),
    selector: 'tutorGroup',
  },
  {
    name: t('Last Session'),
    selector: 'lastSession',
    grow: 2,
  },
  {
    name: t('Session Count'),
    selector: 'sessionCount',
  },
  {
    name: t('Pre-test attemps'),
    selector: 'preTestAttempts',
  },
  {
    name: t('Post-test attempts'),
    selector: 'postTestAttempts',
    grow: 3,
  },
  {
    name: t('Last Login Date'),
    selector: 'lastLoginDate',
  }
]

export const transformAttendanceData = (data: any, t: any) => {
  let transformedData = []
  transformedData = data.paginatedrecords.map((prop: any, key: number) => {
    return {
      id: prop._id,
      name: (
        <div>
          <Link to={`/usagetrends/usersessions/${prop.email}/${prop._id}`}>
            {prop.student_name.trim() ? `${prop.student_name}` : t('Name Unknown')}
          </Link>
        </div>
      ),
      studentId: prop.student_id ? prop.student_id : '-',
      tutorGroup: prop.tutor_group ? prop.tutor_group : '-',
      lastSession:
        'last_session' in prop && prop.last_session ? (
          <Moment format='DD-MM-YYYY'>{prop.last_session}</Moment>
        ) : (
          '-'
        ),
      sessionCount: 'sessions_count' in prop ? prop.sessions_count : '-',
      preTestAttempts: (
        <div className='tutoring-attempts-cell'>
          {prop.pretest_attempts ? prop.pretest_attempts : '-'}
          <br />
          <br />
          {prop.pretest_attempts && <b>Total: {prop.pretest_attempts_count}</b>}
        </div>
      ),
      postTestAttempts: (
        <div className='tutoring-attempts-cell'>
          {prop.posttest_attempts ? prop.posttest_attempts : '-'}
          <br />
          <br />
          {prop.posttest_attempts && (
            <b>Total: {prop.posttest_attempts_count}</b>
          )}
        </div>
      ),
      lastLoginDate: 'last_login_at' in prop && prop.last_login_at ? (
        <Moment format='DD-MM-YYYY'>{prop.last_login_at}</Moment>
      ) : (
        '-'
      )
    }
  })

  transformedData.unshift({
    id: 'filter-row',
    name: (
      <input
        type='text'
        className='form-control'
        placeholder={t('Search name')}
        onChange={(event) =>
          data.onFilterInputChange(event.target.value, 'filteredName')
        }
        value={
          data.filters && data.filters.filteredName
            ? data.filters.filteredName
            : ''
        }
      />
    ),
    studentId: (
      <input
        type='text'
        className='form-control'
        placeholder={t('Search learner Id')}
        onChange={(event) =>
          data.onFilterInputChange(event.target.value, 'filteredStudentId')
        }
        value={
          data.filters && data.filters.filteredStudentId
            ? data.filters.filteredStudentId
            : ''
        }
      />
    ),
    tutorGroup: (
      <input
        type='text'
        className='form-control'
        placeholder={t('Search tutor group')}
        onChange={(event) =>
          data.onFilterInputChange(event.target.value, 'tutorGroup')
        }
        value={
          data.filters && data.filters.tutorGroup ? data.filters.tutorGroup : ''
        }
      />
    ),
    lastSession: '',
    sessionCount: '',
  })

  return transformedData
}
