import cx from 'classnames'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import DashboardFiltersWithDateOnly from '../../../../components/UI/Dashboard/DashboardFiltersWithDateOnly/DashboardFiltersWithDateOnly'
import '../../index.scss'
import DashboardNavigation from '../DashboardNavigation'
import UnMatchedDatatableComponent from '../MatchedUnmatchedQuestions/partials/UnMatchedDatatable'
import LearnerProgress from './partials/LearnerProgress'
import {
  getAgentSettings,
  getAllTopics,
} from '../../../../store/knowledge/actions'
import {
  getAllTopicsSelector,
  isTopicsLoading,
} from '../../../../store/knowledge/selector'
import LearnerAttendance from './partials/LearnerAttendance'

import { useTranslation } from "react-i18next";

interface Props {}

export const Learning = (props: any) => {
  const { t } = useTranslation('translation');
  const allTopics = useSelector(getAllTopicsSelector)
  const topicsLoading = useSelector(isTopicsLoading)
  const history = useHistory()
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState('matched')
  const [isAiTutor, setIsAiTutor] = useState(false)
  const [filtersData, setFiltersData] = useState({
    filter_from_date: null,
    filter_to_date: null,
    // channel: null,
  })

  useEffect(() => {
    agentSettings()
    if (!topicsLoading) {
      dispatch(getAllTopics())
    }
    return () => {}
  }, [])

  const agentSettings = async () => {
    try {
      const settings: any = await dispatch(getAgentSettings())
      if (settings.status.code === 200) {
        if (
          'is_ai_tutor' in settings.data.agent.settings &&
          settings.data.agent.settings.is_ai_tutor
        ) {
          setIsAiTutor(true)
        }
      }
    } catch (error) {}
  }

  const hasFilterUpdated = async (e: any) => {
    await setFiltersData(e)
  }

  return (
    <>
      <section
        className={cx({
          'page-mid-wraper dashborad-wraper h-without-foter': true,
        })}
      >
        <div className='page-mid-wraper'>
          {/*--------- top-navigation -----------*/}
          <DashboardNavigation />
          {/*-------- welcome text ------------*/}
          <div className='welcome-wrap welcome-subtext d-flex flex-md-nowrap flex-wrap mb-4'>
            <h2>{t("Learning")}</h2>
          </div>

          <DashboardFiltersWithDateOnly hasFilterUpdated={hasFilterUpdated} />

          <LearnerProgress isAiTutor={isAiTutor} filtersData={filtersData} allTopics={allTopics} />
          <LearnerAttendance isAiTutor={isAiTutor} filtersData={filtersData} />
        </div>
      </section>
    </>
  )
}

export default Learning
