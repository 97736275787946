import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Image, Container } from "react-bootstrap";
import inteApis from "../../services/IntegrationService";
import arrowLeft from "../../assets/images/arrow-left.svg";
import OnlySupperAdmin from "../../components/UI/OnlySupperAdmin/OnlySupperAdmin";
import CanvasIntegration from "./CanvasIntegration";
import BlackboardIntegration from "./BlackboardIntegration";

export const Integrations = () => {
  const { t } = useTranslation("translation");
  const history = useHistory();
  const [integrations, setIntegrations] = useState({
    canvas: null,
    blackboard: null
  });

  const fetchIntegrations = async () => {
    const { status, data } = await inteApis.getIntegrations();
    if (status === 200) {
      // setIntegrations(data?.data?.integrations || []);
      let _integrations: any = {};
      data?.data?.integrations?.forEach((item: any) => {
        _integrations[item.provider] = item;
      });
      setIntegrations(_integrations);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <OnlySupperAdmin>
      <div className="page-mid-wraper integration">
        <button
          className="btn p-0 btn-back mt-2"
          onClick={() => {
            history.goBack();
          }}
        >
          <Image src={arrowLeft} /> {t("Return to previous page")}
        </button>

        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-4">
            <CanvasIntegration
                data={integrations.canvas}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <BlackboardIntegration
                data={integrations?.blackboard}
            />
          </div>
        </div>
      </div>
    </OnlySupperAdmin>
  );
};

export default Integrations;
