import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import PermissionWrapper from "../../../components/UI/PermissionWrapper/PermissionWrapper";
import {
  CREATE_QUESTION_BANK,
  IMPORTQUESTIONBANK,
  INTEGRATED_SOURCES,
} from "../../../config";
import CreateQuestionFromDocument from "./CreateQuestionFromDocument";
import CreateQuestionFromQuestionBank from "./CreateQuestionFromQuestionBank";
import { checkIntegration } from "../../TeachersToolkit/pages/Shared/utils";

function CreateQuestionsOptions(props: any) {
  const history = useHistory();
  const [createFromDocumentModal, setCreateFromDocumentModal] = useState(false);
  const [createFromQuestionBankModal, setCreateFromQuestionBankModal] =
    useState(false);
  const [isCanvasIntegrated, setIsCanvasIntegrated] = useState({
    intregate: false,
    data: null,
    show: false,
  });

  useEffect(() => {
    checkIntegration({ setIsCanvasIntegrated, isCanvasIntegrated });
  }, []);

  const createQuestionFromDocumentHandler = () => {
    setCreateFromDocumentModal(!createFromDocumentModal);
  };

  const createQuestionFromQuestionBankHandler = () => {
    setCreateFromQuestionBankModal(!createFromQuestionBankModal);
  };

  const addMoreQuestionToQuiz = (question_ids: any[], quizId: any) => {
    props.addMoreQuestionToQuiz(question_ids, quizId);
    createQuestionFromQuestionBankHandler();
  };

  return (
    <>
      <CreateQuestionFromDocument
        show={createFromDocumentModal}
        selectedGroupIdForDocument={props.knowledge_group_ids}
        isEditQuiz={true}
        quizId={props.quizId}
        onHide={createQuestionFromDocumentHandler}
        confirmHandler={() => {}}
        // toolkitSmartQuizAddMoreQue={props.toolkitSmartQuiz}
        queTypes={props.queTypes}
        questionBank={props.questionBank}
        document_Id={props.document_Id}
      />

      <CreateQuestionFromQuestionBank
        show={createFromQuestionBankModal}
        selectedGroupIdForDocument={props.knowledge_group_ids}
        isEditQuiz={true}
        quizId={props.quizId}
        onHide={createQuestionFromQuestionBankHandler}
        confirmHandler={() => {}}
        // toolkitSmartQuizAddMoreQue={props.toolkitSmartQuiz}
        queTypes={props.queTypes}
        addMoreQuestionToQuiz={addMoreQuestionToQuiz}
      />

      <PermissionWrapper>
        <Dropdown className="sort-dropdown ml-auto">
          <Dropdown.Toggle
            className="btn btn-outline-primary font-600  py-1"
            variant="default"
            id="dropdown-basic"
          >
            + {props.questionBank ? t("Add") : t("Add Question")}
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="smart-quiz-add-more"
            // {`${props.toolkitSmartQuiz ? "smart-quiz-add-more" : ""}`}
          >
            {!props.questionBank && (
              <Dropdown.Item onClick={createQuestionFromQuestionBankHandler}>
                {t("Import from Question bank")}
              </Dropdown.Item>
            )}

            <Dropdown.Item onClick={createQuestionFromDocumentHandler}>
              {t("Generate from Uploaded Document")}
            </Dropdown.Item>
            {isCanvasIntegrated.intregate && (
              <Dropdown.Item
                onClick={() => {
                  const through = props.questionBank
                    ? `&through=questionBank`
                    : "";
                  const group = props.knowledge_group_ids
                    ? `&group=${props.knowledge_group_ids}`
                    : "";
                  history.push(
                    `${INTEGRATED_SOURCES}?quizId=${props?.quizId}${through}${group}`,
                    { integrationData: isCanvasIntegrated.data }
                  );
                }}
              >
                {t("Import from Integration Source")}
              </Dropdown.Item>
            )}
            <Dropdown.Item
              onClick={() => {
                history.push(
                  IMPORTQUESTIONBANK + "?group=" + props.knowledge_group_ids,
                  {
                    quizId: props?.quizId, //call from create/edit quiz
                  }
                );
              }}
            >
              {t("Upload a test")}
            </Dropdown.Item>
            <Dropdown.Item
              onClick={
                props.addNewQuestion
                  ? props.addNewQuestion
                  : () => {
                      history.push(
                        CREATE_QUESTION_BANK +
                          "?group=" +
                          props.knowledge_group_ids
                      );
                    }
              }
            >
              {t("Create from scratch")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </PermissionWrapper>
    </>
  );
}

export default CreateQuestionsOptions;
