import cx from "classnames";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import anyalysImg from "../../../../assets/images/analyse.svg";
import scheduleImg from "../../../../assets/images/schedule.svg";
import { getAgentFeatures } from "../../../../store/common/actions";
import { getCommonUserDetails } from "../../../../store/common/selector";
import TeachersToolkit from "../../../TeachersToolkit";
import DashboardNavigation from "../DashboardNavigation";
import "./style.css";

function Tab({
  value,
  title,
  current_state,
  onTabClick,
}: {
  value: string;
  title: string;
  current_state: string;
  onTabClick: any;
}) {
  const step_value = parseInt(value.replace("Step ", "")); // extract the number
  return (
    <div
      className={`onboard-tab ${current_state}`}
      data-dispctrl={`${step_value}`}
      onClick={onTabClick}
    >
      <div className="tab-circle">{step_value}</div>
      <div className="tab-text">{title}</div>
    </div>
  );
}
const Onboard = (props: any) => {
  const { t } = useTranslation("translation");
  const user = useSelector(getCommonUserDetails);
  const dispatch = useDispatch();
  const history = useHistory();
  const [disp, setDisp] = useState(1); // update the current display (on the right) being shown
  useLayoutEffect(()=>{
    history.push("/overview")
  },[])

  const [dispstate, setDispState] = useState([
    "current",
    "incomplete",
    "incomplete",
    "incomplete",
    "incomplete",
  ]);
  let tab_names = [
    "Build your Knowledge Base",
    "Create Quiz",
    "Setup Learning Pathway",
    "Customise Your AI Tutor",
    "Manage Your Users",
  ];

  const [isSupportFeatureEnabled, setIsSupportFeatureEnabled] = useState(false);

  useEffect(() => {
    const getAgentFeature = async () => {
      const agentFeatures: any = await dispatch(getAgentFeatures());
      setIsSupportFeatureEnabled(
        agentFeatures.some((feature: any) => feature.name === "support")
      );
    };
    getAgentFeature();
  }, []);

  function handleNext(value: number) {
    setDisp(value + 1);

    for (let i = 0; i < dispstate.length; i++) {
      if (i === value) dispstate[i] = "current";
      // else if (i < value)
      //     dispstate[i] = 'complete';
      else if (dispstate[i] !== "complete")
        // do not override already completed
        dispstate[i] = "incomplete";
    }
  }

  function handleCurrent(value: number) {
    setDisp(value + 1);

    for (let i = 0; i < dispstate.length; i++) {
      if (dispstate[i] !== "complete")
        // do not override already completed
        dispstate[i] = "incomplete";
    }

    dispstate[value] = "current";
  }

  function generateTabs() {
    let content = [];
    for (let i = 0; i < 5; i++) {
      content.push(
        <Tab
          value={"Step " + (i + 1)}
          title={t(tab_names[i])}
          current_state={dispstate[i]}
          onTabClick={() => {
            handleCurrent(i);
          }}
        />
      );
    }
    return content;
  }

  return (
    <>
      <section
        className={cx({
          "page-mid-wraper dashborad-wraper h-without-foter": true,
        })}
      >
        <div className="container">
          {/*-------- welcome text ------------*/}
          <div className="welcome-wrap d-flex flex-md-nowrap flex-wrap mb-4">
            <div>
              <img src={anyalysImg} alt={t("Dashboard")} />
            </div>
            <article>
              {user && (
                <h1>
                  {t("Welcome back")},{" "}
                  {user.first_name || user.last_name
                    ? user.first_name + " " + user.last_name
                    : user.email}
                </h1>
              )}
              <h3>{t("Track. Monitor. Enhance.")}</h3>
              {/* {process.env &&
                        process.env.REACT_APP_CISCO !== 'cisco' &&
                        !setup_completed && (
                            <button
                            className='btn btn-outline-primary mt-3'
                            onClick={resumeSetupHandler}
                            >
                            Resume Setup
                            </button>
                        )} */}
            </article>
          </div>

          {/*--------- top-navigation -----------*/}
          <DashboardNavigation />

          {/* <div className="row onboard-content-container">
            <div className="col-md-12">
              <div className="mb-4 row">
                <div className="col-lg-4 onboard-btn-wrapper">
                  {generateTabs()}
                </div>
                <div
                  className="col-lg-8 onboard-content-wrapper onboard-text-wrapper"
                  data-display={"display" + disp}
                >
                  <div id="display1" className="row onboard-content">
                    <div className="col-md-8 col-lg-6 right-image-wrapper">
                      <img src={step1} alt="" className="step-image" />
                    </div>
                    <div className="col-md-4 col-lg-6 right-text-wrapper">
                      <p className="step-text">
                        {t(
                          "Upload your documents to create context for your chatbot and generate knowledge."
                        )}
                      </p>
                      <button
                        className="btn-primary btn ml-3 mr-0"
                        onClick={() => {
                          history.push("./build/1/knowledge");
                        }}
                      >
                        {t("Upload Documents(s)")}
                      </button>
                      <p className="step-next" onClick={() => handleNext(1)}>
                        {t("Next Step")}
                        <img src={next} alt="" className="step-next-icon" />
                      </p>
                    </div>
                  </div>
                  <div id="display2" className="row onboard-content">
                    <div className="col-md-8 col-lg-6 right-image-wrapper">
                      <img src={step2} alt="" className="step-image" />
                    </div>
                    <div className="col-md-4 col-lg-6 right-text-wrapper">
                      <p className="step-text">
                        {t(
                          "Evaluate your leaners' knowledge with custom quizzes"
                        )}
                      </p>
                      <button
                        className="btn-primary btn ml-3 mr-0"
                        onClick={() => {
                          history.push("./beta/quizzes");
                        }}
                      >
                        {t("Build Quiz")}
                      </button>
                      <p className="step-next" onClick={() => handleNext(2)}>
                        {t("Next Step")}
                        <img src={next} alt="" className="step-next-icon" />
                      </p>
                    </div>
                  </div>
                  <div id="display3" className="row onboard-content">
                    <div className="col-md-8 col-lg-6 right-image-wrapper">
                      <img src={step3} alt="" className="step-image" />
                    </div>
                    <div className="col-md-4 col-lg-6 right-text-wrapper">
                      <p className="step-text">
                        {t(
                          "Shape your content and design personalised learning pathways"
                        )}
                      </p>
                      <button
                        className="btn-primary btn ml-3 mr-0"
                        onClick={() => {
                          history.push(CONVERSATION_FLOW);
                        }}
                      >
                        {t("Setup Learning Pathways")}
                      </button>
                      <p className="step-next" onClick={() => handleNext(3)}>
                        {t("Next Step")}
                        <img src={next} alt="" className="step-next-icon" />
                      </p>
                    </div>
                  </div>
                  <div id="display4" className="row onboard-content">
                    <div className="col-md-8 col-lg-6 right-image-wrapper">
                      <img src={step4} alt="" className="step-image" />
                    </div>
                    <div className="col-md-4 col-lg-6 right-text-wrapper">
                      <p className="step-text">
                        {t(
                          "Tailor your chatbot to reflect your brand's identity"
                        )}
                      </p>
                      <button
                        className="btn-primary btn ml-3 mr-0"
                        onClick={() => {
                          history.push(CHAT_CUSTOMISATION + "?viewMode=true");
                        }}
                      >
                        {t("Customise Chatbot")}
                      </button>
                      <p className="step-next" onClick={() => handleNext(4)}>
                        {t("Next Step")}
                        <img src={next} alt="" className="step-next-icon" />
                      </p>
                    </div>
                  </div>
                  <div id="display5" className="row onboard-content">
                    <div className="col-md-8 col-lg-6 right-image-wrapper">
                      <img src={step5} alt="" className="step-image" />
                    </div>
                    <div className="col-md-4 col-lg-6 right-text-wrapper">
                      <p className="step-text">
                       {t(" Get started by inviting admins and learners, and setting up classes")}
                      </p>
                      <button
                        className="btn-primary btn ml-3 mr-0"
                        onClick={() => {
                          history.push(USER_MANAGEMENT + "?viewMode=true");
                        }}
                      >
                        {t("Manage Users")}
                      </button>
                      <p
                        className="step-next"
                        onClick={() => {
                          history.push("./");
                        }}
                      >
                        {t("Next Step")}
                        <img src={next} alt="" className="step-next-icon" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*-------- Onboard Body ------------*/}
          <TeachersToolkit />
          {/*-------- Onboard Banner ------------*/}
          {isSupportFeatureEnabled && (
            <div className="mt-2">
              <h2 className="mb-3">
                {t("Need help getting started?")}
              </h2>
              <div className="onboard-banner quick-start-box mb-4 d-flex align-items-center">
                <div className="onboard-banner-item">
                  <img src={scheduleImg} alt={t("Schedule")} />
                </div>
                <div className="onboard-banner-item pl-4">
                  <div>
                    <h2 className="font-18-px">
                      {t("Onboarding Call with Learning Success Manager")}
                    </h2>
                    <span className="decription font-14">
                      {t(
                        "Schedule an Onboarding Call with our Learning Success Manager to setup your account."
                      )}
                    </span>
                  </div>
                </div>
                <div className="onboard-banner-item">
                  <button
                    className="btn-primary btn ml-3 mr-0"
                    onClick={() => {
                      window.open(
                        "https://outlook.office365.com/owa/calendar/NoodleFactoryLearningSuccessTeam@noodlefactory.ai/bookings/",
                        "_blank"
                      );
                    }}
                  >
                    {t("Schedule a Call")}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Onboard;
