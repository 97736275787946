import React, { useEffect } from 'react'
import { Route, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import { AppStateType } from '../store/rootReducer'
import Loading from '../components/UI/Loading/Loading'
import MainLayout from '../components/MainLayout/MainLayout'

const OneAuthWrapper = ({ Component, ...rest }: any) => {
  const windowObj: any = window
  const { history } = rest
  // if(!localStorage.getItem('originalPath')){
  //   localStorage.setItem(
  //     'originalPath',
  //     window.location.pathname// store with query params if any
  //   )
  // }

  // const token = localStorage.getItem('token')
  const { isLoggedIn } = useSelector((state: AppStateType) => {
    return state.auth
  })

  useEffect(() => {
    // redirect if token and user is not logged in
    // if token is invalid then we have 401 setted up
    // that will logged out user and let user login again
    if (!isLoggedIn && !history.location.search) {
      let url =
        windowObj.REACT_APP_ONE_AUTH_DOMAIN +
        "/authorize?scope=openid%20profile%20email&response_type=code&client_id=" +
        windowObj.REACT_APP_ONE_AUTH_CLIENTID +
        "&redirect_uri=" +
        window.location.origin +
        windowObj?.SUB_DOMAIN +
        "/" +
        "&nonce=" +
        uuidv4() +
        "&state=" +
        uuidv4();
      localStorage.setItem(
        "originalPath",
        window.location.pathname // store with query params if any
      );
      window.location.href = url;
    }
  }, []);
  return (
    <Route
      exact
      {...rest}
      render={(matchProps) =>
        isLoggedIn? (
          <MainLayout showSidebar={rest.isHeader}>
            <Component history={history} {...matchProps} />
          </MainLayout>
        ) : (
          <Loading />
        )
      }
    />
  )
}

export default OneAuthWrapper
