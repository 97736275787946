import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client, { Auth0ClientOptions } from '@auth0/auth0-spa-js'
import AppInstance from '../axios-global'
import DataApiInstance from '../axios-data-instance'
import ApiUtil from './apiUtil'

interface Auth0ProviderProps extends Auth0ClientOptions {
  onRedirectCallback?: (appState: any) => void
  children: React.ReactNode
  initOptions?: Auth0ClientOptions
}

export interface ContextValue {
  isAuthenticated?: boolean
  // user?: any;
  loading?: boolean
  popupOpen?: boolean
  // loginWithPopup: (params: any) => void;
  handleRedirectCallback?: () => void
  getIdTokenClaims?: (...p: any) => void
  loginWithRedirect?: (...p: any) => void
  getTokenSilently?: (...p: any) => void
  getTokenWithPopup?: (...p: any) => void
  logout?: (...p: any) => void
}

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

export const Auth0Context = React.createContext<ContextValue>({
  isAuthenticated: false,
  // user: {},
  loading: true,
  popupOpen: false,
  // loginWithPopup: (params: any) => {},
  handleRedirectCallback: () => {},
  getIdTokenClaims: (...p: any) => {},
  loginWithRedirect: (...p: any) => {},
  getTokenSilently: (...p: any) => {},
  getTokenWithPopup: (...p: any) => {},
  logout: (...p: any) => {},
})
export const useAuth0 = () => useContext(Auth0Context)

const setAxiosTokenInterceptor = async (accessToken: string): Promise<void> => {
  // axios.interceptors.request.use(async config => {
  AppInstance.interceptors.request.use(async (config) => {
    // const token = await auth0FromHook.getTokenSilently()
    const requestConfig = config
    if (accessToken) {
      requestConfig.headers.common.Authorization = `Bearer ${accessToken}`
    }
    return requestConfig
  })
  DataApiInstance.interceptors.request.use(async (config) => {
    const requestConfig = config
    if (accessToken) {
      requestConfig.headers.common.Authorization = `Bearer ${accessToken}`
    }
    return requestConfig
  })
}

export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [auth0Client, setAuth0] = useState<any>()
  const [loading, setLoading] = useState(true)
  const windowObj: any = window;
  useEffect(() => {
    if ( windowObj.REACT_APP_SSO_PROVIDER === 'oneauth' ) {
      return
    }
    const initAuth0 = async () => {
      const auth0FromHook: any = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      auth0FromHook.isAuthenticated().then(async (authenticated: boolean) => {
        setIsAuthenticated(authenticated)
        if (authenticated) {
          const token = await auth0FromHook.getTokenSilently()
          ApiUtil.getTokenSilently = auth0FromHook.getTokenSilently.bind(auth0FromHook)

          setAxiosTokenInterceptor(token).then(() => {
            setLoading(false)
          })
        } else {
          setLoading(false)
        }
      })
    }
    initAuth0().catch()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    setLoading(false)
    setIsAuthenticated(true)
    // setUser(auth0User)
  }

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
