import classNames from "classnames";
import { FC, ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

interface CustomLinkProps {
  icon: ReactNode;
  label: string;
  link: string;
}

export const CustomLink: FC<CustomLinkProps> = ({ icon, label, link }) => {
  const location = useLocation();

  return (
    <Link
      className={classNames(
        "tw-px-2 tw-py-4 tw-flex tw-items-center tw-gap-4  tw-font-bold tw-text-sm tw-font-mulish !tw-no-underline",
        link.indexOf(location.pathname) !== -1
          ? "tw-text-primary"
          : "tw-text-grey"
      )}
      to={link}
    >
      {icon} {label}
    </Link>
  );
};
