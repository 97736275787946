import * as type from "../actionType";

interface AuthState {
  isLoggedIn: boolean,
  token: string | null,
  isLoggedInSucceed: boolean,
  isLoggedInFailed: boolean,
  loginProcessStarted: boolean,
}

const initialState: AuthState = {
  isLoggedIn: false,
  token: null,
  isLoggedInSucceed: false,
  isLoggedInFailed: false,
  loginProcessStarted: true,
};

const AuthReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.LOGIN_PROCESS_STARTED:
    case type.LOGIN_PROCESS_FINISHED:
      return {
        ...state,
        ...action.payload
      }
    case type.LOGIN_PROCESS_COMPLETED:
      return {
        ...state,
        ...action.payload,
        isLoggedInSucceed: true,
        isLoggedInFailed: false,
        loginProcessStarted: false,
      };
    case type.LOGIN_PROCESS_FAIL:
      return {
        ...state,
        isLoggedInSucceed: false,
        isLoggedInFailed: true,
        loginProcessStarted: false,
        isLoggedIn: false
      }
    default:
      return state;
  }
};

export default AuthReducer;
