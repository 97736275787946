import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalTitle,
  OverlayTrigger,
  Popover,
  Image,
  Tooltip,
} from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useTranslation } from "react-i18next";
import closeImg from "../../../assets/New-images/build-knowledge-close-img.svg";
import Select from "react-select";
import infoCircleImg from "../../../assets/New-images/info-circle-img.svg";
import { addNewAgent } from "../../../store/agents/actions";
import { useDispatch, useSelector } from "react-redux";
import { getAgentFeatures, getPersonas } from "../../../store/common/actions";
import { getIsSuperAdmin } from "../../../store/common/selector";
import pinkInfoCircle from "../../../assets/images/pink-warning.svg";
import KnowledgeService from "../../../services/KnowledgeService";

const langs = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "pt-pt",
    label: "Português - Portugal",
  },
  {
    value: "id",
    label: "Bahasa Indonesia",
  },
  {
    value: "es",
    label: "Español",
  },
  {
    value: "tr",
    label: "Türkçe",
  },
  {
    value: "es-MX",
    label: "Español - Mexico",
  },
];

const AddAgentPopup = (props: any) => {
  const { t } = useTranslation("translation");
  const { orgLang } = props;
  const dispatch = useDispatch();
  const IsSuperAdmin = useSelector(getIsSuperAdmin);
  const ref = useRef(null);
  const langRef = useRef(null);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  let defaultLang: any = {
    value: null,
    label: null,
  };
  if (orgLang) {
    defaultLang = langs.find((l) => l.value === orgLang);
  }
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLang);
  const [agentDescription, setAgentDescription] = useState("");
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [agentSettings, setAgentSetting] = useState<any>({ settings: {} });
  const [personaOptions, setPersonaOptions] = useState([
    {
      value: "tutoring",
      label: "Learning Tutor",
    },
    {
      value: "facilitator",
      label: "Learning Facilitator",
    },
  ]);
  const service = new KnowledgeService();

  useEffect(() => {
    if (props.show) {
      // getAgentSetting();
      const fun = async () => {
        const personas: any = await dispatch(getPersonas());
        if (personas && personas.length > 0) {
          setPersonaOptions(
            personas.map((p: any) => ({
              value: p.key,
              label: p.name,
            }))
          );
        }
      };
      fun();
    }
    return;
  }, [props.show]);

  // const getAgentSetting = async () => {
  //   const { status, data } = await service.getAgentSettings();
  //   if (status === 200) {
  //     const settings = data?.data?.agent;
  //     setAgentSetting(settings);
  //     if (props.personas && props.personas.length > 0 && settings) {
  //       const { chat_persona } = settings?.settings;
  //       const defaultPersona = props.personas.find(
  //         (i: any) => i.value === chat_persona
  //       );
  //       setSelectedPersona(defaultPersona);
  //     }
  //     return;
  //   }
  // };

  const saveAgent = async () => {
    try {
      setLoading(true);
      const agent: any = await dispatch(
        addNewAgent({
          name: name,
          description: agentDescription,
          language_code: selectedLanguage.value,
        })
      );
      if (agent) {
        await hideModal(agent);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onClear = () => {
    setName(null);
    setAgentDescription(null);
    setSelectedLanguage(defaultLang);
    setSelectedPersona(null);
  };

  const hideModal = (agent: any) => {
    if(agent && agent._id) {
      props.footerButtonAction(agent, selectedPersona);
      onClear();
    }
  };

  return (
    <React.Fragment>
      <Modal show={props.show} centered>
        <ModalHeader className="clr-black py-3 px-4 justify-content-between border-bottom">
          <ModalTitle id="contained-modal-title-vcenter">
            <h2 className="mb-0 font-1">{t("Add Agent")}</h2>
          </ModalTitle>
          <button
            className="btn"
            onClick={() => {
              onClear();
              props.onHide();
            }}
          >
            <img src={closeImg} alt="" />
          </button>
        </ModalHeader>
        <ModalBody className="px-4">
          <label htmlFor="" className="font-600">
            {t("Agent Name")}<span className="clr-red"> *</span>
          </label>
          <input
            type="text"
            className="form-control h-auto radius-4 mb-4"
            onChange={(e: any) => {
              setName(e.target.value);
            }}
            placeholder="Agent Name"
          />
          <h3 className="font-600">{t("Agent Description")}<span className="clr-red"> *</span></h3>
          <textarea
            className="form-control radius-4 mb-4"
            onChange={(e: any) => {
              setAgentDescription(e.target.value);
            }}
            placeholder="Description of Agent"
          />
          <div
            className="position-relative d-inline-flex align-items-center mb-2"
            ref={langRef}
          >
            <h3 className="font-600 add-agent mb-0">{t("Agent Language")}</h3>
            <OverlayTrigger
              placement={"right-start"}
              container={langRef}
              overlay={
                <Tooltip className="persona-tooltip" id="agent-popover">
                  {t(
                    "The agent language impacts the language output of the agent. This includes generating Q&As and summaries"
                  )}
                </Tooltip>
              }
            >
              <button className="btn py-0 px-2">
                <Image src={pinkInfoCircle} />
              </button>
            </OverlayTrigger>
          </div>
          <Select
            className="select-sort select-target mb-4 shadow-none"
            classNamePrefix="select-target-prefix"
            isSearchable={false}
            components={{
              IndicatorSeparator: () => null,
            }}
            value={selectedLanguage}
            placeholder="Select Language"
            options={langs}
            onChange={(e) => {
              setSelectedLanguage(e);
            }}
          />
          <div
            className="agent-persona position-relative d-inline-flex align-items-center mb-2"
            ref={ref}
          >
            <h3 className="font-600 mb-0">{t("Agent Persona")}</h3>
            <OverlayTrigger
              placement="right-start"
              container={ref}
              overlay={
                <Tooltip className="persona-tooltip" id="tooltip-prsona">
                  {/* <div> */}
                  <span>{t("Select the chatbot behaviour:")}</span>
                  <ul className="pl-4 mb-0">
                    <li>
                      <span className="font-600">{t("Learning Tutor:")}</span>
                      {t(
                        " Provides guidance by answering learner questions and offering explanations on specific topics or subjects."
                      )}
                    </li>
                    <li>
                      <span className="font-600">
                        {t("Learning Facilitator:")}
                      </span>
                      {t(" Promotes learning via socratic dialogue.")}
                    </li>
                  </ul>
                  {/* </div> */}
                </Tooltip>
              }
            >
              <button className="btn py-0 px-2">
                <Image src={pinkInfoCircle} />
              </button>
            </OverlayTrigger>
          </div>
          <Select
            // key={selectedBtn?.reference_id}
            isSearchable
            className="select-sort select-target shadow-none"
            classNamePrefix="select-target-prefix"
            components={{
              IndicatorSeparator: () => null,
            }}
            // defaultValue={selectedPersona}
            value={selectedPersona}
            options={personaOptions}
            onChange={(e: any) => {
              setSelectedPersona({
                value: e?.value,
                label: e?.label,
              });
            }}
          />
        </ModalBody>
        <ModalFooter className="px-4">
          <button
            className="btn-outline-primary btn py-1 font-600"
            onClick={() => {
              onClear();
              props.onHide();
            }}
            disabled={loading}
          >
            {t("Cancel")}
          </button>
          <button
            className="btn-primary btn py-1 font-600 mr-0 ml-3"
            onClick={(e) => saveAgent()}
            disabled={
              !name ||
              !agentDescription ||
              !selectedLanguage.value ||
              name.trim().length === 0 ||
              agentDescription.trim().length === 0 ||
              loading
              // (!name || name.trim().length === 0) &&
              // (!agentDescription || agentDescription.trim().length === 0) &&
              // !language.value
            }
          >
            {t("Create")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default AddAgentPopup;
